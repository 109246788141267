import React from "react";
import {BrowserRouter} from "react-router-dom"
import AppRouter from "./components/AppRouter"
import ResultReturn from "./components/result_return";


const App = () => {
  return (
      <BrowserRouter>
        <AppRouter/>
        <ResultReturn />
      </BrowserRouter>
  );
}
export default App;
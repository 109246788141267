import {makeAutoObservable} from "mobx";

export  default class AboutStore {
    constructor() {
        this._news = [
            {id:1, name: 'Закажите Тест-драйв! для территории до 500 га и получите скидку 30% *при стоимости заказа от 250.000 рублей'},
            {id:2, name: 'Сезон 2021 уже не за горами, оформите предзаказ на наши услуги и получите скидку!'},
            {id:3, name: 'AEROSPACE-AGRO объявляет о старте программы "Цифровая платформа". Станьте участником уже сейчас! Мы ищем сельскохозяйственные организации для проведения тестирования и обучения методов искусственного интеллекта. Создадим цифровое будущее вместе!'},
            {id:4, name: 'Для крупных агрохолдингов площадью более 50.000 га мы предлагаем особые условия по программам пилотирования, тестированию и внедрению услуг *подробности в описании!'},
        ]

        makeAutoObservable(this)
    }
    setAbout(news) {
        this._news = news
    }
    get news() {
        return this._news
    }

}
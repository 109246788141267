import React, {useContext,useEffect} from 'react';
import {Context} from "../../index";
import {useParams,useHistory} from "react-router-dom";
import Map from "./Map";
import Headline from "./Headline";

const Cases = () => {
    const {casePage} = useContext(Context);
    const {id} = useParams();
    const el = casePage.case[id];
    const history = useHistory()
    useEffect(() => {
        if (id==='5') {
            history.push('/cases/6')
        }
    }, [id])
    return (
        <div className="container keys about mb-80 mt-5">
            <Headline />
            <Map />
            <div className="region region-md-none d-none d-md-block d-lg-none "></div>
            <div id={'anc'} className="region region-md">
                <div className="region-block">
                    <div className='d-none d-md-block d-lg-none container'>
                        <div className="container-title mt-4">
                            <h1>{el?.name}</h1>
                        </div>
                    </div>
                    <div className="container-title d-md-none d-lg-block">
                        <h1>{el?.name}</h1>
                    </div>
                    <div className="anchor">
                        <div className="d-flex">
                            <a href='#problem'>Проблема</a>
                            <a href='#description'>Описание территории</a>
                            <a href='#assessment'>Оценка состояния</a>
                        </div>
                        <div className="d-flex">
                            <a href='#methods'>Используемые методы и технологии</a>
                            <a href='#decision'>Наше решение</a>
                            <a href='#result'>Результат</a>
                        </div>
                    </div>
                </div>
            </div>
            {/*problem*/}
            <div className="row" id={'problem'}>
                <div className="col-lg-4 d-none d-lg-flex align-items-center justify-content-center pt-3 mt-5 mb-5">
                    <img alt='коллаж' src={el.problem_img}/>
                </div>
                <div className="col-lg-8 col-12 first_block_cases">
                    <div className='d-md-flex align-items-center'>
                        <div className="container-title col-md-5 col-12">
                            <h1>Проблема</h1>
                        </div>
                        <div className="col-md-7 col-12 mobil-case-btn">
                            <img alt='коллаж' className='mobil-case-img' src={el.problem_img}/>
                        </div>
                    </div>
                    <p>{el.problem}</p>
                </div>
            </div>
            <div className="row third_block_cases" id={'description'}>
                <div className="col-lg-7 col-12 d-flex flex-column justify-content-center">
                    <div className='d-md-flex align-items-center'>
                        <div className="container-title col-md-5 col-12">
                            <h1>ОПИСАНИЕ ТЕРРИТОРИИ</h1>
                        </div>
                        <div className="col-md-7 col-12 mobil-case-btn">
                            <img alt='коллаж' className='mobil-case-img' src={el.description_img} />
                        </div>
                    </div>
                    {el.description?.map((item,index )=>
                        typeof item === "string"?
                            <p key={index}>{item}</p>
                            :
                            <div key={index} className={'mb-3'}>
                                {item.map((element,index) =>
                                    <p key={index} className='li-before'>{element}</p>
                                )}
                            </div>
                        )}
                </div>
                <div className="col-lg-5 col-12 d-none d-lg-flex align-items-center justify-content-center">
                    <img alt='коллаж' src={el.description_img} />
                </div>
            </div>
            <div className="row second_point_cases" id={'assessment'}>
                <div className="col-lg-5 col-12 d-none d-lg-flex align-items-center justify-content-center image_block">
                    <img alt='коллаж' className="" src={el.assessment_img} />
                </div>
                <div className="col-lg-7 col-12 text_block">
                    <div className={'empty_text_block'}></div>
                    <div className={'main_text_block'}>
                        <div className="container-title">
                            <h1>Оценка состояния</h1>
                        </div>
                        <img alt='коллаж' className={'mobil-case-img'} src={el.assessment_img} />
                        <p>{el.assessment_start}</p>
                        {el.assessment_li?.map((item,index)=>
                            typeof item === "string"?
                                <p key={index} className='li-before'>{item}</p>
                                :
                                <div key={index} className={'mb-3'}>
                                    {item.map((element,index) =>
                                        <p key={index} className=''>{element}</p>
                                    )}
                                </div>
                        )}
                        {el.assessment_end?.map((item,index)=>
                            <p key={index}>{item}</p>
                        )}
                    </div>
                    <div className={'empty_text_block-two'}></div>
                </div>
            </div>
            <div className="row fourth_point_cases" id={'methods'}>
                <div className="col-lg-7 col-12 d-lg-flex flex-column justify-content-center">
                    <div className="container-title">
                        <h1>Используемые методы <br className='d-none d-lg-block'/> и технологии</h1>
                    </div>
                    <div className="col-12 col-lg-5 d-flex d-lg-none align-items-center p-0">
                        <div className='case-cards'>
                            {el.methods_img?.map((item, index)=> (
                                <div key={index} className='case-card_block'>
                                    <div className="img_block">
                                        <img alt='коллаж' src={item} />
                                    </div>
                                    <p>{el.methods_text[index]}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <p>{el.methods_start}</p>
                    {el.methods_li?.map((item,index) =>
                        typeof item === "string"?
                            <p key={index} className='li-before'>{item}</p>
                            :
                            <div key={index} className={'mb-3 ml-20'}>
                                {item.map((element,index) =>
                                <p key={index} className=''>{element}</p>
                            )}
                            </div>
                    )}
                    {el.methods_end?.map((item,index)=>
                        <p key={index}>{item}</p>
                    )}
                </div>
                <div className="col-12 col-lg-5 d-none d-lg-flex align-items-center">
                    <div className='case-cards'>
                        {el.methods_img?.map((item, index)=> (
                            <div key={index} className='case-card_block'>
                                <div className="img_block">
                                    <img alt='коллаж' src={item} />
                                </div>
                                <p>{el.methods_text[index]}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row fifth_point_cases" id={'decision'}>
                <div className="col-lg-5 col-12 image_block align-items-center justify-content-center d-none d-lg-flex">
                    <img alt='коллаж' className="" src={el.decision_img} />
                    <div  className='d-flex flex-wrap justify-content-around mt-5'>
                        {el.decision_img_li?.map((item,index) =>
                            <p key={index} className={'li-before'}>{item}</p>
                        )}
                    </div>
                </div>
                <div className="col-lg-7 col-12 text_block special">
                    <div className={'empty_text_block'}></div>
                    <div className={'main_text_block'}>
                        <div className='d-md-flex align-items-center'>
                            <div className="container-title col-md-5 col-12">
                                <h1>Наше решение</h1>
                            </div>
                            <div className="col-md-7 col-12 mobil-case-btn">
                                <img alt='коллаж' className='mobil-case-img' src={el.decision_img} />
                            </div>
                        </div>
                        {el.decision_start?.map((item,index)=>
                            <p key={index}>{item}</p>
                        )}
                        {el.decision_li?.map((item,index)=>
                            <p key={index} className='li-before'>{item}</p>
                        )}
                        {el.decision_end?<div className="keys-green-block">{el.decision_end}</div>: null}
                    </div>
                    <div className={'empty_text_block-two'}></div>
                </div>
            </div>
            <div className="row sixth_point_cases" id={'result'}>
                <div className="container-title w-auto">
                    <h1>Результат</h1>
                </div>
                <div className="div-el row">
                    {el.result?.map((item,index) =>
                        (item[0] !== '')?
                        <div key={index} className="el col-12 col-sm-6">
                            <div className="d-flex flex-column align-items-lg-center flex-lg-row">
                                <img alt='коллаж' className="img-el" src={item[0]}/>
                                <div className="text">
                                    <p className="title">{item[1]}</p>
                                    <p className="content">{item[2]}</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div key={index} className="el d-flex align-items-center col-12 col-lg-6">
                            <div className="keys-green-block">{item[2]}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='mobil-case-btn'>
                <button onClick={()=> {window.scroll(0,0)}} className='banner-link'>наверх<img alt='коллаж' src="/images/case/svg/arrow.png"/> </button>
            </div>
            <div className='btn-ontop d-none d-lg-flex' onClick={()=> {
                    const el = document.getElementById('map_id');
                    el.scrollIntoView({behavior: "smooth"});}}>
                вернуться наверх
                <div className="btn-img-ontop">
                    <img src="/images/case/svg/00.svg" alt=""/>
                </div>
            </div>

        </div>
    );
};

export default Cases;
import React from 'react';

const BannerCarouselItem = ({mid, banner}) => {
    function AddActive(id) {
        var active_el = document.getElementById(mid);
        active_el.classList.remove('active');
    }
    function RemActive(id) {
        var active_el = document.getElementById(mid);
        active_el.classList.add('active');
    }
    return (
        <a onMouseEnter={()=>AddActive(banner.id)} onMouseLeave={()=>RemActive(banner.id)}
           href={banner.link}
           className={ mid===banner.id? 'banner-item active' : 'banner-item'}
           key={banner.id}
           id={banner.id}
           style={{'--hover-image': `url(${banner.img})` }}>
            <div className="container-title d-flex">
                <h3>{
                    banner.title.map((word) =>
                       <span key={word}>{word}<br/></span>
                    )
                    }
                </h3>
                <img className='' src='/images/icons/btn-lg-next.svg' />
            </div>
            <div className='banner-content'>
                {banner.content.split('\n').map((item,index) => (
                    <p key={index}>{item}</p>
                ))}
            </div>
            <p className='banner-link'>Узнать подробнее</p>
        </a>
    );
};      

export default BannerCarouselItem;
import React, {useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import RoadmapSmall from "../components/RoadmapSmall";

const getWidth  = () => {
    return window.innerWidth;
};
const Roadmap = () => {
    let [width, setWidth] = useState(getWidth);
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    return (
        <div>
            <NavBar width={width}/>
            <div className='roadmap_bg'>
                <div className='d-none d-lg-block mb-160'>
                    <div className='road_img roadmap_title_block  d-flex align-content-end mb-100'
                        style={{backgroundImage: "url(/images/roadmap/png/01.png)"}}
                        >
                        <div className='container'>
                            <div className='row'>
                                <div className="container-title">
                                    <h1 className={'roadmap_title'}>Дорожная <br/> карта проекта</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className="row first_block">
                            <div className="col-7 d-flex flex-column">
                                <h1 className='road_title'>АПРЕЛЬ 2019</h1>
                                <p>История AEROSPACE-AGRO началась в апреле 2019 года с исследования мировых проектов и имеющихся разработок в сельском хозяйстве по данным  дистанционного зондированию земли и геоинформационных систем. Задача – разработать методики, которые позволят оптимизировать использование сельскохозяйственных участков на основе экспертного анализа космических снимков высокого разрешения.</p>
                            </div>
                            <div className="col d-flex align-items-start justify-content-center">
                                <img src="images/roadmap/png/02.png" alt=""/>
                            </div>
                        </div>
                        <div className="row second_block">
                            <div className="col-7 d-flex flex-column">
                                <h2 className=''>
                                    За основу в применении <br/>
                                    методик был взят приоритет</h2>
                                <div className='w-100 d-flex flex-wrap'>
                                    <div className='d-flex flex-column w-50'>
                                        <img className='roadmap_svg m-zero' src="/images/roadmap/svg/01.svg" alt=""/>
                                        <p>повышение экономической эффективности</p>
                                    </div>
                                    <div className='d-flex flex-column w-50'>
                                        <img className='roadmap_svg' src="/images/roadmap/svg/02.svg" alt=""/>
                                        <p className='w-85'>увеличение качества и количества урожая</p>
                                    </div>
                                    <div className='d-flex flex-column w-50'>
                                        <img className='roadmap_svg' src="/images/roadmap/svg/03.svg" alt=""/>
                                        <p>своевременное обнаружение и профилактику проблем на земельных участках</p>
                                    </div>
                                    <div className='d-flex flex-column w-50'>
                                        <img className='roadmap_svg' src="/images/roadmap/svg/04.svg" alt=""/>
                                        <p className='w-85'>разработку решений с расчетом необходимых материалов и затрат
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 second_img">
                                <img src="images/roadmap/png/03.png" alt=""/>
                            </div>
                        </div>
                        <div className="row third_block">
                            <div className="col-6"><img src="images/roadmap/png/04.png" alt=""/></div>
                            <div className="col-6 left_block">
                                <h2 className='text-wrap'>Перед масштабным запуском компании наши специалисты тщательно анализировали</h2>
                                <p className='li-before'>опыт компаний-конкурентов и методы работы</p>
                                <p className='li-before'>особенности наших будущих клиентов, задачи и боли</p>
                                <p className='li-before'>специфику рынка для выстраивания доверительных отношений с партнерами и клиентами</p>
                                <p className='li-before'>Эти данные лежат в основе нашей стратегии продвижения и развития на российском и мировом рынке</p>
                            </div>
                        </div>
                        <div className="row fourth_block">
                            <div className="col-7 d-flex flex-column">
                                <h2 className=''>Поиск и формирование команды</h2>
                                <p className=''>С самого начала AEROSPACE-AGRO важно было собрать надежную команду единомышленников и специалистов по сельскому хозяйству высшей квалификации. На первом этапе мы сосредоточились на создании собственных методик, без привлечения искусственного интеллекта. Необходимо было добиться повышения качества анализа космических снимков, чтобы на их основе формировать рекомендации по работам на земельном участке.
                                </p>
                            </div>
                            <div className="col-5">
                                <img src="images/roadmap/png/05.png" alt=""/>
                            </div>
                        </div>
                        <div className="row fifth_block">
                            <div className="col-6"><img className={'f_img'} src="images/roadmap/png/06.png" alt=""/></div>
                            <div className="col-6 left_block">
                                <h2 className=''>Проверка эффективности методик</h2>
                                <p>Для проверки уровня своей работы мы приступили к тестированию технологий на земле. Абсолютно бесплатно внедряли и проверяли на практике все наработки и методы на сельскохозяйственных участках.  Результаты этих тестов представлены в разделе “Кейсы” </p>
                                <a href='/cases/0' className='btn-color_second'>
                                    перейти в «Кейсы»
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="row sixth_block">
                            <div className="col-7 d-flex flex-column">
                                <h2 className=''>Первые продажи</h2>
                                <p>После успешного тестирования сформировали комплекс методик для работы с сельскохозяйственными и фермерскими участками, который адаптируется для каждого конкретного клиента и задачи. Работали как с полноценными запусками, так с возможностью тест-драйва </p>
                                <a href={'/services/19'} className='btn-color_second'>
                                    узнать больше об услуге «Тест-драйв»
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-5">
                                <img className={'f_img'} src="images/roadmap/png/07.png" alt=""/>
                            </div>
                        </div>
                        <div className="row seventh_block">
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <img className={'f_img'} src="images/roadmap/png/08.png" alt=""/>
                            </div>
                            <div className="col-6 left_block">
                                <h1 className='road_title'>Июнь 2020</h1>
                                <p>С 2020 года AEROSPACE-AGRO участвует в профильных выставках, форумах, конференциях, круглых столах, конкурсах. Мы стараемся развиваться во всех направлениях: технологии, работа с клиентами, выходы на новые уровни и рынки. Мы анализируем работу с нашими клиентами и партнерами для того, чтобы работать с нами было легко и удобно.</p>
                            </div>
                        </div>
                        <div className="row eighth_block">
                            <div className="col-7 d-flex flex-column">
                                <h2 className=''>Постоянно идет работа <br/> по усовершенствованию наших методик</h2>
                                <p>В 2020 году мы собрали команду специалистов из 35 человек. Сформировали отделы: геоинформационных систем, агро-технологий,  математического моделирования и искусственного интеллекта. Команды работают над созданием новых алгоритмов, методов, моделей. Проводят исследования для увеличения точности и эффективности имеющихся методик.</p>
                                <p>Значимым достижением для AEROSPACE-AGRO стало лидерство в “Агрохаке”, по многим трекам нам удалось занять призовые места.</p>
                            </div>
                            <div className="col-5">
                                <img src="images/roadmap/png/09.png" alt=""/>
                            </div>
                        </div>
                        <div className="row ninth_block">
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <img className='f_img' src="images/roadmap/png/10.png" alt=""/>
                            </div>
                            <div className="col-6 left_block">
                                <h2 className=''>Наши достижения</h2>
                                <p>Особым достижением в 2020 году стало создание отдела разработки алгоритмов искусственного интеллекта и попадание в ТОП-15 проекта “Архипелаг 20.35”, что позволило нам привлечь грант на разработку алгоритмов искусственного интеллекта 3 млн. рублей от фонда содействия инновациям.</p>
                            </div>
                        </div>
                        <div className="row tenth_block">
                            <div className="col-7 d-flex flex-column">
                                <h2 className=''>Авторская <br/> цифровая платформа</h2>
                                <p>Кроме того, AEROSPACE-AGRO разрабатывает авторскую цифровую платформу – инновационную технологию, которая поможет упростить работу и повысить качество итогового результата как для агропромышленного сектора, так и для нашей компании.</p>
                                <a className=' btn-color_second'>
                                    узнать больше об цифровой платформе
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-5">
                                <img  className='f_img' src="images/roadmap/png/11.png" alt=""/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex justify-content-center">
                                <img src="images/roadmap/png/12.png" alt=""/>
                            </div>
                            <div className="col-6 dop_block" >
                                <h2 className=''>Цели и планы AEROSPACE-AGRO <br/> на ближайшее время</h2>
                                <p>AEROSPACE-AGRO очень важно поддерживать высокое качество услуг. Мы стремимся решать сложные задачи и двигаться к новым целям, поэтому следим за всеми событиями в агроиндустрии, развитием технологий и новыми исследованиями.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 d-flex flex-column serv_block mb-0">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/05.svg" alt=""/>
                                </div>
                                <p>запуск пилотных проектов для крупных агрохолдингов на территории России </p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block mb-0">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/06.svg" alt=""/>
                                </div>
                                <p>разработка собственных математических моделей, их обучение и повышение эффективности до 98%</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block pl-5 mb-0">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/07.svg" alt=""/>
                                </div>
                                <p>создание собственных геоинформационных систем</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/08.svg" alt=""/>
                                </div>
                                <p>создание собственных спутниковых систем (малых космических аппаратов до 300 кг) на базе компании AEROSPACE, сейчас ведутся активные переговоры</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/09.svg" alt=""/>
                                </div>
                                <p>создание новых алгоритмов работы искусственного интеллекта, конкурентоспособных для России и мирового рынка</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block pl-5">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/10.svg" alt=""/>
                                </div>
                                <p>работа над улучшением качества существующих методик и их применения</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block mb-0">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/11.svg" alt=""/>
                                </div>
                                <p>ведутся переговоры о сотрудничестве со страховыми компаниями, что позволит нашим клиентам быть защищенным в случае неудачного применения готовых рекомендаций</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block mb-0">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/12.svg" alt=""/>
                                </div>
                                <p>планируется выход на международный рынок, в приоритете такие страны, как: Италия, ОАЭ, Тайвань, Индия, США</p>
                                <a className=' btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                            <div className="col-4 d-flex flex-column serv_block mb-0 pl-5">
                                <div className='svg_block'>
                                    <img className='roadmap_svg' src="/images/roadmap/svg/13.svg" alt=""/>
                                </div>
                                <p>мы активно готовимся к тому, чтобы стать резидентами Сколково, и для достижения этой цели осталось совсем немного</p>
                                <a className='btn-color_second'>
                                    читать больше
                                    <img src="images/icons/btn-lg-next.svg" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <RoadmapSmall />
                <Footer />
            </div>

        </div>
    );
};

export default Roadmap;
import React, {useEffect, useState} from 'react';
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ServicesHeader from "../components/ServicesHeader/ServicesHeader";
import ServicesCaruselMobail from "../components/ServicesHeader/ServicesHeaderMobail";
import AerospaceServices from "../components/ServicePage/AerospaceServices";

const getWidth  = () => {
    return window.innerWidth;
};
const ServicePage = () => {
    let [width, setWidth] = useState(getWidth);
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return (
        <div className="serv-bg">
            <NavBar width={width}/>
            {width > 1024?<ServicesHeader /> : <ServicesCaruselMobail/>}
            <AerospaceServices/>
            <Footer width={width}/>
        </div>
    );
}

export default ServicePage;
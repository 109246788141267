import React, {useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Texnology from "../components/main/Texnology";
import Whereuse from "../components/main/Whereuse";
import Whytop from "../components/main/Whytop";
import Partners from "../components/main/Partners";
import Media from "../components/main/Media";
import Services from "../components/main/Services";
import AboutCarusel from "../components/main/AboutCarusel/AboutCarusel";
import BannerCarusel from "../components/main/BannerCarusel/BannerCarusel";
import NewsCarusel from "../components/main/NewCarusel/NewsCarusel";
import About from "../components/main/About/About";
import MediaPk from "../components/main/MediaPk";
import MainMap from "../components/main/MainMap";

const getWidth  = () => {
    return window.innerWidth;
};
const Main = () => {
    let [width, setWidth] = useState(getWidth);
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    return (
        <div>
            <div className="main-bg">
                {width >= 992?
                    <div className='vh-100 mb-5'>
                        <NavBar width={width}/>
                        <h1 className='main-title'>Научно-исследовательский центр цифровой агро-диагностики на основе
                            информационно-аэрокосмических технологий и искусственного интеллекта</h1>
                        <img className={'main-title-brand'} src="images/title-brand.svg" alt=""/>
                        {/*<NewsCarusel width={width}/>*/}
                        <img className={'mouse'} src="/images/header/mouse.png" alt=""/>
                    </div>
                    :
                    <div className='position-relative'>
                        <NavBar width={width}/>
                    </div>
                }
                <BannerCarusel width={width}/>
                <About width={width}/>
                <AboutCarusel width={width}/>
                <Texnology width={width} />
                <Whereuse width={width}/>
                <Services width={width}/>
                <Whytop width={width}/>
                <MainMap />
                {width >= 992?
                    <MediaPk />
                    :
                    <Media />
                }
                <Partners />
                <Footer />
            </div>
        </div>
    );
};

export default Main;
import React from 'react';
import Map from "../Case/Map";
import CasesCaseList from "../Case/CasesCaseList";

const MainMap = () => {
    return (
        <div className="container main-map mb-160">
            <div className="row">
                <div className="col-12 d-flex">
                    <div className="container-title w-auto">
                        <h1>Цифровая карта</h1>
                    </div>
                    <p className="top-info d-none d-lg-block">Выберете кейс для быстрого доступа <img className='ml-2' src="/images/icons/btn-lg-next.svg" alt=""/></p>
                </div>
                <div className="col-12 d-none d-lg-block">
                    <ul className="d-flex flex-row flex-wrap">
                        {/*<li className='map_li_case'><a href="/cases/5">РЕСПУБЛИКА КРЫМ</a></li>*/}
                        <li className='map_li_case'><a href="/cases/2">КРАСНОДАРСКИЙ КРАЙ</a></li>
                        <li className='map_li_case'><a href="/cases/7">СТАВРОПОЛЬСКИЙ КРАЙ</a></li>
                        <li className='map_li_case'><a href="/cases/4">ТУЛЬСКАЯ ОБЛАСТЬ</a></li>
                        <li className='map_li_case'><a href="/cases/9">ВЛАДИМИРСКАЯ ОБЛАСТЬ</a></li>
                        <li className='map_li_case'><a href="/cases/8">СВЕРДЛОВСКАЯ ОБЛАСТЬ</a></li>
                        <li className='map_li_case'><a href="/cases/0">КРАСНОЯРСКИЙ КРАЙ</a></li>
                        <li className='map_li_case'><a href="/cases/3">ПРИМОРСКИЙ КРАЙ</a></li>
                        <li className='map_li_case'><a href="/cases/6">МОСКОВСКАЯ ОБЛАСТЬ</a></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12 d-flex d-lg-none">
                    <p>Листайте список городов, чтобы увидеть больше кейсов</p>
                </div>
            </div>
            <Map />
            <CasesCaseList />
        </div>
    );
};

export default MainMap;
import React from 'react';
import Carousel, {consts} from "react-elastic-carousel";

const ServicesDetailedCaruselOne = ({texnology_img, texnology_text_img}) => {
    const breakPoints = [
        {width:0, itemsToShow: 1, className: "TexnologyCarusel", showArrows: true, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: true},
        {width:560, itemsToShow: 2, className: "TexnologyCarusel", showArrows: true, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: true},
    ];
    return (
        <Carousel breakPoints={breakPoints}
                  renderArrow={
                      ({ type, onClick, isEdge }) => {
                          const pointer = type === consts.PREV ? '/images/aboutstore/back.svg' : "/images/aboutstore/next.svg";
                          const btnName = type === consts.PREV ?'about-arrow back' :'about-arrow next'
                          return (
                              <button className={btnName} onClick={onClick} disabled={isEdge}>
                                  <img src={pointer} alt=""/>
                              </button>
                          )
                      }
                  }>
                    {texnology_img?.map((item, index)=> (
                        <div key={index} className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                            <img className='ServDetailed-slider_img_small' src={'/images/imgServDetailed'+ item} alt=""/>
                            <div className='d-flex justify-content-center text-center'>{texnology_text_img[index]}</div>
                        </div>
                    ))}
        </Carousel>
    );
};

export default ServicesDetailedCaruselOne;
import React, {useContext} from 'react';
const ServicesCaruselMobail= ()=> {


        return (
            <div className="fon-mobil-serv">
                <div className="container where_use mb-10">
                    <div className="row">
                        <div className="col-ls-5 col-12">
                            <div className="container-title">
                                <div className='col-12'>
                                    <h1 className='not-marge-bot'>услуга <br/>TEST-DRIVE
                                        <div className='vector'>
                                        </div>
                                    </h1>
                                    <p>
                                        Компания AEROSPACE-AGRO предлагает каждому новому клиенту
                                        воспользоваться услугой Test-Drive!<br/> За сумму в 200 000
                                        рублей мы сделаем подробную диагностику небольшого участка
                                        Вашей земли.
                                    </p>
                                    <div className="hiden-serv-one serv-request mt-5 serv-block">
                                        <div className='bottom hiden-serv-b'>
                                            <button type="button" className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать подробнее
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

export default ServicesCaruselMobail
import React from 'react';
import Carousel, {consts} from "react-elastic-carousel";

const TexnologyCarusel = () => {
    const breakPoints = [
        {width:0, itemsToShow: 1, className: "TexnologyCarusel", showArrows: true, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: true},
        {width:560, itemsToShow: 2, className: "TexnologyCarusel", showArrows: true, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: true},
    ];
    return (
        <Carousel breakPoints={breakPoints}
                  renderArrow={
                      ({ type, onClick, isEdge }) => {
                          const pointer = type === consts.PREV ? '/images/aboutstore/back.svg' : "/images/aboutstore/next.svg";
                          const btnName = type === consts.PREV ?'about-arrow back' :'about-arrow next'
                          return (
                              <button className={btnName} onClick={onClick} disabled={isEdge}>
                                  <img src={pointer} alt=""/>
                              </button>
                          )
                      }
                  }>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/0.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Анализ имеющихся проблем на территории
                    <span className='mt-1'>1/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/1.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Получение космических снимков по определенным параметрам на заданную
                    территорию
                    <span className='mt-1'>2/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/2.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Обработка космических снимков в специализированном программном
                    обеспечении и экспертный анализ проблем
                    <span className='mt-1'>3/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/3.png" alt=""/>
                <div className='texnologyCarusel_text_small cur '>Информационный анализ территории
                    <span className='mt-1'>4/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/4.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>
                    Использование методов искусственного интеллекта для детального анализа проблем
                    <span className='mt-1'>5/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/5.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Математическое моделирование рисков и прогноза урожая
                    <span className='mt-1'>6/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/6.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Составление и разработка индивидуальных рекомендаций по решению имеющихся проблем
                    <span className='mt-1'>7/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/7.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Создание плана для повышения экономической эффективности фермерского хозяйства
                    <span className='mt-1'>8/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/8.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>Оценка затрат и поиск оптимальных решений
                    <span className='mt-1'>9/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/9.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>
                    Наши технологии будут полезны как крупным агрохолдингам, так и частным компаниям и фермерам
                    <span className='mt-1'>10/11</span>
                </div>
            </div>
            <div className='d-flex justify-content-center align-content-center flex-column w-100 position-relative'>
                <img className='texnologyCarusel_img_small' src="/images/texnology/11.png" alt=""/>
                <div className='texnologyCarusel_text_small cur'>
                    PDF документ включает: <br/> исследование,<br/> решение,<br/> рекомендации
                    <span className='mt-1'>11/11</span>
                </div>
            </div>
        </Carousel>
    );
};

export default TexnologyCarusel;
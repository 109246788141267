import {makeAutoObservable} from "mobx";

export  default class AboutStore {
    constructor() {
        this._about = [
            {id:1, name: 'Эффективные методики и разработки!', img: '/images/aboutstore/2.png'},
            {id:2, name: 'Команда – высоко квалифицированные специалисты', img: '/images/aboutstore/3.png'},
            {id:3, name: 'Технологии - информационно-аэрокосмическая диагностика территории', img: '/images/aboutstore/1.png'},
            {id:4, name: 'Индивидуальный комплекс решений – подбор рекомендаций после всестороннего анализа участка', img: '/images/aboutstore/5.png'},
            {id:5, name: 'Результат – увеличение дохода и развитие бизнеса.', img: '/images/aboutstore/4.png'},
        ]

        makeAutoObservable(this)
    }
    setAbout(about) {
        this._about = about
    }
    get about() {
        return this._about
    }

}
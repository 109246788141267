/** URL Backend-сервера. */
const backendURL = 'https://api.aerospace-agro.com/';

/**
 * Функция отправки формы с заказом обратного звонка.
 * @param name {string} ФИО пользователя
 * @param email {string} E-mail пользователя
 * @param phone {string} номер телефона пользователя
 * @param comment {string} комментарий пользователя
 */
export const sendCallback = async (name, email, phone, comment) => {
    const body = JSON.stringify({'name': name, 'email': email, 'phone': phone, 'comment': comment});
    await fetch(backendURL + 'forms/callback', {method: 'POST', body: body});
}

/**
 * Функция отправки формы с обратной связью.
 * @param name {string} ФИО пользователя
 * @param email {string} E-mail пользователя
 * @param phone {string} номер телефона пользователя
 * @param comment {string} комментарий пользователя
 */
export const sendFeedback = async (name, email, phone, comment) => {
    const body = JSON.stringify({'name': name, 'email': email, 'phone': phone, 'comment': comment});
    await fetch(backendURL + 'forms/feedback', {method: 'POST', body: body});
}

/**
 * Функция отправки формы с заявками на оказание услуг.
 * @param name {string} ФИО пользователя
 * @param services {string} список желаемых услуг
 * @param email {string} E-mail пользователя
 * @param phone {string} номер телефона пользователя
 * @param comment {string} комментарий пользователя
 */
export const sendOrder = async (name, services, email, phone, comment) => {
    const body = JSON.stringify(
        {'name': name, 'services': services, 'email': email, 'phone': phone, 'comment': comment});
    await fetch(backendURL + 'forms/order', {method: 'POST', body: body});
}
/**
 * Функция получения новостей.
 * @page (number) страница
 * @count (number)
 */


export const getNews = async (page, count) => {
    let data = await fetch(backendURL + 'news?page='+ page +'&count=' + count, {
        method: 'GET',
        cache: "force-cache"
    }).then(response => response.json())
        .then(response => {
            return response
        })
    return data
}
/**
 * Функция получения всех новостей.
 */

export const getAllNews = async () => {

    let data = await fetch(backendURL + 'news', {
        method: 'GET',
        cache: "force-cache"
    }).then(response => response.json())
        .then(response => {
            return response
        })
    return data
}
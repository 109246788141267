import React, {useContext, useState,} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const Services = observer(({width}) => {
    const {services} = useContext(Context);
    const [flag, setFlag] = useState(services.services[0]);
    function changeFlag(item) {
        document.getElementsByClassName('services-active active')[0].classList.remove("active");
        document.getElementById('services ' + item.id).classList.toggle("active");
        setFlag(item)
    }
    return (
        <div className={width>992?"container services mb-160":"container services mb-5"}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="container-title">
                        <h1>УСЛУГИ <br/> AEROSPACE-AGRO</h1>
                    </div>
                </div>
            </div>
            <div>
                {width>992?
                    <div className="row">
                        <ul className="col-6 services-list">
                            {services.services.map((item, index) =>
                                <li key={item.id} id={'services ' + item.id} onClick={() => changeFlag(item)} className={index===0?'services-active active': 'services-active '}><img src={item.svg} alt="Услуга" /><p
                                    className="w-auto">{item.name}</p></li>
                            )}
                            <li><a href="/services" className="btn btn-color">перейти в полный перечень услуг <img
                                src="images/icons/btn-lg-next.svg"/></a></li>
                        </ul>
                        <div className="col-5 d-flex flex-column about-services">
                            <h3>{flag.name}</h3>
                            <img className='img_main' src={flag.img} alt="" />
                            <p className='mb-4'>{flag.description}</p>
                            {flag.ul?.map((item,index) =>
                                <div key={index} className='d-flex serv-li'>
                                    <div>
                                        <img src="images/roadmap/pol.png" alt="Услуга" />
                                    </div>
                                    <p key={index}>{item}</p>
                                </div>
                            )}
                            <a href={flag.link} className="btn btn-color mt-4">полное описание услуги <img
                                src="images/icons/btn-lg-next.svg"/></a>
                        </div>
                    </div>
                    :
                    <div className='row'>
                        <div className='about-services_small d-flex flex-wrap align-content-start justify-content-start'>
                            {services.services.map((item, index) =>
                                <a href={item.link} key={item.id} id={'services ' + item.id} className='col-12 col-sm-6 d-flex align-content-start justify-content-start flex-column mb-30 '>
                                    <img width={'50px'} className='mb-4 cur-img' src={item.svg} alt="Услуга" />
                                    <div className="w-auto">{item.name}</div>
                                </a>
                            )}
                            <div className='btn-onservices'>
                                <a href="/services" className="btn btn-color">перейти в полный перечень услуг <img
                                src="images/icons/btn-lg-next.svg"/>
                                </a>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});

export default Services;
import React from 'react';

const ResultReturn = () => {
    return (
        <div>
            <div id='result-btn' data-toggle="modal" data-target="#result-popupModal"></div>
            <div className="modal result-popup fade" id="result-popupModal" tabIndex="-1" role="dialog"
                 aria-labelledby="result-popupModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content row">
                        <div className="d-flex flex-column align-items-center">
                                <div className="container-title">
                                    <h1 className={'contact_title'}>Спасибо, ваша заявка успешно отправлена!</h1>
                                </div>
                                <p>В ближайшее время с вами свяжется специалист компании AEROSPACE-AGRO</p>
                                <div className='btn-color_second' data-dismiss="modal" > вернуться на сайт <img src="images/icons/btn-lg-next.svg" alt=""/></div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultReturn;
import React from 'react';

const Headline = () => {
    return (
        <div className='keys-title row'>
            <div className="keys-block col-12 col-sm-6" >
                <div className="container-title">
                    <h1>Кейсы<br/> aerospace-agro</h1>
                </div>
                <div className="keys-grass">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="home" role="tabpanel"
                             aria-labelledby="home-tab">
                            <p className="about-text">Наша задача - максимально подробно представить
                                полное
                                и подробное описание территории. На основе данных,
                                а также дополнительных рекомендаций от нас, Вы получаете мощный инструмент для
                                повышения территории.</p>
                            <p className="about-text">Выберите интересующий вас кейс на карте <img className='ml-4' src="/images/case/svg/down.svg" alt=""/> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-6 col-12'>
                <div className="img col">
                    <img src="/images/case/Grass.png" className="grass" alt="grass"/>
                </div>
            </div>
        </div>
    );
};

export default Headline;
import React,{useContext,useEffect} from 'react';
import Carousel, {consts} from "react-elastic-carousel";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {getAllNews} from "../../http/backend";

const Media = observer(() => {
    const {media} = useContext(Context);
    useEffect((render) => {
        getAllNews().then(response =>  {
            media.setMedia(response)})
    }, [])
    const breakPoints = [
        {width: 0, itemsToShow: 1, className: "slider-1_block", showArrows: true, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: false},
    ];
    return (
        <div className="container media-small mb-160 d-lg-none">
            <div className="container-title">
                <h1>ССЫЛКИ НА СМИ</h1>
            </div>
            <div className={'position-relative'}>
                <Carousel breakPoints={breakPoints}
                          renderArrow={
                              ({ type, onClick, isEdge }) => {
                                  const pointer = type === consts.PREV ? '/images/aboutstore/back.svg' : "/images/aboutstore/next.svg"
                                  return (
                                      <button className='media-arrow' onClick={onClick} disabled={isEdge}>
                                          <img src={pointer} alt=""/>
                                      </button>
                                  )}
                          }>
                    {media.media?.map((element, index) =>
                        <div key={index} className='row w-100 mb-100 position-relative media-element'>
                            <div className="col-12 col-md-4 col-lg-3 mb-4">
                                <div className='d-flex justify-content-center align-items-center media-element-logo'>
                                    <img src={element.logo_url} alt=""/>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 col ">
                                <div>
                                    <div className="container-title mb-3">
                                        <h4><b>{element.heading}</b></h4>
                                    </div>
                                    <b className='mb-3'>{element.subheading}</b>
                                    <p></p>
                                    <p>{element.text}</p>
                                </div>
                            </div>
                            <div className="smi-btnon">
                                <a href={element.link} className="w-100 btn-color">Читать далее</a>
                            </div>
                            <div className='inline_arrow'>
                                <span>{index+1}/{media.length}</span>
                            </div>
                        </div>
                    )}
                </Carousel>
            </div>
        </div>
    );
});

export default Media;
import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container ">
                <div className="row">
                    <div className="col-12 col-lg-3 footer_brand "><img src="/images/brand.svg" alt="brand"/></div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-lg-center">
                        <ul className="list-unstyled pr-5">
                            {/*<li><a href="#">Команда</a></li>*/}
                            <li><a href="/roadmap">Дорожная карта проекта</a></li>
                            {/*<li><a href="#">Новости</a></li>*/}
                            <li><a href="/services">Услуги</a></li>

                            <li><a href="/cases">Кейсы</a></li>
                            <li><a href="/contacts">Контакты</a></li>
                        </ul>
                    </div>
                    {/*<div className="col-12 col-md-6 col-lg-3">*/}
                    {/*    <ul className="list-unstyled ">*/}
                    {/*        /!*<li><a href="#">Блог</a></li>*!/*/}
                    {/*        /!*<li><a href="#">Наши мероприятия</a></li>*!/*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className="col-12 offset-lg-1 col-md-6 col-lg-4 d-flex justify-content-md-end justify-content-sm-start">
                        <ul className="list-unstyled">
                            <li className="mb-4"><a href="tel:84998994989"><img className='mr-2' src="/images/header/call.svg" alt={"позвонить"}/> +7 (499) 899 49 89</a></li>
                            <li className="mb-4 footer-mes">
                                <span>Мы в соцсетях:</span>
                                <div className='mt-2'>
                                    <div className="social d-flex justify-content-start align-items-center">
                                        <a className='mr-3' href="https://www.instagram.com/aerospace.agro/"><img src="/images/header/inst.svg" alt=""/></a>
                                        <a className='mr-3' href="https://www.linkedin.com/company/77860758/"><i className="fa fa-linkedin"></i></a>
                                        <a className='mr-3' href="#"><img src="/images/header/fb.svg" alt=""/></a>
                                    </div>
                                </div>
                            </li>
                            <li>©AEROSPACE-AGRO, <br/> 2019—2021</li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

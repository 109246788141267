import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'
import {sendCallback} from "../http/backend";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const NavBar = ({width}) => {
    let [dropdown, setDropdown] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');
    const history = useHistory()
    useEffect(() => {
        if (width > 1024){
            try {
                document.getElementById('nav'+ history.location.pathname).classList.toggle("active");
            } catch {}
        } else {
            if (!dropdown) {
                try {
                    document.getElementById('nav'+ history.location.pathname).classList.toggle("active");
                } catch {}
            }
        }
    }, [dropdown])

    function DropdownMenu() {
        document.body.classList.toggle('scroll');
        setDropdown(!dropdown)
    }
    async function handleSubmit() {
        if (name ==='' || email==='' || phone==='' || text==='') {
            alert('Введите все данные');
            return;
        }
        if (phone.length!==11) {
            alert('Введите телефон');
            return;
        }
        if (!(/.+\@.+/.test(email))) {
            alert('Почта не валидна');
            return;
        }
        let formatPhone = '+'+ phone[0] + ' (' + phone[1]+ phone[2]+ phone[3] + ') ' + phone[4]+ phone[5]+ phone[6] + '-' + phone[7]+ phone[8] + '-' + phone[9]+phone[10];
        await sendCallback(name, email, formatPhone, text).then(
            value => {
                document.getElementById('nav-input-name').value = '';
                setName('');

                document.getElementById('number-nav').classList.toggle('number-cont');
                document.getElementById('react-tel-input-nav').classList.toggle('d-none');
                setPhone('+7');

                document.getElementById('nav-input-email').value = '';
                setEmail('');

                document.getElementById('nav-input-text').value = '';
                setText('');

                document.getElementById('result-btn').click()
                document.getElementById('pop-up-feedback').click()
                }, reason => {
                alert('Что-то пошло не так, повторите попытку позже');
        });
    }
    return (
        <header className="header">
            {width >= 992?
                <div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content row">
                                <div className='col-12 col-lg-6 mt-4'>
                                    <form className="feedback">
                                        <input id={'nav-input-name'} className='w-100 input_contacts' onChange={(e) => setName(e.target.value)}  placeholder='Ваше имя' type="text"/>
                                        <div id={'number-nav'}>
                                            <input className='w-100 input_contacts' placeholder='Ваш номер' type="text"
                                                   onClick={() => {
                                                       document.getElementById('number-nav').classList.toggle('number-cont');
                                                       document.getElementById('react-tel-input-nav').classList.toggle('d-none')
                                                   }}
                                            />
                                            <div id={'react-tel-input-nav'} className={'d-none'}>
                                                <PhoneInput
                                                    inputProps={{
                                                        name: "phone",
                                                        required: true,
                                                        autoFocus: true
                                                    }}
                                                    country="ru"
                                                    countryCodeEditable={false}
                                                    inputClass={'input_contacts'}
                                                    className='d-none input_contacts'
                                                    localization = {'ru'}
                                                    placeholder={'Ваш номер'}
                                                    enableAreaCodes={false}
                                                    value={phone}
                                                    onChange={setPhone}/>
                                            </div>
                                        </div>
                                        <input id={'nav-input-email'} className='w-100 input_contacts' onChange={(e) => setEmail(e.target.value)}  placeholder='Ваша почта' type="text"/>
                                        <textarea id={'nav-input-text'} className='w-100 input_contacts' onChange={(e) => setText(e.target.value)}  placeholder='Опишите вашу проблему'/>
                                        <a className='btn-color_second w-100' onClick={handleSubmit}> Отправить <img src="images/icons/btn-lg-next.svg" alt=""/></a>
                                    </form>
                                    <p className='privacy text-wrap'>Нажимая «Отправить» вы соглашаетесь <br/>c <a className='text-wrap' href="/privacy.pdf" download> политикой конфиденциальности</a></p>
                                </div>
                                <div className="col-12 col-lg-6 d-flex align-content-end justify-content-end">
                                    <div  id='pop-up-feedback' className='h-25' data-dismiss="modal">
                                        <svg style={{cursor: 'pointer'}} width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.8978 1.29622L22.5141 0L12.1559 9.70378L1.7977 0L0.414062 1.29622L10.7723 11L0.414063 20.7038L1.7977 22L12.1559 12.2962L22.5141 22L23.8978 20.7038L13.5395 11L23.8978 1.29622Z" fill="#0FDC67"/>
                                            <path d="M23.8978 1.29622L22.5141 0L12.1559 9.70378L1.7977 0L0.414062 1.29622L10.7723 11L0.414063 20.7038L1.7977 22L12.1559 12.2962L22.5141 22L23.8978 20.7038L13.5395 11L23.8978 1.29622Z" fill="url(#paint0_linear)"/>
                                            <defs>
                                                <linearGradient id="paint0_linear" x1="0.414062" y1="22" x2="23.8978" y2="22" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#08BCD5"/>
                                                    <stop offset="1" stopColor="#13F025"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container header-one">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <a className="btn-link" href="/main"><img src="/images/brand.svg" alt="AeroSpace-Agro"/></a>
                                <a className="btn-link" href="tel:84998994989"><img src="/images/header/call.svg" /> +7 (499) 899 49 89</a>
                                <a className="btn-link" data-toggle="modal" data-target="#exampleModal" href="#">Заказать обратный звонок</a>
                                <div className="social d-flex justify-content-center align-items-center">
                                    <span>Мы в соцсетях</span>
                                    <a href="https://www.instagram.com/aerospace.agro/"><img src="/images/header/inst.svg" alt=""/></a>
                                    <a href="https://www.linkedin.com/company/77860758/"><i className="fa fa-linkedin"></i></a>
                                    <a href="#"><img src="/images/header/fb.svg" alt=""/></a>
                                </div>
                                <div className="lang d-flex justify-content-between align-items-center">
                                    <img src="/images/header/rus.svg"/>
                                    <span>rus</span>
                                    <a href="#"> <img src="/images/header/drop.svg" alt=""/> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-expand-lg bg-grad">
                        <div className="container navbar-cont">
                            <ul className="navbar-nav w-100 d-flex flex-row justify-content-between align-items-center flex-lg-nowrap">
                                <li className="nav-item bg-transparent">
                                    <a id='nav/main' className="nav-link" href="/main">Главная</a>
                                </li>
                                <li className="nav-item bg-transparent">
                                    <a id='nav/roadmap' className="nav-link" href="/roadmap">Дорожная карта проекта</a>
                                </li>
                                {/*<li className="nav-item bg-transparent">*/}
                                {/*    <a className="nav-link" href="#">Новости</a>*/}
                                {/*</li>*/}
                                <li className="nav-item bg-transparent">
                                    <a id='nav/services'  className="nav-link" href="/services">Услуги</a>
                                </li>
                                {/*<li className="nav-item bg-transparent">*/}
                                {/*    <a className="nav-link" href="#">Блог</a>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item bg-transparent">*/}
                                {/*    <a className="nav-link" href="#">Наши мероприятия</a>*/}
                                {/*</li>*/}
                                <li className="nav-item bg-transparent">
                                    <a id='nav/cases' className="nav-link" href="/cases/0">Кейсы</a>
                                </li>
                                <li className="nav-item bg-transparent">
                                    <a id='nav/contacts' className="nav-link" href="/contacts">Контакты</a>
                                </li>
                                <li className="nav-item">
                                    <button className="btn" type="submit"><img src="/images/header/search.svg" alt=""/></button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                :
                dropdown?
                    <div className='header_small'>
                        <ul className="navbar-nav w-100 d-flex flex-row justify-content-between align-items-center">
                            <div className="lang d-flex justify-content-between align-items-center">
                                <img src="/images/header/rus.svg"/>
                                <a href="#"><img src="/images/header/drop.svg" alt=""/> </a>
                            </div>
                            <li className="nav-item">
                                <a className="btn-link" href="/main"><img src="/images/brand_small.svg"
                                                                          alt="AeroSpace-Agro"/></a>
                            </li>
                            <img onClick={DropdownMenu} src="/images/burger.svg" alt="b"/>
                        </ul>
                    </div>
                    :
                    <div className="dropdown-men">
                        <ul className="header_small navbar-nav w-100 d-flex flex-row justify-content-between align-items-center">
                            <div className="lang d-flex justify-content-between align-items-center">
                                <img src="/images/header/rus.svg"/>
                                <a href="#"><img src="/images/header/drop.svg" alt=""/> </a>
                            </div>
                            <li className="nav-item">
                                <a className="btn-link" href="/main"><img src="/images/brand_small.svg"
                                                                          alt="AeroSpace-Agro"/></a>
                            </li>
                            <img onClick={DropdownMenu} src="/images/burger.svg" alt="b"/>
                        </ul>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a id='nav/main' className="nav-link" href="/main">Главная</a>
                            </li>
                            <li className="nav-item">
                                <a id='nav/roadmap' className="nav-link" href="/roadmap">Дорожная карта проекта</a>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" href="#">Новости</a>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <a id='nav/services' className="nav-link" href="/services">Услуги</a>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" href="#">Блог</a>*/}
                            {/*</li>*/}
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" href="#">Наши мероприятия</a>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <a id='nav/cases' className="nav-link" href="/cases">Кейсы</a>
                            </li>
                            <li className="nav-item">
                                <a id='nav/contacts' className="nav-link" href="/contacts">Контакты</a>
                            </li>
                        </ul>
                    </div>

            }
        </header>
    );
};

export default NavBar;

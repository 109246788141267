import {makeAutoObservable} from "mobx";

export  default class ServicesStore {
    constructor() {
        this._services  = [
            {id:1,
                name: 'Тест-драйв',
                svg: '/images/services/1.svg',
                img: '/images/servicesPage/3_4.png',
                description: 'Тест-драйв позволяет определить проблемы и получить краткий анализ территории Закажите пробное исследование участка и проверьте эффективность анализа и рекомендаций на практике.',
                ul: ['демонстрационный вариант', 'проверка эффективности услуг'],
                link: '/services/19',
                },
            {id:2,
                name: 'Комплексное исследование участка',
                svg: '/images/services/2.svg',
                img: '/images/servicesPage/3_6.png',
                description: 'Узнайте об актуальном состоянии вашего участка и существующих проблемах. Эти данные позволяют грамотно оценить ситуацию и выстроить свою работу.',
                ul: ['Узнать все об участке ', 'Изучить нюансы '],
                link: '/services/21',
            },
            {id:3,
                name: 'Оценка и анализ проблем территории',
                svg: '/images/services/3.svg',
                img: '/images/servicesPage/1_1.png',
                description: 'Мы предоставляем информацию, в каком состоянии находится Ваша территория, и какие проблемы существуют в данный момент. Прогнозируем возможные риски, влияющие на качество и количество урожая, предлагаем варианты решения возможных проблем.',
                ul: ['Исследовать проблемы ', 'Предоставить решения'],
                link: '/services/0',},
            {id:4,
                name: 'Комплекс исследований и оценка участка на количество и виды вредителей',
                svg: '/images/services/4.svg',
                img: '/images/servicesPage/2_6.png',
                description: 'Борьба с вредителями является важным этапом для получения высоких урожаев. Мы проводим комплексное исследование наличия насекомых и их распространения. Подбираем эффективные методы борьбы и профилактики.',
                ul: ['Оценить вред и угрозу', 'Разработать концепцию решения проблемы '],
                link: '/services/13',},
            {id:5,
                name: 'Анализ сельскохозяйственного участка на заболевание растений',
                svg: '/images/services/5.svg',
                img: '/images/servicesPage/2_2.png',
                description: 'Предоставляем подробное описание состояния сельскохозяйственных культур, что позволяет не только получить информацию о заболевших растениях, но и выявить факторы, способствующие возникновению болезней. Вы сможете проанализировать статистику по вашему участку и получите рекомендации по устранению заболеваний растений.',
                ul: ['Исследовать заболевания', 'Предоставить решения по ликвидации заболевания '],
                link: '/services/9',},
            {id:6,
                name: 'Диагностика территорий перед посевами — создание комплекса моделей работ в течении сезона',
                svg: '/images/services/6.svg',
                img: '/images/servicesPage/1_2.png',
                description: 'Для эффективного использование территории необходимо определить наиболее эффективную модель сельскохозяйственной работы на будущий сезон. Мы помогаем всесторонне изучить состояния участка и выстроить оптимальный план работ для повышения качества и количества урожая.',
                ul: ['Проанализировать территорию', 'Разработать оптимальный план'],
                link: '/services/2',},
            {id:7,
                name: 'Расчёт экономической эффективности сельскохозяйственного участка',
                svg: '/images/services/7.svg',
                img: '/images/servicesPage/3_3.png',
                description: 'Оценка продуктивности территории позволяет определить и запланировать проведение необходимых работ для повышения рентабельности вашего бизнеса.',
                ul: ['Оценить имеющиеся затраты ', 'Повысить эффективность территории'],
                link: '/services/18',},
            {id:8,
                name: 'Минеральный анализ почвы',
                svg: '/images/services/8.svg',
                img: '/images/servicesPage/1_8.png',
                description: 'На основе данного анализа вы получаете информацию о состоянии почвы участка, а также рекомендации по соблюдению баланса минеральных и химических элементов для оптимизации урожая, а также по ликвидации имеющихся проблем.\n',
                ul: ['Провести анализ территории', 'Изучить индивидуальный минеральный баланс'],
                link: '/services/7',},
        ]

        makeAutoObservable(this)
    }
    setAbout(services ) {
        this._services  = services
    }
    get services () {
        return this._services
    }

}
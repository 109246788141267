import {makeAutoObservable} from "mobx";

export  default class MediaStore {
    constructor() {
        this._media = [
        ]
        this._page = 1;
        this._activPage = 1;
        this._pages = 1;
        this._count = 3;

        makeAutoObservable(this)
    }
    setPageCount(payload) {
        this._page = payload.page
        this._count = payload.count
    }
    setPage(payload) {
        this._page = payload
    }
    setPages(news_len) {
        this._pages = Math.ceil(news_len/this.count)
    }
    setActivePage(activpage) {
        this._activPage = activpage
    }
    setMedia(media) {
        this._media = media
    }
    get media() {
        return this._media
    }
    get length() {
        return this._media.length
    }
    get page() {
        return this._page
    }
    get activePage() {
        return this._activPage
    }
    get pages() {
        return this._pages
    }
    get count() {
        return this._count
    }

}
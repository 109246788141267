import React, {useContext, useEffect} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {getAllNews, getNews} from "../../http/backend";

const MediaPk = observer(() => {
    const {media} = useContext(Context);

    useEffect(() => {
        getNews(media.activePage, media.count).then(response =>  media.setMedia(response.results))
    },[media.activePage, media.count])

    function next() {
        if (media.activePage > media.page) {
            media.setActivePage(1)
        } else {
            media.setActivePage(media.activePage+1)
        }
        try {
            const el = document.getElementById('flag-media');
            el.scrollIntoView({behavior: "smooth"});
        } catch {

        }
    }
    function back() {
        if (media.activePage <= 1) {
            media.setActivePage(media.pages)
        } else {
            media.setActivePage(media.activePage-1)
        }
        try {
            const el = document.getElementById('flag-media');
            el.scrollIntoView({behavior: "smooth"});
        } catch {

        }
    }
    function changePage(i){
        media.setActivePage(i)
        try {
            const el = document.getElementById('flag-media');
            el.scrollIntoView({behavior: "smooth"});
        } catch {

        }
    }
    function pages() {
        getAllNews().then(response =>  media.setPages(response.length))

        if (media.pages <= 1) {
            return null;
        }
        let pagination = [];
        for (let i = 1; i <= media.pages; i++) {
            pagination.push(<div onClick={() => changePage(i)} className={(media.activePage===i)?'page-link active ' : 'page-link '}>{i}</div>)
        }

        let element = <div className="row mb-50">
            <div className="col-5 offset-2">
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className="page-item">
                            <div onClick={back} className="page-link">
                                <img src="/images/icons/btn-sm-prev.svg" alt=""/>
                            </div>
                        </li>
                        {pagination.map((element,index) =>
                            <li key={index} className="page-item">{element}</li>)
                        }
                        <li className="page-item">
                            <div onClick={next} className="page-link">
                                <img src="/images/icons/btn-sm-next.svg" alt=""/>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        return element;
    }
    return (
        <div className="container mb-160 d-none d-lg-block">
            <div id={'flag-media'} className="container-title col-lg-5">
                <h1>ССЫЛКИ НА СМИ</h1>
            </div>
            <div>
                {media.media?.map((element, index) =>
                    <div key={index} id={ 'news_page_' + media.activePage} className={'row media-pk mb-50' }>
                        <div className="col-12 col-lg-2 d-flex flex-column justify-content-center">
                            <div className='d-flex justify-content-center'>
                                <img src={element.logo_url} alt=""/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 d-flex flex-column">
                            <div className='mb-50'>
                                <div className="container-title">
                                    <h4>{element.heading}</h4>
                                </div>
                                <b>{element.subheading}</b>
                                <p></p>
                                <p className='m-0'>{element.text}</p>
                            </div>
                            <a href={element.link} className="btn-color">читать далее <img src="images/icons/btn-lg-next.svg"/></a>
                        </div>
                        <div className="col-5 justify-content-end img-shadow d-none d-lg-flex">
                            <img src={element.image_url} alt=""/>
                        </div>
                    </div>
                )}
                {pages()}
            </div>
        </div>
    );
});

export default MediaPk;
import React, {useContext,useState,useEffect} from 'react';
import {Context} from "../index";
import NavBar from "../components/NavBar";
import ServicesHeader from "../components/ServicesHeader/ServicesHeader";
import ServicesCaruselMobail from "../components/ServicesHeader/ServicesHeaderMobail";
import Footer from "../components/Footer";
import {createService} from "../http/api";
import {observer} from "mobx-react-lite";
import {sendOrder} from "../http/backend";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const getWidth  = () => {
    return window.innerWidth;
};
const BasketPage = observer(()  => {
    const {servicesPage} = useContext(Context);
    useEffect(() => {
        try {
            servicesPage.setBasket(JSON.parse(sessionStorage.basket));
        } catch (e) {
        }
    }, [])
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');
    let [width, setWidth] = useState(getWidth);
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    function changeElement(item) {
        servicesPage.setChoseEl(item);
    }
    async function handleSubmit() {
        let arr = '';
        servicesPage.choselist?.map((el) => {
            if (el.chose === true) {
                arr += el.name + ', ';
            }
        })
        if (name ==='' || email==='' || phone==='' || text==='' || arr === '') {
            alert('введите все данные и выберите хотя бы 1 услугу');
            return;
        }
        if (phone.length!==11) {
            alert('Введите телефон');
            return;
        }
        if (!(/.+\@.+/.test(email))) {
            alert('Почта не валидна');
            return;
        }
        let formatPhone = '+'+ phone[0] + ' (' + phone[1]+ phone[2]+ phone[3] + ') ' + phone[4]+ phone[5]+ phone[6] + '-' + phone[7]+ phone[8] + '-' + phone[9]+phone[10];

        await sendOrder(name, arr, email, formatPhone, text).then(
            value => {
                document.getElementById('bskt-input-name').value = '';
                setName('');

                document.getElementById('number-bskt').classList.toggle('number-cont');
                document.getElementById('react-tel-input-bskt').classList.toggle('d-none');
                setPhone('+7');

                document.getElementById('bskt-input-email').value = '';
                setEmail('');

                document.getElementById('bskt-input-text').value = '';
                setText('');
                servicesPage.setDel();
                document.getElementById('result-btn').click()
            }, reason => {
                alert('Что-то пошло не так, повторите попытку позже');
            });
        // await createService({"fio": name, "name":arr, "email": email, "phone": phone, "comment": text});
    }
    return (
        <div className="serv-bg basket">
            <NavBar width={width}/>
            {width > 1024?<ServicesHeader /> : <ServicesCaruselMobail/>}
            <div className='desk-img-serv line'>
                <div className='container pd-none'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3 className=' manager'>
                            <p className='m'>ваш запрос менеджеру</p>
                            <p className='s'>Выбрано: <b>{servicesPage.choselist.length} услуг для консультации</b></p>
                        </h3>
                        <a href={'/services'} type="button" className="bt-green btn btn-primary" >
                            назад к услугам
                            <div className='vector trans'></div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container basket-page">
                <div className="row d-lg-none">
                    <div className="col-12">
                        <div className="container-title">
                            <h1>
                                Ваш запрос менеджеру
                            </h1>
                        </div>
                        <a href={'/services'} type="button" className="bt-green btn btn-primary" >
                            назад к услугам
                            <div className='vector trans'></div>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="container-title basket-title">
                            <h1>1. Список выбранных услуг для консультации</h1>
                        </div>
                    </div>
                    {servicesPage.choselist?.map((element, index) =>
                        <div key={index} className="col-12 col-lg-6 basket-element">
                            <div className="d-flex flex-column flex-md-row align-items-center">
                                <img className={element.chose===true?null:'grey'}  src={element.mainimg} alt=""/>
                                <div className="d-flex flex-column justify-content-between basket_text">
                                        <p>{element.name}</p>
                                        <button className={element.chose===true? null :'grey'} onClick={()=>changeElement(element)}> {element.chose===true?'удалить из списка':'вернуть обратно'}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="container-title basket-title">
                            <h1>2. Контактные данные</h1>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mt-4'>
                        <form className="feedback">
                            <input id={'bskt-input-name'} className='w-100 input_contacts' onChange={(e) => setName(e.target.value)}  placeholder='Ваше имя' type="text"/>
                            <div id={'number-bskt'}>
                                <input className='w-100 input_contacts' placeholder='Ваш номер' type="text"
                                       onClick={() => {
                                           document.getElementById('number-bskt').classList.toggle('number-cont');
                                           document.getElementById('react-tel-input-bskt').classList.toggle('d-none')
                                       }}
                                />
                                <div id={'react-tel-input-bskt'} className={'d-none'}>
                                    <PhoneInput
                                        inputProps={{
                                            name: "phone",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        country="ru"
                                        countryCodeEditable={false}
                                        inputClass={'input_contacts'}
                                        className='d-none input_contacts'
                                        localization = {'ru'}
                                        placeholder={'Ваш номер'}
                                        enableAreaCodes={false}
                                        value={phone}
                                        onChange={setPhone}/>
                                </div>
                            </div>
                            <input id={'bskt-input-email'} className='w-100 input_contacts' onChange={(e) => setEmail(e.target.value)}  placeholder='Ваша почта' type="text"/>
                            <textarea id={'bskt-input-text'} className='w-100 input_contacts' onChange={(e) => setText(e.target.value)}  placeholder='Опишите вашу проблему'/>
                            <a className='btn-color_second w-100' onClick={handleSubmit}> Оформить запрос <img src="images/icons/btn-lg-next.svg" alt=""/></a>
                        </form>
                        <p className='privacy text-wrap'>Нажимая «Отправить» вы соглашаетесь <br/>c <a className='text-wrap' href="/privacy.pdf" download> политикой конфиденциальности</a></p>
                    </div>
                    <div className="col-12 col-lg-6 ">
                        <img className='basket_each d-none d-lg-block' src="/images/basket_img.png" alt=""/>
                    </div>
                </div>
            </div>
            <Footer width={width}/>
        </div>
    );
});

export default BasketPage;
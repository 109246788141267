import {makeAutoObservable} from "mobx";


export  default class ServicesPageStore {
    constructor() {
        this._service = [
            {id:0,
                name: 'Оценка рисков сельскохозяйственной деятельности на конкретной местности',
                mainimg: '/images/servicesPage/1_1.png',
                link: '/services/0',
                discription: 'Управление сельскохозяйственным участком подразумевает постоянное отслеживание множества процессов. Часто проблемы на участке незаметны на ранних этапах и момент, когда все можно легко исправить, может быть упущен.',
                svg: ['/red-2.png','/red-5.png','/red-3.png'],
                text_svg: ['низкий урожай','уменьшение выручки','почвенное истощение'],

                texnology_text: 'Используя базы данных аэрокосмического мониторинга, собираются разнообразные данные о состоянии участка. Космические снимки высокого разрешения дешифруются по нашим собственным методикам. Вся собранная информация обрабатывается с использованием автоматизированных моделей и искусственного интеллекта.',
                texnology_img: ['/science-1.png','/science-3.png','/science-7.png','/science-8.png'],
                texnology_text_img: ['информационный мониторинг','математическое моделирование','методика оценки состояния','разработка индивидуальных рекомендаций'],

                solution_text: 'Мы предоставляем подробное описание состояния участка, по результатам анализа вы сможете просчитать экономическую эффективность, составить план дальнейших работ, рассчитать количество необходимых материалов, определить план развития, учитывая все преимущества и недостатки. ',
                solution_img: ['/decision-5.png','/decision-7.png','/decision-2.png','/decision-8.png'],
                solution_text_img: ['экономическая оценка территории','комплексный подбор ресурсов для решения проблем','индивидуальная методика ликвидации проблем','оценка рисков и возможностей '],

                end_text: 'Результат исследования включает:',
                end_text_ul: ['информационный анализ территории','экспертную оценку','анализ рельефа, состава и качества почвы','определение уровня грунтовых вод и влажности','количество солнечной радиации и солнечного света','анализ выпадения и видов осадков','анализ роста сорняков','определение уровня здоровья растений','исследование сельскохозяйственных культур на благоприятность и совместимость агроклиматических, почвенных, биологических и гидрологических условий территории, на которых они произрастают.'],
                end_svg: ['/result-2.png','/result-3.png','/result-5.png','/result-6.png'],
                end_text_svg: ['использование современных технологий','диагностика состояния','мониторинг территории','методики борьбы и ликвидации '],
                chose: true
            },
            {id:1,
                name: 'Разработка индивидуальной методики на основе имеющихся проблем',
                mainimg: '/images/servicesPage/1_2.png',
                link: '/services/1',
                discription: 'Проблемы, возникающие на аграрных территориях, требуют индивидуального подхода. Невозможно использование одних и тех же стандартных схем для различных хозяйств, с отличающимися климатическими и территориальными особенностями.',
                svg: ['/red-3.png','/red-5.png','/red-7.png'],
                text_svg: ['почвенное истощение','уменьшение выручки','эрозия'],

                texnology_text: 'AEROSPACE-AGRO работает на основе информационно-аэрокосмических технологий. Снимки из космоса и данные полевых исследований обрабатываются с использованием математических моделей автоматизированных алгоритмов искусственного интеллекта. В результате разрабатываются методики и рекомендации для конкретного участка.',
                texnology_img: ['/science-1.png','/science-5.png','/science-8.png','/science-3.png'],
                texnology_text_img: ['информационный мониторинг','диагностика территории','разработка индивидуальных рекомендаций','математическое моделирование'],

                solution_text: 'Мы получаем информацию не только о существующих проблемах, но и видим причины их появления. Эксперты разрабатывают методики, которые помогают найти решения, а также предпринять меры по профилактике и сформировать план работ на основе экспертной оценки территории.',
                solution_img: ['/decision-3.png','/decision-2.png','/decision-6.png','/decision-9.png'],
                solution_text_img: ['экспертное заключение','индивидуальная методика ликвидации проблем','определение имеющихся проблем на территории','комплекс мониторинга'],

                end_text: 'В итоге, вы получаете готовое решение, с полным экономическим расчетом всех необходимых действий: от видов работ до количества и типов вносимых на участок удобрений. Вы можете приступать к реализации сразу же после получения, что положительно скажется на экономике участка.',
                end_svg: ['/result-2.png','/result-4.png','/result-6.png','/result-1.png'],
                end_text_svg: ['использование современных технологий','стабильный рост культур','методики борьбы и ликвидации ','высокий урожай '],
                chose: true},
            {id:2,
                name: 'Оценка и прогноз совместимости сельскохозяйственной деятельности и агроклиматических условий',
                mainimg: '/images/servicesPage/1_3.png',
                link: '/services/2',
                discription: 'Исследование агроклиматических условий, их влияния на произрастающие сельскохозяйственные культуры, является необходимой работой для получения максимальной информации о вашем участке для повышения количества и качества урожая. Мы анализируем:',
                discription_ul: ['состояния и состава почвы','изучение рельефа местности','состояние близлежащих территорий','экологическую ситуацию и ее изменения (засуха, влажность, ветер и прочее) благоприятность условий роста и урожайности различных культур исходя из вышеперечисленного.'],
                svg: ['/red-8.png','/red-3.png','/red-5.png'],
                text_svg: ['недостаточное выпадение осадков ','почвенное истощение','уменьшение выручки'],

                texnology_text: 'Исследование задействует данные российских и зарубежных ГИС (географических информационных систем), метеостанций. Анализируются снимки из космоса в высоком разрешении. Для обработки информации используется комплекс математических моделей, мы получаем прогноз совместимости условий и культур с учетом рисков и возможных вариантов развития участка',
                texnology_img: ['/science-1.png','/science-3.png','/science-7.png','/science-5.png'],
                texnology_text_img: ['информационный мониторинг','математическое моделирование','методика оценки состояния','диагностика территории'],

                solution_text: 'Вы получаете экспертное заключение о совместимости высаженных растений и территории, оценку экономической выгоды конкретного вида культуры с учетом особенностей участка, перечень рисков, рекомендации по увеличению урожайности.',
                solution_img: ['/decision-10.png','/decision-8.png','/decision-3.png','/decision-5.png'],
                solution_text_img: ['математическая модель прогноза','оценка рисков и возможностей','экспертное заключение','экономическая оценка территории'],

                end_text: 'По результатам анализа мы можем с высокой точностью оценить насколько особенности вашего участка позволяют успешно выращивать выбранные вами виды растений, а также предложить варианты иных культур, для которых данные условия будут наиболее благоприятными.',
                end_svg: ['/result-3.png','/result-5.png','/result-6.png','/result-2.png'],
                end_text_svg: ['диагностика состояния','мониторинг территории','методики борьбы и ликвидации','использование современных технологий'],
                chose: true},
            {id:3,
                name: 'Рельеф местности: оценка и прогноз деятельности',
                mainimg: '/images/servicesPage/1_4.png',
                link: '/services/3',
                discription: 'Знание рельефа участка и происходящих с ним изменений, во многом определяет его эффективность. Эрозия почв, риск возникновения оврагов, просадка земли и деградация грунта неэффективное районирование земель может привести к потерям 10-15% урожая.',
                svg: ['/red-3.png','/red-7.png','/red-5.png'],
                text_svg: ['почвенное истощение','эрозия','уменьшение выручки'],
                texnology_text: 'По результатам исследования космических снимков высокого разрешения создается информационно-аналитический рельеф территории, что позволяет детально оценить особенности рельефа участка и спрогнозировать возможные изменения.',
                texnology_img: ['/science-2.png','/science-4.png','/science-7.png','/science-9.png'],
                texnology_text_img: ['аэрофотосъемка ','цифровая карта полей','методика оценки состояния','разработка индивидуальной методики исследования'],

                solution_text: 'На основе детальной оценки рельефа разрабатываются методы решения актуальных проблем и предупреждения возможных негативных изменений. Вы сможете выбрать наиболее подходящее вам решение и выстроить план работ для поддержания максимального плодородия.',
                solution_img: ['/decision-4.png','/decision-6.png','/decision-8.png','/decision-2.png'],
                solution_text_img: ['цифровые 3D карты','определение имеющихся проблем на территории','оценка рисков и возможностей','индивидуальная методика ликвидации проблем '],

                end_text: 'Результаты исследований оформляются в информативную карту, модель территории, на которой выделены участки с минимальными недостатками рельефа и высоким потенциалом для получения максимального урожая. Предоставляется информация о проблемах на земельном участке и методах коррекции и профилактики.',
                end_svg: ['/result-6.png','/result-2.png','/result-5.png','/result-3.png'],
                end_text_svg: ['методики борьбы и ликвидации','использование современных технологий','мониторинг территории ','диагностика состояния '],
                chose: true},
            {id:4,
                name: 'Подбор экономически выгодных к посадке культур + практические рекомендации',
                mainimg: '/images/servicesPage/1_5.png',
                link: '/services/4',
                discription: 'Для повышения количества урожая необходимо учитывать соответствие участка земли и выбора экономически выгодного вида культур. Без учета особенностей состава почвы, экологической обстановки, погодных условий и других факторов, даже при идеальном уходе за растениями, вы не сможете получить оптимальный урожай и рост экономической эффективности территории.',
                svg: ['/red-2.png','/red-9.png','/red-8.png'],
                text_svg: ['низкий урожай','заболевания','недостаточное выпадение осадков'],

                texnology_text: 'Используя методы аэрокосмического мониторинга и дешифровки анализа почвы, мы получаем все необходимые данные для построения моделей прогноза рисков и успешного развития земельного участка. Сопоставляется множество взаимовлияющих параметров. ',
                texnology_img: ['/science-1.png','/science-3.png','/science-7.png','/science-10.png'],
                texnology_text_img: ['информационный мониторинг','математическое моделирование','методика оценки состояния','исследование  научного сотрудника'],

                solution_text: 'Мы проводим дешифровку анализа почвы и соотношения химических элементов, учитываем экологические факторы, совместимость растений и особенностей участка. На основе результатов исследования территории формируется список культур, условия для которых оптимальны на данном участке. Подготавливается подробное описание всех видов растений, для которых данный участок будет наиболее благоприятен, рекомендации по посадке, уходу, график внесения удобрений и обработки земли. ',
                solution_img:  ['/decision-2.png','/decision-5.png','/decision-8.png','/decision-10.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем','экономическая оценка территории','оценка рисков и возможностей','математическая модель прогноза'],

                end_text: 'Всё это позволяет подробно спланировать дальнейшую работу на участке и получить максимальную экономическую выгоду после сбора урожая.',
                end_svg: ['/result-1.png','/result-5.png','/result-2.png','/result-4.png'],
                end_text_svg: ['высокий урожай ','мониторинг территории','использование современных технологий','стабильный рост культур '],
                chose: true},
            {id:5,
                name: 'Анализ влажности почв + прогноз засух',
                mainimg: '/images/servicesPage/1_6.png',
                link: '/services/5',
                discription: 'Здоровье выращиваемых культур и количество получаемого урожая, зависит от уровня влажности почвенного слоя земли. Для благоприятного роста культур необходимо учитывать вероятность засухи и иметь план действий для решения данной проблемы.',
                svg: ['/red-8.png','/red-2.png','/red-3.png'],
                text_svg: ['недостаточное выпадение осадков','низкий урожай','почвенное истощение'],

                texnology_text: 'По данным информационно-аэрокосмических технологий выстраивается математическая модель состояния климата, специалисты составляют наиболее вероятный прогноз погодных условий и их влияние на состояния территории.',
                texnology_img: ['/science-1.png','/science-5.png','/science-3.png','/science-9.png'],
                texnology_text_img: ['информационный мониторинг','диагностика территории ','математическое моделирование','разработка индивидуальной методики исследования'],

                solution_text: 'Разработанные авторские методики изучения сельскохозяйственных земель позволяют составить рекомендации по повышению или понижению влажности, а также разработать порядок действий в случае возникновения засухи, чтобы данное явление оказало минимальное влияние на территорию.',
                solution_img: ['/decision-3.png','/decision-8.png','/decision-10.png','/decision-6.png'],
                solution_text_img: ['экспертное заключение','оценка рисков и возможностей ','математическая модель прогноза','определение имеющихся проблем на территории '],

                end_text: 'Своевременный контроль оптимальной влажности почвы и предотвращение засухи позволят контролировать урожай и сэкономить средства за счет эффективного планирования и ухода за участком.',
                end_svg: ['/result-2.png','/result-5.png','/result-6.png','/result-1.png'],
                end_text_svg: ['использование современных технологий','мониторинг территории','методики борьбы и ликвидации','высокий урожай'],
                chose: true},
            {id:6,
                name: 'Эрозия почвы: анализ и прогноз',
                mainimg: '/images/servicesPage/1_7.png',
                link: '/services/6',
                discription: 'Эрозия почвы оказывает огромное влияние на развитие сельскохозяйственных культур. Это медленный и незаметный процесс. При самом неблагоприятном прогнозе, территория становится непригодной для дальнейших сельскохозяйственных работ.',
                svg: ['/red-7.png','/red-8.png','/red-2.png'],
                text_svg: ['эрозия','недостаточное выпадение осадков ','низкий урожай'],

                texnology_text: 'Информационно-аэрокосмические технологии позволяют определить уровень эрозии почвы и состояние участка в данный момент. Благодаря инновационным математическим моделям исследований и анализу собранных данных, мы определяем состояние почвы и составляем план работ по устранению проблемы.',
                texnology_img: ['/science-2.png','/science-3.png','/science-4.png','/science-8.png'],
                texnology_text_img: ['аэрофотосъемка','математическое моделирование','цифровая карта полей','разработка индивидуальных рекомендаций'],

                solution_text: 'На основе анализа мы предоставляем модель экологической ситуации на территории, информацию о климатических условиях, прогноз по эрозии почвы. Исследование актуально для любых участков, проблема эрозии незаметна на ранних этапах, поэтому важно держать ситуацию под контролем и обладать полной информацией. Подробные рекомендации и проработанный план сразу же можно применять на практике для решения существующих проблем и для профилактики. ',
                solution_img: ['/decision-1.png','/decision-4.png','/decision-6.png','/decision-10.png'],
                solution_text_img: ['ситуационная модель состояния','цифровые 3D карты','определение имеющихся проблем на территории ','математическая модель прогноза'],

                end_text: 'Эти данные позволяют планировать работы на участке с учетом всех особенностей и рисков, ликвидировать эрозию почвы и не допустить ее развитие.',
                end_svg: ['/result-4.png','/result-6.png','/result-3.png','/result-1.png'],
                end_text_svg: ['стабильный рост культур ','методики борьбы и ликвидации','диагностика состояния','высокий урожай '],
                chose: true},
            {id:7,
                name: 'Минеральный анализ почвы + практические рекомендации - структура ',
                mainimg: '/images/servicesPage/1_8.png',
                link: '/services/7',
                discription: 'Учет химического состава почвы важен при планировании посадок и выборе растений. Без минерального состава почвы сложно подобрать необходимые удобрения, учесть негативное влияния веществ на рост и урожай. Определение уровня наличия химических элементов в почве позволяет эффективно рассчитать рост культур на территории, с помощью анализа почвенного слоя на засоление и минерального состава в целом. ',
                svg: ['/red-3.png','/red-2.png','/red-7.png'],
                text_svg: ['почвенное истощение','низкий урожай','эрозия'],

                texnology_text: 'Эффективность анализа минерального состава почвы достигается за счет комплексного исследования. Мы собираем данные метеостанций, проводим информационный анализ территории, производим дешифровку радарных съемок из космоса, получаем экспертную оценку специалистов.',
                texnology_img: ['/science-1.png','/science-5.png','/science-10.png','/science-9.png'],
                texnology_text_img: ['информационный мониторинг','диагностика территории ','исследование  научного сотрудника','разработка индивидуальной методики исследования'],

                solution_text: 'Минеральный анализ почвы содержит подробную информацию не только о взаимосвязи актуального соотношении химических элементов и развития растений на участке. Вы получите рекомендации по внесению удобрений с учетом климатических особенностей: температуры, количества осадков, влажности, как и когда необходимо применять определенные микроэлементы для устранения дефицитов и увеличения урожая.',
                solution_img: ['/decision-2.png','/decision-6.png','/decision-8.png','/decision-10.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем','определение имеющихся проблем на территории ','оценка рисков и возможностей ','математическая модель прогноза'],

                end_text: 'Анализ данных позволяет прогнозировать количество урожая и составить график внесения удобрений. По результатам минерального анализа формируются рекомендации по внесению удобрений с учетом возможных потерь микроэлементов, их поглощаемости почвой, температурных условий и осадков. Вы сможете составить график внесения удобрений и добиться оптимальных результатов.',
                end_svg: ['/result-7.png','/result-8.svg','/result-4.png','/result-3.png'],
                end_text_svg: ['индивидуальный подход','разработка комплекса решений','стабильный рост культур ','диагностика состояния'],
                chose: true},
            {id:8,
                name: 'Составление индивидуального графика внесения удобрений',
                mainimg: '/images/servicesPage/2_1.png',
                link: '/services/8',
                discription: 'Правильное и рациональное внесение удобрений является необходимым процессом для качественного роста здоровой культуры и высокой урожайности. График и точный расчет необходимых элементов позволит избежать потери урожая в целом.',
                svg: ['/red-3.png','/red-4.png','/red-9.png'],
                text_svg: ['почвенное истощение','сорняки','заболевания'],

                texnology_text: 'Мы используем информационно-аэрокосмические технологии для исследования и анализа территории. Исследуем данные о погодных условиях, климатических особенностях, состоянии почвы, наличии вредителей и других факторах. \n',
                texnology_img: ['/science-1.png','/science-8.png','/science-3.png','/science-5.png'],
                texnology_text_img: ['информационный мониторинг','разработка индивидуальных рекомендаций','математическое моделирование ','диагностика территории '],

                solution_text: 'На основе собранных данных строятся математические модели, которые позволяют выстроить схему работы по внесению удобрений. Каждое решение индивидуально и учитывает особенности и задачи конкретного участка земли.',
                solution_img: ['/decision-10.png','/decision-2.png','/decision-8.png','/decision-7.png'],
                solution_text_img: ['математическая модель прогноза','индивидуальная методика ликвидации проблем','оценка рисков и возможностей ','комплексный подбор ресурсов для решения проблем'],

                end_text: 'На основе исследования участка предоставляется индивидуальная схема работы с удобрениями',
                end_text_ul: ['создание виртуальной карты полей с разделением на участки','подробным описанием необходимых вам видов удобрений','план внесения удобрений в необходимом количестве для каждого сектора.'],
                end_svg: ['/result-3.png','/result-5.png','/result-1.png','/result-4.png'],
                end_text_svg: ['диагностика состояния','мониторинг территории','высокий урожай ','стабильный рост культур '],
                chose: true},
            {id:9,
                name: 'Заболевание растений: анализ и прогноз',
                mainimg: '/images/servicesPage/2_2.png',
                link: '/services/9',
                discription: 'Состояние здоровья растений является основополагающим фактором роста и урожайности культуры. Своевременное выявление заболеваний позволяет заблаговременно разработать методику лечения и восстановления, а также предупредить дальнейшее его развитие. ',
                svg: ['/red-9.png','/red-6.png','/red-2.png'],
                text_svg: ['заболевания','засуха','низкий урожай'],

                texnology_text: 'Анализ снимков из космоса высокого разрешения позволяет выявить текущие болезни культур, их распространение и учесть все аспекты, которые могут быть причинами заболеваний. С помощью моделирования строится прогноз последствий распространения пораженных культур, оцениваются риски и возможный ущерб.',
                texnology_img: ['/science-2.png','/science-3.png','/science-5.png','/science-9.png'],
                texnology_text_img: ['аэрофотосъемка','математическое моделирование ','диагностика территории ','разработка индивидуальной методики исследования'],

                solution_text: 'По результатам анализа собирается полный перечень факторов, влияющих на появление и распространение заболеваний. Моделируются прогнозы развития ситуации и количественные показатели возможных потерь урожая.',
                solution_img: ['/decision-3.png','/decision-10.png','/decision-6.png','/decision-8.png'],
                solution_text_img: ['экспертное заключение','математическая модель прогноза','определение имеющихся проблем на территории ','оценка рисков и возможностей '],

                end_text: 'Вы получаете заключение об актуальном состоянии полей и распространении заболеваний растений, стадиях, рисках при развитии болезней, влияние на качество и количество урожая и прогноз убытков.',
                end_svg: ['/result-3.png','/result-4.png','/result-6.png','/result-5.png'],
                end_text_svg: ['диагностика состояния','стабильный рост культур ','методики борьбы и ликвидации','мониторинг территории'],
                chose: true},
            {id:10,
                name: 'Разработка индивидуальной методики лечения растений',
                mainimg: '/images/servicesPage/2_3.png',
                link: '/services/10',
                discription: 'Заболевание растений требует индивидуального подхода к лечению культуры, исходя из особенностей почвенного слоя, климатических и прочих экологических составляющих территории. Своевременное реагирование на проблему лечения растений позволяет сохранить культуру и минимизировать потерю урожая.',
                svg: ['/red-9.png','/red-10.png','/red-2.png'],
                text_svg: ['заболевания','неэффективные методики работы ','низкий урожай'],

                texnology_text: 'Мы используем данные аэрокосмического мониторинга. Разрабатываем индивидуальные методики по лечению заболеваний растений, с учётом их развития в данный момент, и потенциального развития в будущем.',
                texnology_img: ['/science-7.png','/science-8.png','/science-9.png','/science-10.png'],
                texnology_text_img: ['методика оценки состояния','разработка индивидуальных рекомендаций','разработка индивидуальной методики исследования','исследование  научного сотрудника'],

                solution_text: 'Лечение заболеваемости определённой культуры должно разрабатывается индивидуально, исходя из причины появления болезни, факторов экологического и агроклиматического состояния территории, особенностей региона.',
                solution_img: ['/decision-2.png','/decision-3.png','/decision-7.png','/decision-8.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем','экспертное заключение ','комплексный подбор ресурсов для решения проблем','оценка рисков и возможностей '],

                end_text: 'Своевременное решение проблемы заболеваний растений и профилактика позволяет получать запланированный урожай и сделать ваш участок прибыльным.',
                end_svg: ['/result-8.svg','/result-5.png','/result-6.png','/result-3.png'],
                end_text_svg: ['разработка комплекса решений','мониторинг территории','методики борьбы и ликвидации','диагностика состояния'],
                chose: true},
            {id:11,
                name: 'Составления карты наличия сорняков на участке + прогнозирование развития',
                mainimg: '/images/servicesPage/2_4.png',
                link: '/services/11',
                discription: 'Сорняки препятствуют росту растений, распространяют заболевания и влияют на урожай.',
                svg: ['/red-2.png','/red-4.png','/red-9.png'],
                text_svg: ['низкий урожай','сорняки','заболевания'],

                texnology_text: 'Для анализа сорняков мы используем данных полученные при обработке космических снимков высокого разрешения в различных спектрах съемки. На основе чего разрабатывается карта, которая показывает местонахождение и очаги сорняков. Сорняки идентифицируются по классу опасности ',
                texnology_img: ['/science-1.png','/science-5.png','/science-7.png','/science-4.png'],
                texnology_text_img: ['информационный мониторинг','диагностика территории ','методика оценки состояния','цифровая карта полей'],

                solution_text: 'Благодаря высокой детальности космических снимков, удается отследить все очаги разрастания сорняков и определить их дальнейшее распространение и развитие. После этого мы обрабатываем полученную информацию и составляем полную карту состояния участка.',
                solution_img: ['/decision-6.png','/decision-3.png','/decision-10.png','/decision-8.png'],
                solution_text_img: ['определение имеющихся проблем на территории ','экспертное заключение ','математическая модель прогноза','оценка рисков и возможностей '],

                end_text: 'Вы получите данные о влиянии сорняков на рост культур и информационную карту с рекомендациями по устранению сорняков и методами по предотвращению их распространения. Своевременное устранение сорняков на ранних этапах позволит сберечь ваш урожай.',
                end_svg: ['/result-7.png','/result-8.svg','/result-6.png','/result-4.png'],
                end_text_svg: ['индивидуальный подход','разработка комплекса решений','методики борьбы и ликвидации','стабильный рост культур '],
                chose: true},
            {id:12,
                name: 'Разработка индивидуальной методики борьбы с сорняками',
                mainimg: '/images/servicesPage/2_5.png',
                link: '/services/12',
                discription: 'Сорняки ⏤ угроза для любой сельскохозяйственной культуры. Борьба с ними требует качественного и индивидуального подхода. Сорняки не дают развиваться посаженным культурам, могут переносить заболевания и вредителей, переопыление с сорняками ухудшает сортовые качества растений. ',
                svg: ['/red-4.png','/red-2.png','/red-8.png'],
                text_svg: ['сорняки','низкий урожай','недостаточное выпадение осадков '],

                texnology_text: 'Анализ космических снимков высокого разрешения позволяет определить видовой состав и распространение сорняков, условия и факторы влияющие на их развитие. По этим данным создаются методики по ликвидации сорняков для каждого отдельного случая.',
                texnology_img: ['/science-2.png','/science-8.png','/science-9.png','/science-3.png'],
                texnology_text_img: ['аэрофотосъемка','разработка индивидуальных рекомендаций','разработка индивидуальной методики исследования','математическое моделирование '],

                solution_text: 'Полученные данные мы используем для разработки методики, которая учитывает все особенности территории, а также климатические особенности региона. Мы определяем не только наличие и виды сорняков, но и область их распространение и их влияние на произрастающие на территории культуры. ',
                solution_img: ['/decision-2.png','/decision-3.png','/decision-1.png','/decision-10.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем','экспертное заключение ','ситуационная модель состояния','математическая модель прогноза'],

                end_text: 'Применив наши рекомендации, вы сможете бороться с сорняками без вреда для вашей урожайности и здоровья культур.По результатам исследования вы получите набор методик для борьбы с сорняками на вашем участке без вреда для здоровья культур и урожайности и сможете выбрать наиболее подходящие вам рекомендации.',
                end_svg: ['/result-6.png','/result-7.png','/result-8.svg','/result-10.svg'],
                end_text_svg: ['методики борьбы и ликвидации','индивидуальный подход','разработка комплекса решений','работа научных экспертов'],
                chose: true},
            {id:13,
                name: 'Комплекс исследований и оценка участка на наличие вредителей',
                mainimg: '/images/servicesPage/2_6.png',
                link: '/services/13',
                discription: 'Здоровая экосистема территории — ключ к высокому качеству почвы и уровню урожайности. Вредители могут нанести большой урон растением и почве, стать причиной потери урожая. Проработанная схема борьбы с вредителями поможет вам сохранить культуры.',
                svg: ['/red-1.png','/red-10.png','/red-9.png'],
                text_svg: ['наличие вредителей ','неэффективные методики работы','заболевания'],

                texnology_text: 'На основе космических снимков высокого разрешения собирается множество данных. После анализа информации, формируется полный отчет о текущей ситуации с вредителями на вашем участке, схема их распространения, прогноз ущерба и последствий. На основе этих данных мы создаем план работы и профилактики по борьбе с вредителями. Далее, нами будет разработана модель возможно распространения и последствий, при помощи которой мы сможем оценить возможные риски.',
                texnology_img: ['/science-2.png','/science-8.png','/science-5.png','/science-7.png'],
                texnology_text_img: ['аэрофотосъемка','разработка индивидуальных рекомендаций','диагностика территории','методика оценки состояния'],

                solution_text: 'Для борьбы с вредителями за объем урожая и состояние участка формируется список необходимых ресурсов, сроков, действий и решений, с подробным описанием, чтобы вы могли незамедлительно приступить к решению проблемы. ',
                solution_img: ['/decision-7.png','/decision-8.png','/decision-10.png','/decision-2.png'],
                solution_text_img: ['комплексный подбор ресурсов для решения проблем','оценка рисков и возможностей ','математическая модель прогноза','индивидуальная методика ликвидации проблем '],

                end_text: 'Применив рекомендации по борьбе с вредителями, вы сможете обеспечить стабильный рост культур и высокую урожайность.',
                end_svg: ['/result-3.png','/result-4.png','/result-7.png','/result-6.png'],
                end_text_svg: ['диагностика состояния','стабильный рост культур ','индивидуальный подход','методики борьбы и ликвидации'],
                chose: true},
            {id:14,
                name: 'Индивидуальный подбор методики борьбы с вредителями',
                mainimg: '/images/servicesPage/2_7.png',
                link: '/services/14',
                discription: 'Сельскохозяйственные вредители могут сильно уменьшить урожайность, нанести вред растениям и почве, даже уничтожить урожай',
                svg: ['/red-1.png','/red-5.png','/red-2.png'],
                text_svg: ['наличие вредителей ','уменьшение выручки','низкий урожай'],

                texnology_text: 'На основе данных аэрокосмического мониторинга собирается информация о распространении, видовом разнообразии вредителей на вашем участке, факторах, влияющих на рост их численности. Мы анализируем актуальную ситуацию, строим прогноз. Разработаем и подготавливаем индивидуальную методику по борьбе с вредителями для конкретной территории',
                texnology_img: ['/science-2.png','/science-5.png','/science-7.png','/science-3.png'],
                texnology_text_img: ['аэрофотосъемка','диагностика территории ','методика оценки состояния','математическое моделирование '],

                solution_text: 'Процесс борьбы пройдет без вреда для вашего урожая. На основе данных аэрокосмической съемки мы составим для вас индивидуальную методику по борьбе с вредителями и определим период развития и размножения насекомых, зависимость от климатических условий и произрастающих культур, а также влияние вредителей на урожайность участка.',
                solution_img: ['/decision-2.png','/decision-10.png','/decision-1.png','/decision-7.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем ','математическая модель прогноза','ситуационная модель состояния ','комплексный подбор ресурсов для решения проблем'],

                end_text: 'С помощью наших методик вы избавитесь от вредителей на участке и сможете сфокусироваться на увеличении урожая.',
                end_svg: ['/result-7.png','/result-8.svg','/result-4.png','/result-10.svg'],
                end_text_svg: ['индивидуальный подход','разработка комплекса решений','стабильный рост культур ','работа научных экспертов'],
                chose: true},
            {id:15,
                name: 'Долгосрочный прогноз осадков',
                mainimg: '/images/servicesPage/2_8.png',
                link: '/services/15',
                discription: 'Погода ⏤ один из самых важных факторов, влияющих на рост и урожайность культур. Уровень осадков влияет на заболеваемость растений, уровень размножения вредителей, рост сорняков. Осадки могут разрушать почву и способствовать развитию эрозии.',
                svg: ['/red-8.png','/red-5.png','/red-6.png'],
                text_svg: ['недостаточное выпадение осадков ','уменьшение выручки','засуха'],

                texnology_text: 'По данным анализа космических снимков высокого разрешения с помощью данных дистанционного зондирования и анализа специалистами строится математическая модель, прогнозирующая уровень осадков в долгосрочной перспективе. При построении модели мы пользуемся решениями, разработанными с использованием искусственного интеллекта.',
                texnology_img: ['/science-3.png','/science-10.png','/science-9.png','/science-6.png'],
                texnology_text_img: ['математическое моделирование ','исследование  научного сотрудника','разработка индивидуальной методики исследования','разработка нейронных сетей'],

                solution_text: 'Данная модель подробно описывает долгосрочный ритм осадков на вашей и близлежащих территориях. Учитывая эту информацию при долгосрочном планировании, вы можете рассчитать необходимые ресурсы и составить график действий, что позволит избежать внеплановых затрат и серьезных проблем с переувлажнением или пересыханием почвы. ',
                solution_img: ['/decision-10.png','/decision-8.png','/decision-7.png','/decision-1.png'],
                solution_text_img: ['математическая модель прогноза','оценка рисков и возможностей ','комплексный подбор ресурсов для решения проблем','ситуационная модель состояния '],

                end_text: 'Благодаря этой модели вы сможете получить полноценную картину состояния участка и построить стратегию дальнейшей работы с ним.',
                end_svg: ['/result-7.png','/result-8.svg','/result-9.png','/result-2.png'],
                end_text_svg: ['индивидуальный подход','разработка комплекса решений','оценка состояния ','использование современных технологий'],
                chose: true},
            {id:16,
                name: 'Подбор индивидуальной методики севооборота с максимальным экономическом эффектом',
                mainimg: '/images/servicesPage/3_1.png',
                link: '/services/16',
                discription: 'Правильная методика севооборота поможет вам грамотно использовать земельные ресурсы и повысить урожайность, не увеличивая затраты на удобрения. Без грамотного планирование севооборота на участке возникает множество проблем: рост числа сорняков, обеднение почвы, нарушение баланса микроорганизмов, заболевания растений. ',
                svg: ['/red-10.png','/red-5.png','/red-2.png'],
                text_svg: ['неэффективные методики работы','уменьшение выручки','низкий урожай'],

                texnology_text: 'Для предотвращения проблем, мы разрабатываем индивидуальную методику севооборота, основываясь на минеральном анализе почвы, экологических и климатических условиях территории, а также особенностей самого участка земли. При разработке мы используем данные аэрокосмического мониторинга и экспертную оценку участка специалистами. ',
                texnology_img: ['/science-1.png','/science-7.png','/science-3.png','/science-9.png'],
                texnology_text_img: ['информационный мониторинг','методика оценки состояния','математическое моделирование ','разработка индивидуальной методики исследования'],

                solution_text: 'Методика, сформированная на основе результатов исследований, позволяет учесть множество факторов и планировать севооборот максимально эффективно с точки зрения использования и восстановления ресурсов для получения оптимального урожая.',
                solution_img: ['/decision-7.png','/decision-5.png','/decision-1.png','/decision-8.png'],
                solution_text_img: ['комплексный подбор ресурсов для решения проблем','экономическая оценка территории','ситуационная модель состояния ','оценка рисков и возможностей '],

                end_text: 'По итогам исследования вы получите модель посадки сроком на сезон. Она включает в себя рекомендации по посадке культур и рекомендации по уходу за растениями и территорией. Использование данной модели в процессе производства поможет вам достичь максимальной урожайности, а также рационального использования ваших земельных ресурсов.',
                end_svg: ['/result-8.svg','/result-6.png','/result-5.png','/result-10.svg'],
                end_text_svg: ['разработка комплекса решений','методики борьбы и ликвидации','мониторинг территории','работа научных экспертов'],
                chose: true},
            {id:17,
                name: 'Экологическая оценка территории ',
                mainimg: '/images/servicesPage/3_2.png',
                link: '/services/17',
                discription: 'Экологическое состояние окружающей среды ⏤ один из многих факторов, влияющих на эффективность вашего участка. Игнорирование экологической обстановки может привести к потере урожая и обеднению почвы.',
                svg: ['/red-3.png','/red-7.png','/red-9.png'],
                text_svg: ['почвенное истощение','эрозия','заболевания'],

                texnology_text: 'С помощью информационно-аэрокосмических технологий мы получаем подробную информацию не только об интересующей нас местности, но и о прилегающих территориях. Эти данные содержат информацию о факторах риска загрязнения промышленными и технологическими отходами, наличия водоемов, которые могут связывать далеко расположенные участки.',
                texnology_img: ['/science-1.png','/science-9.png','/science-5.png','/science-7.png'],
                texnology_text_img: ['информационный мониторинг','разработка индивидуальной методики исследования','диагностика территории ','методика оценки состояния'],

                solution_text: 'Анализ экологической обстановки в регионе определяет наличие и расположение крупных объектов (заводов, рек, запруд, железных дорог), влияющих на состояние почвы и растений, в радиусе до 300 км от вашей территории. Кроме этого, изучается наличие мутаций у растений, перенасыщение почвы минералами и осадки в виде кислотных или химических дождей.',
                solution_img: ['/decision-2.png','/decision-6.png','/decision-8.png','/decision-1.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем ','определение имеющихся проблем на территории ','оценка рисков и возможностей ','ситуационная модель состояния '],

                end_text: 'Результаты нашего анализа сформированы в виде описания данных и рекомендаций, которые помогут сохранить почву в оптимальном состоянии с учетом всех влияющих факторов, и получать с нее высокий уровень урожая каждый год. ',
                end_svg: ['/result-6.png','/result-8.svg','/result-9.png','/result-2.png'],
                end_text_svg: ['методики борьбы и ликвидации','разработка комплекса решений','оценка состояния ','использование современных технологий'],
                chose: true},
            {id:18,
                name: 'Прогноз экономической эффективности участка',
                mainimg: '/images/servicesPage/3_3.png',
                link: '/services/18',
                discription: 'Правильный уход за участком, высаживание лучших образцов культур, своевременное внесение удобрений не является залогом экономически выгодного бизнеса. Урожайность и эффективность использования территории зависят от множества факторов.',
                svg: ['/red-2.png','/red-5.png','/red-3.png'],
                text_svg: ['низкий урожай','уменьшение выручки','почвенное истощение'],

                texnology_text: 'Анализ данных на основе космических снимков высокого разрешения и построение математических моделей, с помощью искусственного интеллекта, позволяет создать детальный прогноз экономической эффективности сельскохозяйственного участка.',
                texnology_img: ['/science-1.png','/science-6.png','/science-3.png','/science-7.png'],
                texnology_text_img: ['информационный мониторинг','разработка нейронных сетей','математическое моделирование ','методика оценки состояния'],

                solution_text: 'Математическое моделирование позволяет сопоставить множество данных об участке, условиях, факторах риска, учесть необходимые ресурсы для развития и оптимизации работы. В результате формируется список рекомендаций, который позволит вам планировать работы на участке не только на ближайший сезон, но и в долгосрочной перспективе. Моделирование обеспечивает грамотную оценку качества вашей территории и актуальные рекомендации по ее улучшению.',
                solution_img: ['/decision-8.png','/decision-10.png','/decision-5.png','/decision-1.png'],
                solution_text_img: ['оценка рисков и возможностей ','математическая модель прогноза','экономическая оценка территории','ситуационная модель состояния '],

                end_text: 'Подробный отчет по результатам исследования и прогнозирование наиболее удачных сценариев развития поможет быстро сориентироваться и выстроить план работ с учетом особенностей вашей территории, имеющихся ресурсов. Получить высокую урожайность, минимизировать риски и сформировать верный путь развития вашего хозяйства.',
                end_svg: ['/result-8.svg','/result-3.png','/result-5.png','/result-10.svg'],
                end_text_svg: ['разработка комплекса решений','диагностика состояния','мониторинг территории','работа научных экспертов'],
                chose: true},
            {id:19,
                name: '«Тест-драйв»',
                mainimg: '/images/servicesPage/3_4.png',
                link: '/services/19',
                discription: 'Если вы еще не уверены в качестве наших услуг, мы готовы предложить вам услугу Тест-драйв. Вы можете протестировать эффективность наших методов и технологий на небольшом участке и оценить их актуальность, эффективность, степень рентабельности. Услуга позволит протестировать наши методики и технологии на небольшом участке, оценить их эффективность и экономическую рентабельность.',
                svg: ['/red-2.png','/red-5.png','/red-10.png'],
                text_svg: ['низкий урожай','уменьшение выручки','неэффективные методики работы '],

                texnology_text: 'Мы используем информационно-аэрокосмические технологии для сбора большого массива данных для всестороннего анализа вашего участка. Анализ информации производится с использованием математических моделей и искусственного интеллекта. На основе результатов подробного анализа специалисты разрабатывают методики и рекомендации для каждого конкретного участка.',
                texnology_img: ['/science-1.png','/science-3.png','/science-5.png','/science-8.png'],
                texnology_text_img: ['информационный мониторинг','математическое моделирование ','диагностика территории ','разработка индивидуальных рекомендаций'],

                solution_text: 'Мы проведем и изучим для вас:',
                solution_text_ul: [
                    'анализ рельефа',
                    'минеральный анализ почв',
                    'исследование уровня эрозии и влияние уклона',
                    'оценку заболеваемости растений и предоставим методики по лечению',
                    'информацию о сорняках и поможем их побороть',
                    'мониторинг экологической ситуации на территории',
                    'расчет экономической эффективности участка',
                    'уровень поражения вашей территории вредителями и предоставим методики по борьбе с ними',
                    'исследование влажности почвы и определим долгосрочные ритмы осадков'

                ],
                solution_img: ['/decision-1.png','/decision-7.png','/decision-2.png','/decision-10.png'],
                solution_text_img: ['ситуационная модель состояния ','комплексный подбор ресурсов для решения проблем','индивидуальная методика ликвидации проблем ','математическая модель прогноза'],

                end_text: 'Вы получите результаты полного анализа небольшого тестового участка, методики и рекомендации, по которым сможете сформировать пошаговый план действий для развития и оптимизации работ. Оцените все преимущества наших исследований в сравнении с обычным полевым анализом.',
                end_svg: ['/result-3.png','/result-6.png','/result-7.png','/result-8.svg'],
                end_text_svg: ['диагностика состояния','методики борьбы и ликвидации','индивидуальный подход','разработка комплекса решений'],
                chose: true},
            {id:20,
                name: '«Все включено»',
                mainimg: '/images/servicesPage/3_5.png',
                link: '/services/20',
                discription: 'Вам необходимо срочно поднять прибыльность участка, нужно решить множество проблем за короткий срок. Вы обрабатываете большие сельскохозяйственные угодья, ваши участки расположены на различных территориях. Множество проблем решается с помощью услуги “Все включено”.',
                svg: ['/red-3.png','/red-8.png','/red-5.png'],
                text_svg: ['почвенное истощение','недостаточное выпадение осадков','уменьшение выручки'],

                texnology_text: 'Информационно-аэрокосмические технологии позволяют собрать огромный массив данных о земельных участках. Данные аэрокосмического мониторинга и полевых исследований анализируются с помощью математических моделей и искусственного интеллекта. Это позволяет учесть огромное количество взаимовлияющих факторов и выработать эффективную работающую стратегию, включающую решение текущих проблем, профилактику и план развития.',
                texnology_img: ['/science-2.png','/science-10.png','/science-9.png','/science-3.png'],
                texnology_text_img: ['аэрофотосъемка','исследование  научного сотрудника','разработка индивидуальной методики исследования','математическое моделирование '],

                solution_text: 'Вы получите подробные рекомендации для оптимизации работ на территории и повышения эффективности. Мы также составим индивидуальные стратегии по работе с культурами, удобрениями, вредителями, почвой. Опишем погодные и климатические особенности, экологическое состояние вашей территории на данный момент и в долгосрочной перспективе.',
                solution_img: ['/decision-2.png','/decision-7.png','/decision-10.png','/decision-5.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем ','комплексный подбор ресурсов для решения проблем','математическая модель прогноза','экономическая оценка территории'],

                end_text: 'Обладая полными данными анализа, перечнем методик и рекомендаций, вы сможете улучшить производительность вашего хозяйства, выстроить стратегию развития, увеличить прибыли и привлечь инвестиции. ',
                end_svg: ['/result-1.png','/result-4.png','/result-5.png','/result-8.svg'],
                end_text_svg: ['высокий урожай','стабильный рост культур ','мониторинг территории','разработка комплекса решений'],
                chose: true},
            {id:21,
                name: 'Комплексное исследование участка',
                mainimg: '/images/servicesPage/3_6.png',
                link: '/services/21',
                discription: 'Важнейший этап сельскохозяйственной работы ⏤ это период посева. Для грамотных посевных работ производителю необходимо иметь максимальное количество информации о его территории.',
                svg: ['/red-8.png','/red-3.png','/red-1.png'],
                text_svg: ['недостаточное выпадение осадков ','почвенное истощение','наличие вредителей'],

                texnology_text: 'Для анализа территории перед посевом используются данные аэрокосмического мониторинга. Полученную информацию мы обрабатываем с применением математических моделей. ',
                texnology_img: ['/science-1.png','/science-7.png','/science-8.png','/science-3.png'],
                texnology_text_img: ['информационный мониторинг','методика оценки состояния','разработка индивидуальных рекомендаций','математическое моделирование '],

                solution_text: 'Формируется комплекс работ на весь сезон. Мы определяем урожайность культур и составляем график посадки и обработки территории, рекомендации по применению удобрений. Кроме этого, формируется перечень потенциальных проблем и предлагаются способы их устранения и профилактики.',
                solution_img: ['/decision-2.png','/decision-7.png','/decision-6.png','/decision-3.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем ','комплексный подбор ресурсов для решения проблем','определение имеющихся проблем на территории ','экспертное заключение '],

                end_text: 'Вы получите анализ текущего состояния участка и рекомендации, которые помогут грамотно составить план работ на весь сезон. По данным прогноза вы сможете выстроить стратегии развития для получения максимальной прибыли.',
                end_svg: ['/result-5.png','/result-7.png','/result-9.png','/result-3.png'],
                end_text_svg: ['мониторинг территории','индивидуальный подход','оценка состояния ','диагностика состояния'],
                chose: true},
            {id:22,
                name: 'Мониторинг с/х угодий в течение сезона',
                mainimg: '/images/servicesPage/3_7.png',
                link: '/services/22',
                discription: 'Задача получения максимального урожая подразумевает контроль множества внешних факторов, например, погодных условий, экологической обстановки, а также четкое планирование работ на участке: посадка, внесение удобрений, обработка земли, профилактика и борьба с вредителями и сорняками. Несвоевременное решение проблем ведет к усугублению ситуации и потере эффективности.',
                svg: ['/red-8.png','/red-4.png','/red-1.png'],
                text_svg: ['недостаточное выпадение осадков ','сорняки','наличие вредителей '],

                texnology_text: 'Данные космических снимков высокого разрешения, анализируются с помощью алгоритмов искусственного интеллекта, мониторинг происходит в непрерывном режиме.',
                texnology_img: ['/science-1.png','/science-6.png','/science-7.png','/science-5.png'],
                texnology_text_img: ['информационный мониторинг','разработка нейронных сетей','методика оценки состояния','диагностика территории '],

                solution_text: 'Важнейшие факторы, влияющие на урожай отслеживаются в режиме 24/7, это позволяет своевременно фиксировать все изменения и формировать рекомендации для устранения проблем на этапе их зарождения.',
                solution_img: ['/decision-9.png','/decision-6.png','/decision-1.png','/decision-3.png'],
                solution_text_img: ['комплекс мониторинга','определение имеющихся проблем на территории ','ситуационная модель состояния ','экспертное заключение '],

                end_text: 'Вы всегда будете в курсе изменений на участке и сможете эффективно принимать решения для оптимизации работ и получения максимального урожая.',
                end_svg: ['/result-5.png','/result-6.png','/result-3.png','/result-2.png'],
                end_text_svg: ['мониторинг территории','методики борьбы и ликвидации','диагностика состояния','использование современных технологий'],
                chose: true},
            {id:23,
                name: 'Оценка полей после сбора урожая + подбор культур на будущий сезон',
                mainimg: '/images/servicesPage/3_8.png',
                link: '/services/23',
                discription: 'После окончания сбора урожая начинается следующий этап работы. Для подготовки к будущему сезону необходимо оценить состояние полей и определить культуры, которые будут высаживаться. От этого зависит количество внесения минеральных веществ и подбор оптимальной культуры для конкретного участка.',
                svg: ['/red-10.png','/red-3.png','/red-5.png'],
                text_svg: ['неэффективные методики работы','почвенное истощение','уменьшение выручки'],

                texnology_text: 'С помощью аэрокосмического мониторинга и экспертной оценки территории собираются данные для анализа с использованием математических моделей и алгоритмов искусственного интеллекта. Сопоставляются все факторы и формируется оценка участка и рекомендации.',
                texnology_img: ['/science-1.png','/science-10.png','/science-3.png','/science-7.png'],
                texnology_text_img: ['информационный мониторинг','исследование  научного сотрудника','математическое моделирование ','методика оценки состояния'],

                solution_text: 'На основе исследования составляется индивидуальную методику по оценке состояния полей. Формируются рекомендации для выбора культур на будущий сезон, план и график необходимых работ. Культуры подбираются с учетом особенностей вашего участка для получения максимального урожая и роста экономической эффективности.',
                solution_img: ['/decision-2.png','/decision-7.png','/decision-3.png','/decision-5.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем ','комплексный подбор ресурсов для решения проблем','экспертное заключение ','экономическая оценка территории'],

                end_text: 'Результат анализа оценки полей поможет оптимально подготовить землю для посадки новых культур, использование методик восстановления и ухода за почвой сэкономят ресурсы, оптимизация процессов и грамотный выбор культур для следующего сезона обеспечат обильный урожай и развитие участка.',
                end_svg: ['/result-4.png','/result-7.png','/result-8.svg','/result-9.png'],
                end_text_svg: ['стабильный рост культур','индивидуальный подход','разработка комплекса решений','оценка состояния '],
                chose: true},
            {id:24,
                name: 'Оценка эффективности ведения с/x деятельности + рекомендации по конкретным проблемам',
                mainimg: '/images/servicesPage/4_1.png',
                link: '/services/24',
                discription: 'При работе на участке и ведении сельскохозяйственной деятельности возникает огромное количество задач. Иногда необходимо получить рекомендации и решения конкретной проблемы, чаще существует целый ряд проблем, которые требуют комплексного решения.',
                svg: ['/red-5.png','/red-10.png','/red-6.png'],
                text_svg: ['уменьшение выручки','неэффективные методики работы','засуха'],

                texnology_text: 'Работа с данными аэрокосмического мониторинга и показатели экономической эффективности позволяют собрать огромное количество данных о состоянии участка. Для анализа и обработки информации используются алгоритмы искусственного интеллекта и математические модели, на основе результатов этих исследований формируются методы работы и рекомендации.',
                texnology_img: ['/science-6.png','/science-7.png','/science-1.png','/science-3.png'],
                texnology_text_img: ['разработка нейронных сетей','методика оценки состояния','информационный мониторинг','математическое моделирование '],

                solution_text: 'Мы проведем полный анализ ведения деятельности на вашей территории и выявим общую ситуацию с учетом допущенных ошибок. Определим текущие и возможные в будущем проблемы, а также составим рекомендации по их устранению. Разработаем рекомендации для подготовки к следующем посевным работам исходя из особенностей вашего участка.',
                solution_img: ['/decision-2.png','/decision-3.png','/decision-8.png','/decision-5.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем ','экспертное заключение','оценка рисков и возможностей ','экономическая оценка территории'],

                end_text: 'Вы получите полную и актуальную информацию о состоянии участка и сможете оперативно приступить к исправлению существующих проблем.',
                end_svg: ['/result-9.png','/result-5.png','/result-8.svg','/result-2.png'],
                end_text_svg: ['оценка состояния ','мониторинг территории','разработка комплекса решений','использование современных технологий'],
                chose: true},
            {id:24,
                name: 'Индивидуальный план решения проблем почвенного плодородия',
                mainimg: '/images/servicesPage/4_2.png',
                link: '/services/25',
                discription: 'Негативное изменение свойств почвы ведут к снижению качества и количества урожая. Изменение биологического состава, снижение количества кислорода, эрозия – все это причины снижения почвенного плодородия. На плодородие почвы влияет множество факторов от региональных особенностей до технической обработки.',
                svg: ['/red-3.png','/red-8.png','/red-10.png'],
                text_svg: ['почвенное истощение','недостаточное выпадение осадков ','неэффективные методики работы'],

                texnology_text: 'Мы анализируем космические снимки высокого разрешения, собираем экспертную оценку территории и на основе этих данных моделируем процессы изменения почвы и просчитываем последствия, риски и варианты решения проблем.',
                texnology_img: ['/science-2.png','/science-3.png','/science-7.png','/science-8.png'],
                texnology_text_img: ['аэрофотосъемка','математическое моделирование ','методика оценки состояния','разработка индивидуальных рекомендаций'],

                solution_text: 'При снижении плодородия почвы в первую очередь необходимо своевременно выявить основные причины и работать над устранением главных проблем. Результаты исследований формируются в виде рекомендаций по восстановлению плодородия почвенного покрова и профилактики с учетом данных по конкретному участку.',
                solution_img: ['/decision-2.png','/decision-10.png','/decision-8.png','/decision-3.png'],
                solution_text_img: ['индивидуальная методика ликвидации проблем','математическая модель прогноза','оценка рисков и возможностей ','экспертное заключение'],

                end_text: 'На основе полученных рекомендаций вы получите полную информацию по уровню плодородности почвы, факторах риска и сможете разработать план увеличения почвенного плодородия и профилактических работ по сохранению свойств почвы.',
                end_svg: ['/result-8.svg','/result-3.png','/result-9.png','/result-4.png'],
                end_text_svg: ['разработка комплекса решений','диагностика состояния','оценка состояния ','стабильный рост культур '],
                chose: true}
        ];
        this._choselist = [];
        makeAutoObservable(this)
    }
    setService(service) {
        this._service = service
    }
    //добавление всей корзины из локалки
    setBasket(basket) {
        this._choselist = basket
        sessionStorage.setItem('basket', JSON.stringify(this._choselist));
    }
    //добавление 1 элемента и сохранение в локал
    setChoselist(item) {
        let a = -1;
        this._choselist?.map((el) => {
            if (el.name === item.name) {
                a = 0;
            }
        });
        if (a === -1) {
            this._choselist.push(item)
            sessionStorage.setItem('basket', JSON.stringify(this._choselist));
        }
    }
    setChoseEl(item) {
        let a = this._choselist.indexOf(item);
        this._choselist[a].chose = !this._choselist[a].chose;
        sessionStorage.setItem('basket', JSON.stringify(this._choselist));
    }
    setChoseElNot(item) {
        let a = this._choselist.indexOf(item);
        this._choselist[a].chose = 0;
        sessionStorage.setItem('basket', JSON.stringify(this._choselist));
    }
    setDel() {
        this._choselist = [];
        sessionStorage.setItem('basket', JSON.stringify([]));
    }
    get choselist() {
        return this._choselist
    }
    get service() {
        return this._service
    }

}
import React from 'react';

const Who = () => {
    return (
        <div className="col-12 col-lg-7">
            <p className="about-text">AEROSPACE-AGRO предлагает комплексные инновационные решения для оптимизации и увеличения экономической эффективности  аграрного бизнеса. Мы изучаем территории и культуры с использованием информационно-аэрокосмических технологий, чтобы повысить урожайность и выручку предприятия. Работаем дистанционно и быстро.</p>
            <p className="about-text">AEROSPACE-AGRO разрабатывает методики для ликвидации проблем и особенностей конкретного участка. Используя информационно-аэрокосмические технологии, мы обрабатываем данные  земельных участков с целью оценки проблем  при помощи технологий анализа космических снимков высокого разрешения. Далее мы разрабатываем индивидуальные рекомендации, которые формируются с учетом поставленных задач и территориальных особенностей.</p>
            <p className="about-text">AEROSPACE-AGRO станет вашим надежным партнером. Используя информационно-аэрокосмические технологии, мы сможем найти решение проблемы и повысить эффективность ваших полей.</p>
        </div>
    );
};

export default Who;
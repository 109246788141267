import React from 'react';

const Advantage = () => {
    return (
        <div className="col-12 col-lg-7">
            <p className="about-text">Мы используем  комплексные и инновационные решения для оптимального использования территорий с учетом необходимости увеличения экономической эффективности вашего бизнеса.</p>
            <p className="about-text">Мы поможем вам предупреждать и контролировать заболевания растений, определять территорию распространения вредителей, рассчитывать количество необходимых удобрений, следить за соблюдением плана сельскохозяйственной реализации, это лишь часть услуг, которые мы оказываем.</p>
            <p className="about-text">Одно из наших преимуществ – технологии, которые позволяют проводить исследование дистанционно. В короткие сроки вы получите полный подробный анализ вашего участка, список рекомендаций по улучшению качества и количества урожая.</p>
            <p className="about-text">Мы всегда работаем с конкретной территорией, поэтому стоимость услуг определяется исходя из поставленной задачи и особенностей участка.</p>
        </div>
    );
};

export default Advantage;
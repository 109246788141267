import React, {useContext } from 'react';
import {useParams} from 'react-router-dom';
import ServicesDetailedCaruselOne from "./ServicesDetailedCarusel/ServicesDetailedCaruselOne"
import {Context} from "../index";

const ServicesDetailed= () => {
    const {servicesPage} = useContext(Context);
    const {id} = useParams();
    const el = servicesPage.service[id];
    return(
        <div>
            <div className='container'>
                <div className="row serv-detal">
                    <div className="col-12 breadcrumbs">
                        <a href="/">Главная</a> > <a href="/services">Услуги</a> > <a>{el.name}</a> </div>
                    </div>
                </div>
            <div className="container">
                <div className="col">

                    <div className="row">
                        <div className="col-lg-7 col-12 text-Serv-Detailed">
                            <div className="container-title">
                                <h1>
                                    {el.name}
                                </h1>
                            </div>
                            <div className="mobil-img col-lg-5 col-12 imgServDetailed">
                                <img className='' src={el.mainimg} alt="картинка"/>
                            </div>
                            <p>{el.discription}</p>
                            {el.discription_ul?.map((item,index)=>
                                <p key={index} className='ml-3 li-before'>{item}</p>
                            )}
                            <div className='d-flex svg-detail-one flex-column flex-md-row flex-lg-row flex-wrap'>
                                {el.svg?.map((item, index)=> (
                                    <div key={index} className='d-flex col-4 text-img-min align-items-center w-50 min-img-servDetailed'>

                                        <img src={'/images/imgServDetailed'+ item} alt=""/>
                                        <p className="">{el.text_svg[index]}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="imgServDetailed-big desk-img big-img col-lg-5">
                            <img src={el.mainimg} alt="картинка"/>
                        </div>

                    </div>
                </div>
                <div className="row first_block_serv ">
                    <div className="imgServDetailed-margin-one">
                        <div className="row">
                            <div className="col-lg-6 ServDetailed-slider">
                                <div className='texnology_img '>
                                    {el.texnology_img?.map((item, index)=> (
                                        <div key={index} className='texnology_img_block'>
                                            <img src={'/images/imgServDetailed'+ item} />
                                            <p>{el.texnology_text_img[index]}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 d-flex flex-column justify-content-center">
                                <div className="container-title small">
                                    <h1 className="marg-h1">Технология</h1>
                                </div>
                                <p>
                                    {el.texnology_text}
                                </p>
                            </div>
                            <div className="col-12 mobil-img">
                                <ServicesDetailedCaruselOne texnology_img={el.texnology_img} texnology_text_img={el.texnology_text_img}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row second_block_serv">
                    <div className="imgServDetailed-margin-tho">
                        <div className="row">
                            <div className="col-lg-6 col-12 d-flex flex-column justify-content-center">
                                <div className="small container-title">
                                    <h1 className="marg-h1">Решение</h1>
                                    <p>{el.solution_text}</p>
                                    {el.solution_text_ul?.map((item,index)=>
                                        <p key={index} className='ml-3 li-before'>{item}</p>
                                    )}
                                </div>
                                <div className="mobil-img">
                                    <ServicesDetailedCaruselOne texnology_img={el.solution_img} texnology_text_img={el.solution_text_img}/>
                                </div>
                            </div>
                            <div className="col-lg-6 ServDetailed-slider-thri ServDetailed-slider ">
                                <div className='texnology_img'>
                                    {el.solution_img?.map((item, index)=> (
                                        <div key={index} className='texnology_img_block'>
                                            <img src={'/images/imgServDetailed'+ item} />
                                            <p>{el.solution_text_img[index]}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second_point mb-100">
                    <div className="row third_block_serv">
                        <div className="row imgServDetailed-margin-thri">
                            <div className="col-lg-6 col-12 min-img">
                                <div className="row">
                                    <div className='d-flex flex-column flex-lg-row w-100 flex-wrap'>
                                        {el.end_svg?.map((item, index)=> (
                                            <div key={index} className='d-flex w-50 imgServDetailed-result-block'>
                                                <div className="min-img-block">
                                                <img className="mobil-img vector-det" src={'/images/imgServDetailed/Vector.png'} alt=""/>
                                                <img  src={'/images/imgServDetailed'+ item} alt=""/>
                                                    {/*<p>{el.end_text_svg[index]}</p>*/}
                                                </div>
                                                <p>{el.end_text_svg[index]}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pl-5 text-p col-12">
                                <div className="container-title">
                                    <h1 className="marg-h1">
                                        В результате вы получаете
                                    </h1>
                                    <p>{el.end_text}</p>
                                    {el.end_text_ul?.map((item,index)=>
                                        <p key={index} className='ml-3 li-before'>{item}</p>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='btn-ontop d-none d-lg-flex' onClick={()=> {window.scroll(0,0)}}>
                        вернуться наверх
                        <div className="btn-img-ontop">
                            <img src="/images/case/svg/00.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ServicesDetailed;
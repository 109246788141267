import React, {useEffect, useState} from 'react';
import Map from "../components/Case/Map";
import CasesCaseList from "../components/Case/CasesCaseList";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {useHistory} from "react-router-dom";

const getWidth  = () => {
    return window.innerWidth;
};
const CasePage = () => {
    const history = useHistory();
    let [width, setWidth] = useState(getWidth);
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return (
        <div className="serv-bg">
            {width>1200? history.push('/cases/0'):null}
            <NavBar width={width}/>
            <div className="container mb-5">
                <div className="d-flex justify-content-center">
                    <div className='setv-bg_case-img'>
                        <img className='w-100' src="/images/case/Grass.png" alt="Responsive image"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="container-title max-cont">
                            <h1>кейсы<br/>aerospace-agro</h1>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="serv-bg_title-text">
                            Наша задача - максимально подробно представить
                            полное и подробное описание территории. На основе данных,
                            а также дополнительных рекомендаций от нас, Вы получаете мощный инструмент для
                            повышения территории.</p>
                    </div>
                </div>
                <Map />
                <CasesCaseList />
            </div>
            <Footer />
        </div>

    );
};

export default CasePage;
import {makeAutoObservable} from "mobx";


export  default class CasePageStore {
    constructor() {
        this._case = [
            {id: 0,
                name:'Красноярский край',
                problem: 'Заказчик: владелец двух сельскохозяйственных участков в Красноярском крае. Необходимо провести полное комплексное исследование данной территории, а также добиться повышения урожайности с учетом посаженного сорта. На территории произрастает пшеница, сорт - “Новосибирская 31”.',
                problem_img: '/images/case/problem-1.png',

                description: [
                    'Данный участок расположен в лесостепной зоне, рельеф холмистый, имеются плавные перепады высот до 30 метров. Почвы преимущественно серые лесные с добавлением чернозема. Рельеф понижается с востока на запад, а вместе с западными ветрами это способствует развитию ветровой и водной эрозии.',
                    'Территория характеризуется резко континентальным климатом, среднегодовая температура -1,7С; среднеегодовое количество осадков - 430мм. Периодически наблюдаются засухи, частые перепады температуры, а в начале июня случились заморозки до -2С.',
                    'В результате влияния облачности и прозрачности атмосферы, прямая солнечная радиация на данной территории составляет 45-65% от ее возможных сумм.',
                    'Исходя из полученных сведений, было выяснено, что климатические условия на данном участке не являются благоприятными для пшеницы, произрастающей на с/х участках заказчика.'
                ],
                description_img:'/images/case/description-1.png',

                assessment_img: '/images/case/assessment-1.png',
                assessment_start: 'Было определено состояние исследуемой территории благодаря космическим снимкам, на основе которых удалось выяснить следующие факторы:',
                assessment_li: [
                    'Из-за перепадов высот участка и выдувания розы ветров, происходит активное развитие эрозии почвы;',
                    'Наличие наклона на территории способствует появлению водной эрозии, то есть влага распространена неравномерно;',
                    'В более низкой части происходит скопление влаги, из-за чего растения болеют и гниют;',
                    'Грибковые инфекционные заболевания начали распространятся вверх по участку - по повышению уровня роста культуры;',
                    'Ввиду климатических составляющих, почва является низкоплодородной;',
                    'Наличие сорняков.'
                ],
                assessment_end:['По итогу, кроме основной задачи, поступившей от заказчика, перед нами встала новая проблема - остановить распространение заболевания растений и эрозии.'],

                methods_start:'Что мы сделали для решения проблемы:',
                methods_li:[
                    'Обработали космические снимки, чтобы определить уровень водной эрозии и её распределения по территории, а также провели анализ запаса водных ресурсов на всем участке.',
                    'Эти данные необходимы для того, чтобы определить точное количество воды для полноценного и здорового развития культуры',
                    'Произвели анализ данных по прошлым сезонам:',
                    [' - как происходил рост культуры',
                     ' - какие климатические особенности наблюдались за прошлые годы',
                     ' - каким было состояние почвы',
                     ' - соотношение всех факторов с количеством полученного урожая'],
                    'Определили минеральный состав почвы и составили график внесения удобрений;',
                    'Составили рекомендации по развитию участка, а также по лечению заболевания растений.',],
                methods_end:[],
                methods_img: ['/images/case/methods-1.png','/images/case/methods-2.png','/images/case/methods-3.png','/images/case/methods-4.png'],
                methods_text: ['разработка нейронных сетей','комплекс мониторинг','внесение удобрений','индивидуальные рекомендации по полю '],

                decision_img: '/images/case/decision-1.png',
                decision_start: ['Решением данной проблемы стали, в первую очередь, рекомендации на основе изучения территории и культуры. Необходимо было учитывать, что участок имеет перепады высот, поэтому для каждого уровня необходимо применять определённый комплекс удобрений. Снизу достаточно воды, но растения гниют и болеют; сверху же болезней нет, но почва засушлива и требует больше воды.'],
                decision_li: [],
                decision_end: 'Нами была разработана индивидуальная методика по работе с данным сельскохозяйственным участком с делением его на сектора для применения минеральных и химических веществ.',

                result: [
                    ['/images/case/result-1.png','Увеличение количества урожая','В результате проведённой работы клиент смог увеличить количество урожая на 10%.'],
                    ['/images/case/result-2.png','выравнивание водного баланса территории ','Было 28 центнеров с гектара, стало 32 центнера с гектара - чистая выручка увеличилась на 5 млн рублей.'],
                    ['/images/case/result-3.png','Предотвращение заболеваний','Было предотвращено заболевание растений, а также выровнен водный баланс почвы по всему уклону участка.'],
                ]
            },
            {id:1,
                name:'Волгоградская область',
                problem: 'В связи с биологическими особенностями необходимо было повысить урожайность проса и найти причины и факторы, которые могут повлиять на снижение урожайности.',
                problem_img: '/images/case/problem-2.png',

                description: [
                    'Еланский район Волгоградской области. Выращиваемая культура : просо. Резко-континентальный климат: летом температура может достигать +400 по Цельсию. Присутствуют возвышенные участки с большими перепадами высот, однако рельеф слабо волнистый. Почвы - обыкновенные чернозёмы, состояние плодородия на территории нормальное. Около 150 гектар земли. При этом из-за засухи территория склонна к развитию вредителей и заболеваний. '
                    ],
                description_img:'/images/case/description-2.png',

                assessment_img: '/images/case/assessment-2.png',
                assessment_start: 'Почва холмистая и сухая, коэффициент влажности низкий, климат резко-континентальный. Для проса это вполне нормальные условия, однако в некоторых местах излишняя сухость начала приводить к высыханию растений. ',
                assessment_li: [],
                assessment_end: [],

                methods_start:'',
                methods_li:[
                        'Цифровая модель рельефа для выяснения влияния осадков на посевы.',
                        'Математическая модель рисков и вероятностей для составления прогноза осадков.',
                        'Составление карты внесения удобрений и подкормки.'
                   ],
                methods_end:[],
                methods_img: ['/images/case/methods-5.png','/images/case/methods-6.png','/images/case/methods-7.png','/images/case/methods-8.png'],
                methods_text: ['математическая модель прогноза','составление индивид графика внесения удобрений','комплекс мониторинга','цифровая модель рельефа'],

                decision_img: '/images/case/decision-2.png',
                decision_img_li: [
                    'работа специалиста','заболевания ликвидированы','внесение удобрений', 'цифровые карты'
                ],
                decision_start: [],
                decision_li: [
                    'Была разработана цифровая модель рельефа - было выяснено, как влияют на посадку имеющиеся склоны, а также какая на участке плотность грунта (это влияет на процент доходящей до корней через грунт влаги). Было выяснено, что рельеф действительно сильно влияет на посевы.',
                    'На основе математической модели рисков и вероятностей была разработана система долгосрочного прогноза осадков и внесения удобрений и подкормки. Также можно было спрогнозировать, когда и какое количество осадков будет выпадать.',
                    'Разработана сетка, на основе которой было изучено, сколько в вегетационный период стоит ожидать осадков. А также были спрогнозированы примерные расчёты урожайности в зависимости от настоящего состояния участка.',
                    'Была составлена карта внесения удобрения и подкормки для проса. Так как присутствовала нехватка влаги, то было решено ее недостаток нивелировать дополнительными минералами и полезными веществами.'
                ],
                decision_end: '',

                result: [
                    ['/images/case/result-4.png','уменьшение заболеваемости','Уменьшилась заболеваемость на участке - на 35%.'],
                    ['/images/case/result-5.png','сохранение урожайности','Увеличения урожайности не произошло - по итогам прошлого года владелец собрал 14 центнеров с гектара']]
            },
            {id:2,
                name:'Краснодарский край',
                problem: 'Заказчиком является владелец виноградников в Краснодарском крае. После внесения стандартного количества минеральных удобрений часть растений погибла. Такое случилось впервые, заказчик так и не смог самостоятельно выявить причины.',
                problem_img: '/images/case/problem-3.png',

                description: [
                        'Краснодарский край характеризуется умеренно-континентальным, субтропическим и средиземноморским климатами. Зима в этих краях теплая и короткая. Снега выпадает мало, стабильный покров не образуется. Частые оттепели приносят с собой дожди. Вегетационный период на равнине 220-240 дней. Приход суммарной фотосинтетически-активной радиации в среднем за вегетационный период составляет 117 ккал/см2.',
                        'Годовое количество осадков: от 400 до 1400 мм в районе Сочи и около 3200 мм в Красной Поляне. Неравномерное распределение осадков, резкие температурные колебания, губительное действие суховеев и засухи вызывают необходимость строгого соблюдения научно обоснованной системы земледелия при возделывании сельскохозяйственных культур, увеличения посадок лесополос и других гидроаккумулирующих и противоэрозионных мер.',
                        'Почва Краснодарского края обладает высокой плодородностью. Большую часть составляют слабо-выщелоченные, с небольшим содержанием гумуса, сверхмощные черноземы. Характерным признаком является то, что они имеют гумусовые горизонты большой мощности, достигающие 170 сантиметров.'
                ],
                description_img:'/images/case/description-3.png',

                assessment_img: '/images/case/assessment-3.png',
                assessment_start: 'Было определено состояние исследуемой территории благодаря космическим снимкам:',
                assessment_li: [
                        'В момент внесения удобрений почва содержала излишнее количество соли, а также нехарактерные для неё примеси.',
                        'Траектория движения дождевых грозовых масс пролегала над промышленными зонами, где расположен цементный завод. Вследствие этого произошло пропитывание дождевых масс углекислотой, ионами тяжелых металлов, другими газами и пылью, которые в итоге выпали на территорию заказчика.',
                        'Над морем стояла повышенная температура, поэтому испарений было больше, чем обычно. Следовательно, испарялось большее количество солей.',
                        'Пыль, соли, хлориды формировались над морем в облаках, после чего шли по розе ветров в сторону виноградников заказчика.',
                        'После того, как заказчик внёс минеральные удобрения, прошел дождь с повышенным уровнем кислотности и хлоридов.',
                        'Минеральные удобрения вступили в химическую реакцию с выпавшими веществами, следовательно, началась реакция, которая привела к гибели растений:'
                ],
                assessment_end: [' - Соли задерживали влагу в почве, поэтому часть растений начала гнить;',
                                 ' - В местах, где влажность почвы была ниже, выяснилось, что соль впитала в себя всю воду, после чего растения засохли.'],

                methods_start:'',
                methods_li:[
                    'Была составлена карта дождевых масс, чтобы спрогнозировать будущие осадки;',
                    'После получения всей необходимой информации и анализа данных была рассчитана оптимальная дозировка удобрений для нейтрализации химических элементов.',
                    'Дозировка была рассчитана с учетом особенности почвы и территории виноградника;',
                    'Часть растений удалось спасти от гниения, а часть от высыхания, так как были нейтрализованы соли и внесены необходимое количество полива;',
                    'Была разработана методика расчета долгосрочных ритмов осадков;',
                    'Была составлена карта для понимания:',
                    ['- Где выпадут осадки;',
                     '- Откуда будут дуть ветра;',
                     '- Где будут формироваться воздушные массы;',
                     '- Какая будет температура над морем и над виноградниками.']

    ],
                methods_end:[],
                methods_img: ['/images/case/methods-9.png','/images/case/methods-10.png','/images/case/methods-11.png','/images/case/methods-12.png'],
                methods_text: ['комплекс карт на территорию','математическое моделирование','разработка индивидуальных исследования ','разработка индивидуальных методик исследования '],

                decision_img: '/images/case/decision-3.png',
                decision_start: ['Решением данной проблемы стал индивидуальный расчет количества и дозировок удобрений, так как заболевания распространялись неравномерно, и каждому участку нужен был собственный расчет. Также были подобраны идеальные формулы удобрений для нейтрализации последствий осадков, содержащих соли и промышленные вещества.'],
                decision_li: [],
                decision_end: '',

                result: [
                    ['/images/case/result-6.png','сохранение урожая','Гибель растений была предотвращена, 70% виноградников не пострадали, урожай удалось спасти.'],
                    ['/images/case/result-7.png','индивидуальные разработки','Благодаря правильной разработке системы удобрений урожай был сохранён.'],
                    ['/images/case/result-8.png','установление неочевидной экологической причины ','За 3 недели нам удалось решить проблему высыхания и гниения виноградников.']]
            },
            {id:3,
                name:'Приморский край',
                problem: 'Заказчиком является владелец сельскохозяйственного участка в Уссурийском районе. Выращиваемая культура – томаты – начала болеть, стала медленнее расти, начались проблемы с созреванием плодов.',
                problem_img: '/images/case/problem-4.png',

                description: [
                    'В Приморском крае умеренный муссонный климат. Лето прохладное и дождливое: за три летних месяца в этих краях выпадает до 80 % годовых осадков из-за дождей и циклонов. Радиационный баланс характеризуется меньшим числом солнечных дней, чем на соответствующих широтах европейской части России, хотя продолжительность ясной погоды в крае значительна и достаточна для вызревания многих сельскохозяйственных культур. Сумма активных температур 2500-2600°C. Вегетационный период длится от 120-130 суток на севере и до 160-200 суток на юге края. Почвы в основном бурозёмно-луговые.'
                    ],
                description_img:'/images/case/description-4.png',

                assessment_img: '/images/case/assessment-4.png',
                assessment_start: 'Было определено состояние исследуемой территории благодаря космическим снимкам:',
                assessment_li: [
                    'Томаты были перенасыщены азотом (листья были матовыми и с сероватым оттенком, или же вовсе слишком мелкими и светлыми), а также присутствовал недостаток меди (листья покрылись черными пятнами). Из-за этого началось инфекционное гниение растений.',
                    'На нижней стороне листьев появился фиолетовый оттенок, также они начали прижиматься к стволу и подниматься вверх, что говорит о недостатке фосфора.',
                    'По краям листьев появилась не засыхающая кайма, а сами листья начали сворачиваться в трубочку, что говорит о недостатке калия.',
                    'Около 35% исследуемого участка уже подверглось заболеванию, при этом сама болезнь развивалась неравномерно, что осложняло ситуацию. Это говорит о том, что при весеннем внесении подкормки некоторые места уже были перенасыщено азотом.'
                ],
                assessment_end:[],

                methods_start:'',
                methods_li:[
                    'На основе полученных данных была составлена карта неоднородности и выявлены заболевшие участки.',
                    'Так как азот быстро распадается, то для анализа были использованы наземные станции.',
                    'После анализа всех полученных данных мы составили математическую модель, чтобы выяснить, как долго еще в этих местах будет перенасыщение, а также чтобы спрогнозировать распространение инфекционного заболевания.',
                    'Математическая модель показала возможные траектории распространения заболевания, которые зависят от передачи болезни по воздуху и почве.',
                    'Проведение наземного анализа почвы (на температуру, влажность и содержания азота).',
                    'Данные дистанционного зондирования.',
                    'Анализ космических снимков.'
                    ],
                methods_end:[],
                methods_img: ['/images/case/methods-13.png','/images/case/methods-14.png','/images/case/methods-15.png','/images/case/methods-39.png'],
                methods_text: ['математическая модель прогноза','комплексный мониторинг','методика оценки состояния','создание карт неоднородностей '],

                decision_img: '/images/case/decision-4.png',
                decision_start: [],
                decision_li: [
                    'Разработка методики поглощения азота на территории;',
                    'Рыхление почвы, безопасное для корней;',
                    'Внесение необходимых удобрений для спасения урожая, так как развитие растений замедляется из-за болезни;',
                    'Профилактика заболевания на здоровых участках.'
                ],
                decision_end: '',
                result: [
                    ['/images/case/result-9.png','ликвидация заболевания','В результате удалось локализоватьи остановить распространение заболевания растений. К сожалению, часть урожая уже была потеряна из-за несвоевременного лечения.'],
                    ['/images/case/result-10.png','увеличение урожая','Удалось вылечить растения, остановить распространение заболевания (спасено было около 65% урожая), а также томаты начали обильно плодоносить.']]
            },
            {id:4,
                name:'Тульская область',
                problem: 'Проблемой является переувлажнение некоторых участков поля в связи с водной деградацией почв, что способствует развитию мокрой белой гнили – склеротинии. В прошлом году у заказчика седели подсолнечник, а уже в этом году он посадил сою. Ему хотелось бы узнать риски распространения мокрой гнили (белой гнили) на его территории и насколько его территория вообще подвержена данному заболеванию, потому что в некоторых местах он уже начал замечать начало этой болезни.',
                problem_img: '/images/case/problem-5.png',

                description: [
                    'Тульская область, Теплоогаревский район, август. Слабощелочные чернозёмы, температура в августе от +9 до +29 градусов Цельсия. Выпадение осадков - 70-80 миллиметров, то есть выпадает большое количество осадков, а сое необходим длинный солнечный день. В августе часто стояли туманы, была повышена влажность, а необходимого количества солнечного света соя не получила. Около 17% территории заражено сорняками. Распространению болезни, а именно заражению белой мокрой гнилью, подвержено 45% из-за частого тумана и достаточного для ее развития количества осадков. Склоны занимают около 56% площади, а с крутизной 30 – 20%.'
                    ],
                description_img:'/images/case/description-5.png',

                assessment_img: '/images/case/assessment-5.png',
                assessment_start: 'Происходит активное развитие эрозии. Непосредственное влияние на развитие эрозионных процессов оказывает выпадение осадков, их вид, продолжительность, интенсивность, а также время выпадения. Также влияют температура, влажность воздуха, скорость и продолжительность ветра. Эрозия почвы во время дождя происходит при совместном воздействии потока воды и падающих капель. Капли дождя разрушают структуру почвы, создают в потоке добавочную турбулентность, повышающую ее размывающую и транспортирующую способность, а также нагружают поток при всплесках оторванными частицами почвы. На полях посажена соя сорта Зуша, которая ускоряет размывание участка примерно в 1,2 раза. Так, слабо смыто 47% участка, а средне – 20% участка.',
                assessment_li: [],
                assessment_end:[],

                methods_start:'',
                methods_li:[
                    'Разработана цифровая 3D-карта полей, которая позволила выяснить очаги заболевания;' ,
                    'Была разработана цифровая модель почвы для того, чтобы выяснить, насколько она деградировала.' ,
                    'Спрогнозированы возможные последствия, если ситуация с переувлажнением не будет решена.' ,
                    'Также была разработана методика систематического распределения удобрений с учетом распространения воздушных масс на участке (местный микроклимат);' ,
                    'Разработана модель прогноза осадков с учётом возможных переувлажнений для эффективного действия удобрений и лечения.  ' ,
                    'Была разработана карта при помощи индекса NDVI, на которой видно, в каких местах начало активно развиваться заболевание. Также благодаря этой карте стало возможным определить зоны распространения заболевания, которые зависят от переноса воздушных масс. ' ,
                    'Был проанализирован микроклимат поля, благодаря чему стало ясно, какие участки на территории наиболее подвержены заболеванию. '
                   ],
                methods_end:[],
                methods_img: ['/images/case/methods-24.png','/images/case/methods-25.png','/images/case/methods-26.png','/images/case/methods-27.png'],
                methods_text: ['оценка рисков и возможнстей ','цифровая 3D-карта','математическая модель прогноза','цифровая модель почвы '],

                decision_img: '/images/case/decision-5.png',
                decision_start: [],
                decision_li: [
                    'Была разработана цифровая карта полей. На её основе были выявлены очаги, наиболее подверженные заболеванию, где потеря урожая уже достигла 70%. ' ,
                    'Было выявлено направление, в котором с вероятностью 30-50% будет двигаться заболевание. ' ,
                    'На основе всех этих данных была разработана карта внесения удобрений с местом, в которое их нужно было вносить, и дозой. ' ,
                    'Также была составлена таблица выпадения осадков с учетом микроклимата территории: если будут идти сильные дожди, вызывающие эрозию и размывающие внесенные минеральные удобрения, то это не принесет никакого вреда.'
                ],
                decision_end: '',

                result: [
                    ['/images/case/result-17.png','ликвидация заболевания ','Удалось предотвратить развитие заболевания на здоровых частях поля, а также сохранить урожай, который был подвержен риску гибели в 30-50%. '],
                    ['/images/case/result-16.png','сохранение урожая ','Потери были только на тех участках, которые уже на момент начала работ были заражены на 70%. От распространения гнили удалось сохранить около 40% урожая. ']]
            }, //redy
            {id:5,
                // name:'Республика Крым',
                // problem: 'Заказчик - владелец виноградников на холмистой местности в Крыму. Проблема заключается в том, что виноградники начали заболевать и уже несколько лет не приносят урожай. Заказчик не смог самостоятельно выявить причину появления болезни. Решать вопрос было необходимо оперативно, так как с каждым днём ситуация усугублялась. Сорт и методы выращивания заказчик не менял, но растения начали болеть и перестали плодоносить;',
                // problem_li: 'Сорт и методы выращивания заказчик не менял, но растения начали болеть и перестали плодоносить;',
                // problem_img: '/images/case/problem-6.png',
                //
                // description: ['Участок расположен на территории Крымского полуострова, который лежит на южной границе умеренного пояса. Климат отличается некоторыми особенностями, связанными с его географическим положением: большая мягкость и влажность, значительная солнечность.Холмистые территории участка отличаются  повышенной влажностью. Минимум осадков приходится на август и апрель — май.Почвы представляют собой разновидности черноземов: черноземы карбонатные.'],
                // description_img:'/images/case/description-6.png',
                //
                // assessment_img: '/images/case/assessment-6.png',
                // assessment_start: 'Было определено состояние исследуемой территории благодаря космическим снимкам. Удалось выяснить следующие факторы:',
                // assessment_li: [
                //     'Вся выращенная культура болеет одним и тем же заболеванием;',
                //     'После оценки территории и виноградников мы выяснили, что высаженная культура плохо переносит влажность, поэтому постоянно болеет инфекционными и грибковыми болезнями.',
                //     'Болезнь уже коснулась 30-40% виноградников;',
                //     'На территории присутствует несовместимость агроклиматических условий и самой культуры',
                //     'В последние годы произошло повышение температуры на южных холмах из-за переноса воздушных масс. А на северных холмах образовывался туман, и температура понижалась;',
                //     'Перепады высот от моря и изменение ветра способствовали тому, чтобы на склоне задерживались влажные массы, идущие от моря. Из-за столкновения высоких температур и влажных масс в виноградниках началось гниение листьев и почвы.',
                //     ['Это произошло не за один год. Климат и роза ветров на территории менялись на протяжении последних 20 лет;'],
                //     'Из-за микрорельефа местности проблема влажности останется в этих местах надолго. Это означает, что в ближайшие 30-40 лет данная территория будет непригодна для виноградников.'
                // ],
                // assessment_end:[],
                //
                // methods_start:'',
                // methods_li:[
                //     'Была произведена оценка территории по всем параметрам, вследствие чего было выяснено, что данный сорт винограда не способен проживать и плодоносить в таком влажном климате;',
                //     'Изначально заказчик не планировал менять территорию своих виноградников, поэтому была разработана методика внесения минеральных веществ поглощения влажности, а также методика защиты растений от инфекционных и грибковых инфекций: ',
                //     [' - Рассчитано количество внесения удобрений;',
                //      ' - Выведены способы минерализации почвы;',
                //      ' - Разработан план лечения растений;'],
                //     ' Однако данные меры оказались нерентабельны, поэтому рассчиталась модель математического прогноза изменения климата на данной территории. Эта модель окончательно убедила владельца участка перенести культуры в более благоприятное место. '
                //    ],
                // methods_end:[],
                // methods_img: ['/images/case/methods-36.png','/images/case/methods-35.png','/images/case/methods-38.png','/images/case/methods-37.png'],
                // methods_text: ['математическая модель прогноза','комплекс мониторинга','разработка индивидуальной методики исследования ','экономическая оценка территории'],
                //
                // decision_img: '/images/case/decision-6.png',
                // decision_start: ['Решение у этой проблемы было только одно - изменение логистики посадки виноградников и пересадка растений на другие территории, которые обладают более низкой влажностью и пригодны для произрастания данного сорта.'],
                // decision_li: [],
                // decision_end: '',
                //
                // result: [
                //     ['/images/case/result-24.png','разработка индивидуальной логистики пересадки виноградников ','В прошлом году было остановлено распространение заболевания, но поддерживать искусственными методами надлежащую влажность территории – это очень затратно, так как бороться с природными изменениями в принципе невозможно. '],
                //     ['/images/case/result-25.png','остановлено распространение заболеваний ','В следующем году заказчик пересадит свои виноградники на более подходящую по климату и влажности территорию, после чего можно будет выяснить итоговую цифру спасенных растений и процент спасенного урожая.']]
            },
            {id:6,
                name:'Московская область',
                problem: 'Массово гибнут растения на сельскохозяйственном участке в Московском области. Заказчик пытался самостоятельно решить проблему, комбинируя органику и исключая некоторые витамины, однако никакого результата это не дало - гибель урожая предотвратить всё равно не удалось. ',
                problem_img: '/images/case/problem-7.png',

                description: [
                    'Данная сельскохозяйственная территория находится в умеренно континентальном климате, присутствуют локальные очаги с повышенной влажностью. Сумма осадков на участке в среднем варьируется от 500 до 600 миллиметров. Коэффициент увлажнения в среднем больше единицы. ',
                    'В Московской области наиболее распространены дерново-подзолистые почвы, которые занимают большую часть территории. Данный подтип почвы обладает невысоким плодородием и слабо выраженной структурой. ',
                    'Почвы данной территории были очень сильно загрязнены минеральными удобрениями и ядохимикатами, что оказывало непосредственное влияние на культуру. Также почвы имели неравномерную структуру и были слишком увлажнены. Температура нормальная для данного региона в начале вегетационного периода - от +15 до +200 по Цельсию. Осадки выпадали в достаточном количестве, но присутствовало минеральное истощение почвы. '
                    ],
                description_img:'/images/case/description-7.png',

                assessment_img: '/images/case/assessment-7.png',
                assessment_start: 'На основе космических снимков было определено состояние исследуемой территории:',
                assessment_li: ['Температура почвы выше нормы, но увлажненность сохраняется;',
                'Высокая влажность при применении удобрений способствовала тому, что  минеральные вещества почвы вступили в химическую реакцию, которая оказывала негативное влияние на растения;',
                'Началось развитие инфекционной болезни культуры. Информация была получена со снимков высокого разрешения в инфракрасном диапазоне.'],
                assessment_end:[],

                methods_start:'',
                methods_li:[
                    'Была составлена карта территории, а также выявлена область заболевания растений;',
                    'Была составлена математическую модель на основе космических снимков до внесения удобрений заказчиком и после внесения. Благодаря этому можно было наглядно увидеть изменения в состоянии растений, а также причины, по которым начались эти изменения.'
                   ],
                methods_end: [
                    'Данная модель позволила увидеть дальнейшую перспективу состояния участка при наличии болезни - в частности, стало ясно, через сколько времени участок станет непригодным для посева.',
                    'Также математическая модель показала варианты состояния земли:',
                    '- При изменении климатических условий (например, при резкой смене температуры болезнь развивалась бы медленнее);',
                    '- При внесении дополнительных химических и минеральных веществ. '
                ],
                methods_img: ['/images/case/methods-16.png','/images/case/methods-17.png','/images/case/methods-18.png','/images/case/methods-20.png'],
                methods_text: ['математическое моделирование','информационный мониторинг ','цифровая карта полей ','разработка индивидуальной методики исследований '],

                decision_img: '/images/case/decision-7.png',
                decision_start: ['Повлиять на температуру было невозможно, а благодаря графику долгосрочных ритмов осадков и изменения температуры стало ясно, что климатические условия практически не изменяются.',
                                'Было принято несколько решений:'],
                decision_li: [
                    'Использовать химические удобрения, нейтрализующие заболевание культуры; ',
                    'Применить вещества, поглощающие влагу из почвы;',
                    'Задействовать антибиотики для лечения болезни растений. '
                ],
                decision_end: '',

                result: [
                    ['','','К сожалению, заказчик обратился не по факту возникновения проблемы, поэтому на момент начала работ уже было потеряно около 30% урожая. Однако усердная работа и своевременная остановка заболевания культуры позволила сохранить оставшиеся 70% урожая. '],
                    ['/images/case/result-12.png','сохранение 70% урожая',''],
                    ['/images/case/result-11.png','ликвидация заболеваний ','']]
            }, //redy
            {id:7,
                name:'Ставропольский край',
                problem: 'Начался вегетационный период у подсолнечника, однако всходы посевов были неоднородными: где-то имелись явные “проплешины”. Сам заказчик заявлял, что правильно засевал поля и вносил все минеральные вещества, а потому причину такого неоднородного всхода назвать не мог. Необходимо было выяснить причины такого положения и найти способы повлиять на него.',
                problem_img: '/images/case/problem-8.png',

                description: [
                    'Климат территории умеренно – континентальный с недостаточным увлажнением. По многолетним наблюдениям, среднегодовое количество осадков составляет 300-370 миллиметров. Почвы представлены в основном предкавказскими карбонатными чернозёмами, мощность гумусового горизонта достигает 100 сантиметров и более. Средний бонитет почв пахотных земель оценивается в 43-57 баллов. Имеются небольшие уклоны территории, развиты эрозионные процессы. ',
                    'Преобладающие направления ветра - западное и восточное. Средняя скорость ветра составляет 2-5 м/с, максимальная же скорость достигает 30-40 м/с. Распределение осадков по территории края неравномерно, что связано с уплотнениями в почве и уклонами территории. Количество осадков за год уменьшается с юга на север и с запада на восток и составляет в юго-восточных районах края 350-500 миллиметров, на Ставропольской возвышенности - 600 миллиметров, в предгорьях - 600-800 миллиметров. Максимум осадков наблюдается летом. Продолжительность вегетационного периода - 180-185 дней.'
                    ],
                description_img:'/images/case/description-8.png',

                assessment_img: '/images/case/assessment-8.png',
                assessment_start: 'Сильно развиты эрозионные процессы с уменьшением содержания гумуса, присутствуют уплотнения грунта, в некоторых местах образовываются застои влаги после дождей, что также может способствовать развитию вредителей. Большое количество участков либо с недостаточной увлажненностью, либо с застоем влаги. Присутствовала неравномерность всхода и развития культуры. ',
                assessment_li: [],
                assessment_end:['Заказчик рассказал, что производил посев семян в почву с очень неравномерным увлажнением. Именно из-за этого всходы были неоднородными, снизилась их густота.'],

                methods_start:'',
                methods_li:[
                    'Произведён анализ космических снимков высокого разрешения для расчета индекса NDVI, чтобы изучить стадию вегетационного периода. Также это помогло найти некоторые возможные причины такого отклонения у всходов. ',
                    'Учитывались факторы агроклиматических ресурсов: температура, процент поглощения почвой влаги с процентом уплотнения, перепады высот, скорость развития заболеваний на данном участке до этого года.',
                    'Проанализирован ряд последовательных во времени космических снимков, на основе которых можно было выявить динамику развития культуры, начиная с посадки и состояния почвы на тот момент, и заканчивая настоящим моментом. '
                   ],
                methods_end:[],
                methods_img: ['/images/case/methods-28.png','/images/case/methods-29.png','/images/case/methods-30.png','/images/case/methods-31.png'],
                methods_text: ['информационный мониторинг','оценки рисков и возможностей','математическое моделирование','методика оценки состояния'],

                decision_img: '/images/case/decision-8.png',
                decision_start: [],
                decision_li: [
                    'Была разработана математическая модель, которая на основе анализов начального этапа показала, что почвы с самого начала были переувлажнены, из-за чего половина семян просто не взошла и начала гнить и болеть. ',
                    'Были разработаны индивидуальные рекомендации по посеву и уходу за культурой на следующий сезон: разработан план проверки почвы (указаны необходимая для посадки температура и соотношение влаги к температуре) и посева культуры. '
                ],
                decision_end: '',

                result: [
                    ['','','Заказчик получил рекомендации, которые помогут ему избежать подобной ситуации в следующем сезоне.'],
                    ['/images/case/result-18.png','сокращение издержек',''],
                    ['/images/case/result-19.png','подбор ресурсов для решения проблем',''],
                    ['/images/case/result-20.png','комплексная диагностика территории','']]
            },//redy
            {id:8,
                name:'Свердловская область',
                problem: 'Появились риски уменьшения урожайности ячменя озимого 25-30% и более. Снижение урожая зерна и его качества происходит вследствие конкуренции между сорняками и культурными растениями за воду, свет и питательные вещества.',
                problem_img: '/images/case/problem-9.png',

                description: [
                    'В Белоярском районе влажная и достаточно теплая погода в октябре-ноябре, которая во время проведения исследований способствовала массовому появлению сорняков на поле, морозы же начались только в январе. К концу осенней вегетации, в посевах насчитывалось от 23,6 до 29,2 сорняков на 1 метр. Некоторые характеристики территории: черноземы оподзоленные , средний pH поля = 5.5, рельеф неоднородный, развита водная эрозия, температуры средние. Для ячменя опасны морозы ниже -12…-15 °С. При этом постоянные весенние перепады температур губительны для почвы.',
                    'Ориентировочные потери урожая при наличии лишь одного сорняка на 1 м2 в посевах составляют: ',
                    [
                        'Вьюнок полевой — 0,25 ц/га;',
                        'Лебеда белая — 0,27 ц/га;',
                        'Метлица обыкновенная — 0,19 ц/га;',
                        'Осот розовый — 0,68 ц/га;',
                        'Пырей ползучий — 0,55 ц/га;',
                        'Подмаренник цепкий — 0,20 ц/га. '
                    ],
                    'Осенний период характеризуется постепенным понижением температуры, частыми заморозками в воздухе и на поверхности почвы. Многолетняя глубина промерзания почвы варьируется до 50-ти сантиметров , преобладают северные и северо-западные ветры. Скорость ветра обычно составляет 2.5-3 м/с.'
                    ],
                description_img:'/images/case/description-9.png',

                assessment_img: '/images/case/assessment-9.png',
                assessment_start: '',
                assessment_li: [
                    'Насчитывалось в среднем 25 сорняков на 1 м2. Показала это цифровая карта полей по строению и индексу NDVI.',
                    'На основе космических снимков высокого разрешения была составлена тепловая карта полей.',
                    'Также была составлена карта влажности для того, чтобы понять, где может появляться большое количество сорняков после потепления. '
                ],
                assessment_end:[],

                methods_start:'',
                methods_li:[
                    'Была смоделирована 3D модель поля, которая показывала расположение основных видов сорняков и их распространение. ',
                    'Также был произведён расчёт, который показал, к какому минусу в урожайности (в расчёте на один гектар) может привести данная ситуация. '
                   ],
                methods_end:[],
                methods_img: ['/images/case/methods-18.png','/images/case/methods-32.png','/images/case/methods-34.png','/images/case/methods-33.png'],
                methods_text: ['Цифровая карта полей','математическая модель прогноз','анализ влажности почв и прогноз засух','оценка рисков и возможнстей '],

                decision_img: '/images/case/decision-9.png',
                decision_start:[],
                decision_li: [
                    'Была разработана таблица подкормки, направленная на улучшении ситуации. ',
                    'Были внесены специальные удобрения, которые поглотили всю лишнюю влагу с расчётам на ту влажность, которая на тот момент присутствовала на территории в связи с режимом температур, выпадением осадков, рельефа и небольшого перепада высот.  ',
                    'В местах, где присутствовала водная эрозия, необходимо было внести больше препарата поглотителя влаги для того, чтобы эта влага не провоцировала развитие бактерий и заболевания. '
                ],
                decision_end: '',

                result: [
                    ['/images/case/result-23.png','индивидуальные экспертные оценки ','К началу весенней вегетации озимого ячменя отмечалось уменьшение количества сорняков по сравнению с осенним периодом - их стало на 15-17% меньше.'],
                    ['/images/case/result-22.png','сокращение сорняков ','По видовому составу сорняки в это время были представлены зимующими и ранними яровыми. В фазу колошения произошло увеличение количества многолетних сорняков.'],
                    ['/images/case/result-21.png','увеличение выручки ',' ']]
            }, //redy
            {id:9,
                name:'Владимирская область',
                problem: 'Суздальский район Владимирской области. Весной часть посадок озимой тритикалы не взошла, а часть – прекратила развиваться. Взошедшая часть оказалась под угрозой из-за начавшихся морозов и выпавшего снега. Необходимо решить: сохранять посевы или вовсе пересеять тритикале.',
                problem_img: '/images/case/problem-10.png',

                description: [
                    'Климат данной территории - умеренно-континентальный: лето теплое, а зима умеренно холодная. Ярко выражен переход от осеннего периода к зимнему и от весеннего к летнему. Продолжительность периода со среднесуточной температурой ниже нуля - 137 дней, а среднегодовая температура - +50 по Цельсию. Среднегодовое количество осадков - 550-600 миллиметров, если же год засушливый, то около 400 миллиметров. Максимум осадков выпадает в летний период. В зимний период формируется устойчивый снежный покров, но в данном случае он не сформировался, и толщина снежного покрова в среднем составляла 35-40 миллиметров. Продолжительность вегетационного периода варьируется от 160 до 185 дней. ',
                    'Рельеф был неоднородным, возвышенности в некоторых местах имели уклон 1,20, а температура была нестабильная во время начала сезона, то есть весной. Осадки формировались тяжело, присутствовали резкие перепады температуры, что не характерно для данного участка. Осадков практически не было, однако могли резко ударить морозы.'
                    ],
                description_img:'/images/case/description-10.png',

                assessment_img: '/images/case/assessment-10.png',
                assessment_start: 'Было выяснено состояние исследуемой территории благодаря космическим снимкам высокого разрешения, на основе которых удалось выяснить следующие факторы:',
                assessment_li: [
                    'Культура уже начала развиваться и давать всходы, но снежные осадки поставили под угрозу дальнейшее ее развитие;',
                    'Был проведён глубокий ежедневный анализ на основе динамических карт, а также составлен долгосрочный прогноз ритмов осадков, чтобы предупредить неожиданные заморозки;',
                    'Была разработана математическая модель вероятности гибели растений, а также количества выжившего урожая.'
                ],
                assessment_end:['Задача оказалась довольно сложной, а потому шансы на успех были 50/50.'],

                methods_start:'Меры, принимаемые для разрешения проблемы:',
                methods_li:[
                    'Была разработана 3D-карта рельефа.',
                    'Разработана цифровая карта внесения удобрения.',
                    'Были разработаны математические модели рисков и микроклимата территории.',
                    'Был проведён анализ атмосферного фронта, воздушных температур, а также был составлен математический прогноз выпадения осадков.',
                    'Была разработана индивидуальная методика внесения удобрений на основе потребностей почвы в микроэлементах, чтобы сохранить посевы.',
                    'Владелец участка получил рекомендации о том, в каком количестве необходимо вносить гуминовые кислоты, чтобы повысить сопротивляемость растений к изменениям температур. '
                   ],
                methods_end:[],
                methods_img: ['/images/case/methods-21.png','/images/case/methods-22.png','/images/case/methods-23.png','/images/case/methods-18.png'],
                methods_text: ['оценка рисков и возможностей ','модель состояния ','математическая модель прогноза ','цифровая модель рельефа'],

                decision_img: '/images/case/decision-10.png',
                decision_start: ['Решением данной проблемы стало внесение необходимых удобрений и кислот для восстановления вегетационного периода растений, а также для повышения “стрессоустойчивости” культуры к будущим осадкам и перепадам температур. На основе модели долгосрочных ритмов осадков и математической модели микроклиматических рисков было выяснено, что возможны заморозки, поэтому было принято решение не тратить средства и время на территорию, на которой риск гибели оказания около 85%.'],
                decision_li: [],
                decision_end: '',

                result: [
                    ['','','По результатам всего 12% от общего количества озимых культур не взошло, их спасти не удалось. Такого результата удалось добиться благодаря внедрению цифровой карты и правильному распределению удобрений, а также анализу 3D-карт рельефа, которые показали, какие конкретно участки территории нуждаются в повышенном внимании и проработке.'],
                    ['/images/case/result-13.png','ликвидация проблемы ',''],
                    ['/images/case/result-15.png','внедрение индивидуальных рекомендаций',''],
                    ['/images/case/result-14.png','сохранение урожая','']
                ]
            }, //redy
        ];
        makeAutoObservable(this)
    }
    setCase(item) {
        this._case = item
    }
    get case() {
        return this._case
    }
}
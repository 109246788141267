import React, {useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ServicesDetailed from "../components/ServicesDetailed";

const getWidth  = () => {
    return window.innerWidth;
};
const ServDetailed = () => {
    let [width, setWidth] = useState(getWidth);
    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    return (
        <div className='roadmap_bg_serv'>
            <NavBar width={width}/>
            <ServicesDetailed />
            <Footer />
        </div>
    );
};

export default ServDetailed;
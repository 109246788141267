import React, {useContext,useState, useEffect} from 'react';
import {Context} from "../../../index";
import Carousel, { consts } from "react-elastic-carousel";
import BannerCarouselItem from "./BannerCarouselItem";

const BannerCarusel = ({width}) => {
    const {banner} = useContext(Context);
    const [midItemIndex, setMidItemIndex] = useState(Math.ceil(3 / 2) );

    const breakPoints = [
        {width: 0, itemsToShow: 3, className: "banner-carusel", showArrows: true, pagination: false, enableMouseSwipe: false},
        {width: 1200, itemsToShow: 2, className: "banner-carusel", showArrows: true, pagination: false, enableMouseSwipe: false}
    ];
    const breakPointsSmall = [
        {width: 0, itemsToShow: 1, className: "slider-1_block", showArrows: false, pagination: false, enableMouseSwipe: true, disableArrowsOnEnd: true},
    ]

    const onChange = (_, next) => {
        const mid = Math.ceil(3 / 2);
        setMidItemIndex(mid + next.index);
    };

    return (
        <div className='mb-160 mb-20'>
            {width >= 992 ?
                <div className='container'>
                    <div className="row">
                        <div className="col banner-carusel-col">
                            <Carousel breakPoints={breakPoints}
                                      onNextStart={onChange}
                                      onPrevStart={onChange}
                                      renderArrow={
                                          ({type, onClick, isEdge}) => {
                                              const pointer = type === consts.PREV ? '/images/aboutstore/back.svg' : "/images/aboutstore/next.svg"
                                              const btnName = type === consts.PREV ?'banner-arrow back' :'banner-arrow next'
                                              return (
                                                  <button className={btnName} onClick={onClick} disabled={isEdge}>
                                                      <img src={pointer} alt=""/>
                                                  </button>
                                              )
                                          }
                                      }>
                                {banner.banner.map((item,index) =>
                                    <BannerCarouselItem mid={midItemIndex} key={index} banner={item} width={width}/>
                                )}
                            </Carousel>
                        </div>
                    </div>
                </div>
                :
                <Carousel disableArrowsOnEnd breakPoints={breakPointsSmall}>
                    {banner.banner.map(item =>
                        <a key={item.id} className={'banner-item-small'}
                            style={{'--hover-image': `url(${item.mob_img})` }}>
                            <div className="container-title d-flex align-items-end">
                                <h3 className='d-flex flex-column align-items-start'>{
                                    item.title.map((word) =>
                                        <span key={word}>{word}<br/></span>
                                    )
                                }</h3>
                                <img className='' src='/images/icons/btn-lg-next.svg' />
                            </div>
                            <div className='banner-content'>
                                {item.content.split('\n').map((item,index) => (
                                    <p key={index}>{item}</p>
                                ))}
                            </div>
                            <div className='banner-link'>Узнать подробнее</div>
                        </a>
                    )}
                </Carousel>
            }
        </div>
    );
};
export default BannerCarusel;
import React from 'react';
import Carousel, {consts} from "react-elastic-carousel";

const CasesCaseList = () => {
    const breakPoints = [
        {width:0, itemsToShow: 1, className: "case-region-сarusel", showArrows: true, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: true},
        {width:560, itemsToShow: 2, className: "case-region-сarusel", showArrows: false, pagination: false, enableMouseSwipe: false, disableArrowsOnEnd: true},
    ];
    return (
        <div className='d-lg-none'>
            <Carousel breakPoints={breakPoints}
                      renderArrow={
                          ({ type, onClick, isEdge }) => {
                              const pointer = type === consts.PREV ? '/images/aboutstore/back.svg' : "/images/aboutstore/next.svg";
                              const btnName = type === consts.PREV ?'map-arrow back' :'map-arrow next'
                              return (
                                  <button className={btnName} onClick={onClick} disabled={isEdge}>
                                      <img src={pointer} alt=""/>
                                  </button>
                              )
                          }
                      }>
                <div className='d-flex flex-column align-items-start text-center'>
                    <a href='/cases/0' className={'case-region'}>Красноярский край</a>
                    {/*<a href='/cases/5' className={'case-region'}>Республика Крым</a>*/}
                    <a href='/cases/9' className={'case-region'}>Владимирская область</a>
                    <a href='/cases/4' className={'case-region'}>Тульская область</a>
                    <a href='/cases/3' className={'case-region'}>Приморский край</a>
                </div>
                <div className='d-flex flex-column align-items-start text-center'>
                    <a href='/cases/2' className={'case-region'}>Краснодарский край</a>
                    <a href='/cases/1' className={'case-region'}>Волгоградская область</a>
                    <a href='/cases/6' className={'case-region'}>Московская область</a>
                    <a href='/cases/7' className={'case-region'}>Ставропольский край</a>
                    <a href='/cases/8' className={'case-region'}>Свердловская область</a>
                </div>
            </Carousel>
        </div>




    );
};

export default CasesCaseList;
import {makeAutoObservable} from "mobx";

export  default class BannerStore {
    constructor() {
        this._banner  = [
            {id:1, title: ['Конкурс','AEROSPACE-AGRO'],
                link: '#',
                content:'Конкурс компании AEROSPACE-AGRO для специалистов в области аграрных технологий, студентов старших курсов магистратуры и аспирантуры. \n AEROSPACE-AGRO – лидер в применении инновационных решений для диагностики сельскохозяйственных территорий. Лучшие из лучших получат от AEROSPACE-AGRO премии в размере 30.000 рублей!',
                img: '/images/bannerstore/1.png',
                mob_img: '/images/bannerstore/mobbaner.png'},
            {id:2, title: ['Услуга',' Test-Drive'],
                link: '/services/19',
                content:'Протестируйте возможности AEROSPACE-AGRO на небольшом участке. Мы предлагаем каждому новому клиенту услугу Test-Drive! за 200 000 рублей. \n Test-Drive! – это подробная диагностика ограниченной территории вашего участка и пакет рекомендаций. Предложение действует бессрочно, все подробности уточняйте у наших консультантов.',
                img: '/images/bannerstore/1.png',mob_img: '/images/bannerstore/mobbaner.png'},
            {id:3, title: ['О наших', 'технологиях'],  link: '#',
                content:'Технологии AEROSPACE-AGRO увеличат выручку Вашей компании более чем на 15%! Мы предоставляем эффективные решения в области диагностики сельскохозяйственных территорий с помощью информационно-аэрокосмических технологий и разрабатываем индивидуальные рекомендации и методики с использованием искусственного интеллекта.',
                img: '/images/bannerstore/1.png',mob_img: '/images/bannerstore/mobbaner.png'},
            {id:4, title: ['Чем мы будем','Вам полезны?'],  link: 'https://www.instagram.com/p/CQRL42pHqjm/?utm_medium=copy_link',
                content:'Хотите увеличить обороты своего бизнеса и минимизировать свои расходы? Для каждого клиента мы разрабатываем индивидуальные решения исходя из особенностей участка и ваших задач. Свяжитесь с нами для получения подробной консультации, мы обязательно найдем решение для любой ситуации!',
                img: '/images/bannerstore/1.png', mob_img: '/images/bannerstore/mobbaner.png'}
        ]

        makeAutoObservable(this)
    }
    setBanner (banner ) {
        this._banner  = banner
    }
    get banner () {
        return this._banner
    }

}
import Main from "./pages/main";
import Roadmap from "./pages/Roadmap";
import ServicePage from "./pages/ServicePage";
import CasesPage from "./pages/CasesPage";
import ContactsPage from "./pages/СontactsPage";
import ServDetailed from "./pages/servDetailed";
import CasePage from "./pages/CasePage";
import BasketPage from "./pages/BasketPage";

export const publicRoutes = [
    {
        path: '/',
        Component: Main
    },
    {
        path: '/roadmap',
        Component: Roadmap
    },
    {
        path: '/contacts',
        Component: ContactsPage
    },
    {
        path: '/cases',
        Component: CasePage
    },
    {
        path: '/cases/:id',
        Component: CasesPage
    },
    {
        path: '/services',
        Component: ServicePage
    },
    {
        path: '/services/:id',
        Component: ServDetailed
    },
    {
        path: '/basket',
        Component: BasketPage
    }
]

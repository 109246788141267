import React, {useEffect, useState} from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import {sendFeedback} from "../http/backend";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const getWidth  = () => {
    return window.innerWidth;
};
const ContactsPage = () => {
    let [width, setWidth] = useState(getWidth);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        const resizeListener = () => {
            setWidth(getWidth())
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    async function handleSubmit() {
        if (name ==='' || email==='' || phone ==='' || text==='') {
            alert('Введите все данные');
            return;
        }
        if (phone.length!==11) {
            alert('Введите телефон');
            return;
        }
        if (!(/.+\@.+/.test(email))) {
            alert('Почта не валидна');
            return;
        }
        let formatPhone = '+'+ phone[0] + ' (' + phone[1]+ phone[2]+ phone[3] + ') ' + phone[4]+ phone[5]+ phone[6] + '-' + phone[7]+ phone[8] + '-' + phone[9]+phone[10];
        await sendFeedback(name, email, formatPhone, text).then(
            value => {
                document.getElementById('input-name').value = '';
                setName('');

                document.getElementById('number-cont').classList.toggle('number-cont');
                document.getElementById('react-tel-input-cont').classList.toggle('d-none');
                setPhone('+7');

                document.getElementById('input-email').value = '';
                setEmail('');

                document.getElementById('input-text').value = '';
                setText('');

                document.getElementById('result-btn').click()
            }, reason => {
                alert('Что-то пошло не так, повторите попытку позже');
            });
    }

    return (
        <div className='contact_bg'>
            <NavBar width={width}/>
            {width >992?
                <div className='contact_img contact_title_block  d-flex align-content-end mb-100'
                     style={{backgroundImage: "url(/images/ContactsPage/bg.jpg)"}}>
                    <div className='container'>
                        <div className='row'>
                            <div className="container-title">
                                <h1 className={'contact_title'}>Контакты</h1>
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div className='roadsmall_title'>
                <img className='contact_img d-sm-none' src='/images/ContactsPage/cont_small.png' />
                <img className='contact_img d-none d-sm-block' src='/images/ContactsPage/bg-small.png' />
                <div className='title-road title-contact'>
                    <div className='container'>
                        <div className='row'>
                            <div className="container-title">
                                <h1>Контакты</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className='container'>
                <div className="row">
                    <div className='mb-50 col-12 col-lg-6 d-flex flex-column'>
                        <ul className="list-unstyled contacts">
                            <li className=''>
                                <a className="d-flex flex-column flex-md-row" href="tel:84998994989">
                                    <div className=' contacts_li_img'>
                                        <img src="images/ContactsPage/phone.svg" />
                                    </div>
                                    <div className='d-flex flex-column flex-lg-row'>
                                        <b className='mr-1 d-none d-lg-block'>Тел:</b> +7 (499) 899 49 89
                                    </div>
                                </a>
                            </li>
                            <li className=''>
                                <a className="d-flex flex-column flex-md-row" href="mailto:info@aerospace-agro.com">
                                    <div className=' contacts_li_img'>
                                        <img src="images/ContactsPage/mail.svg" />
                                    </div>
                                    <div className='d-flex flex-column flex-lg-row'>
                                        <b className='mr-1'>E-mail:</b>
                                        info@aerospace-agro.com
                                    </div>
                                </a>
                            </li>
                            <li className="d-flex flex-column flex-md-row mb-1">
                                <div className='contacts_li_img'>
                                    <img src="images/ContactsPage/06.svg" />
                                </div>
                                <div className="d-flex">
                                    <b className='mr-1'>ИНН:</b> <span>9706011459</span>
                                </div>
                            </li>
                            <li className="d-flex flex-column flex-md-row ">
                                <div className='d-none d-md-block contacts_li_img'></div>
                                <div className="d-flex">
                                    <b className='mr-1'>ОГРН:</b> <span>1207700484672</span>
                                </div>
                            </li>
                            <li className="d-flex flex-column flex-md-row ">
                                <div className='contacts_li_img'><img src="images/ContactsPage/map.svg" /></div>
                                <span><b className='mr-1'>Адрес:</b> 115419, Москва, <br/> ул. Академика Петровского, <br className='d-none d-lg-block'/>д. 5 стр. 1</span></li>
                            <li className="d-flex flex-column flex-md-row ">
                                <div className='d-none d-md-block contacts_li_img'></div>
                                <div className="d-flex flex-column">
                                    <b>Мы в соцсетях:</b>
                                    <div className='mt-2 d-flex contact-small'>
                                        <a className='mr-3 link-icon' href="https://www.instagram.com/aerospace.agro/">
                                            <img src="/images/header/inst.svg" alt=""/></a>
                                        <a className='mr-3 link-icon' href="https://www.linkedin.com/company/77860758/">
                                            <i className="fa fa-linkedin"></i></a>
                                        <a className='mr-3 link-icon' href="#">
                                            <img src="/images/header/fb.svg" alt=""/></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='col-12 col-lg-6'>
                        {width < 720? <h3 className="contact_small">Вопросы? <br/> Пишите нам!</h3> :<h3 className='w-100 text-wrap'>Остались вопросы? <br/> Пишите нам мы найдем ответы</h3>}
                        <form className="feedback">
                            <input id={'input-name'} className='w-100 input_contacts' onChange={(e) => setName(e.target.value)}  placeholder='Ваше имя' type="text"/>
                            <div id={'number-cont'}>
                                <input className='w-100 input_contacts' placeholder='Ваш номер' type="text"
                                    onClick={() => {
                                        document.getElementById('number-cont').classList.toggle('number-cont');
                                        document.getElementById('react-tel-input-cont').classList.toggle('d-none')
                                    }}
                                />
                                <div id={'react-tel-input-cont'} className={'d-none'}>
                                    <PhoneInput
                                        inputProps={{
                                            name: "phone",
                                            required: true,
                                            autoFocus: true
                                        }}
                                        country="ru"
                                        countryCodeEditable={false}
                                        inputClass={'input_contacts'}
                                        className='d-none input_contacts'
                                        localization = {'ru'}
                                        placeholder={'Ваш номер'}
                                        enableAreaCodes={false}
                                        value={phone}
                                        onChange={setPhone}/>
                                </div>
                            </div>
                            <input id={'input-email'} className='w-100 input_contacts' onChange={(e) => setEmail(e.target.value)}  placeholder='Ваша почта' type="text"/>
                            <textarea id={'input-text'} className='w-100 input_contacts' onChange={(e) => setText(e.target.value)}  placeholder='Опишите вашу проблему'/>
                            <a className='btn-color_second w-100' onClick={handleSubmit}> отправить <img src="images/icons/btn-lg-next.svg" alt=""/></a>
                        </form>
                        <p className='privacy text-wrap'>Нажимая «Отправить» вы соглашаетесь <br/>c <a className='text-wrap' href="/privacy.pdf" download> политикой конфиденциальности</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactsPage;

import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const AerospaceServices = observer (() => {

    const {servicesPage} = useContext(Context);
    const el = servicesPage.service;
    let [page, setPage] = useState(sessionStorage.page? JSON.parse(sessionStorage.getItem('page')) : 1);
    let [flag, setFlag] = useState(false);
    let [filterFlag, setFilterFlag] = useState(sessionStorage.filter? JSON.parse(sessionStorage.getItem('filter')) :'for_all');
    useEffect(() => {
        try {
            servicesPage.setBasket(JSON.parse(sessionStorage.basket));
        } catch (e) {
        }
    }, [])
    function checkIn(item) {
        let a = -1;
        servicesPage.choselist?.map((el) => {
            if (el.name === item.name) {
                a = 0;
            }
        });
        if (a === -1) {
            return 'basket'
        }
        return 'basket grey'
    }
    function add(item) {
        console.log(servicesPage.choselisth)
        servicesPage.setChoselist(item)
    }
    function filter(id) {
        document.getElementsByClassName('page-link active_before')[0].classList.remove("active_before");
        sessionStorage.setItem('filter', JSON.stringify(id));
        setFilterFlag(JSON.parse(sessionStorage.getItem('filter')))
        sessionStorage.setItem('page', JSON.stringify(1));
        setPage(JSON.parse(sessionStorage.getItem('page')));
        document.getElementById(id).classList.add('active_before');
    }
    function changeActive(id) {
        document.getElementById("btn-pagination_serv-"+page).classList.toggle("active");
        sessionStorage.setItem('page', JSON.stringify(id));
        setPage(JSON.parse(sessionStorage.getItem('page')));
        document.getElementById("btn-pagination_serv-"+id).classList.toggle("active");
    }
    return (
        <div>
            <div className='desk-img-serv line'>
                <div className='container pd-none'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h3 className=' manager'>
                            <p className='m'>ваш запрос менеджеру</p>
                            <p className='s'>Выбрано: <b>{servicesPage.choselist.length} услуг для консультации</b></p>
                        </h3>
                        <a href={servicesPage.choselist.length>0? '/basket' :'#'} type="button" className="bt-green btn btn-primary">
                            смотреть список выбранных услуг или оформить запрос
                            <div className='vector'></div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container where_use services-body">
                <div className="row mb-3 mt-5">
                    <div className="col-lg-5 col-12 servicesAa">
                        <div className="serv-aa-title container-title">
                            <h1 className='d-none d-lg-block mb-0'>услуги <br/>aerospace-agro
                                <button className="mobil-img img-serv">
                                    <img src={"/images/servicesPage/information.png"}/>
                                </button>
                            </h1>
                            <h1 className={'d-lg-none'}>
                                Ваш запрос менеджеру
                            </h1>
                            <a className="mobil-img"><strong>Выбрано:</strong> {servicesPage.choselist.length} услуг</a>
                        </div>
                    </div>
                    <div className="col desk-img link-serv container-title">
                        <button className="butDrop" onClick={() => {
                            setFlag(!flag)
                        }}>
                            {flag ?
                                <p className="text-none top-info">Свернуть описание функции «запрос менеджеру» <img src={"/images/servicesPage/VectorSmart.svg"}/></p>
                                :
                                <p className="text-none top-info">Описание функции «запрос менеджеру» <img src={"/images/servicesPage/VectorSmart2.svg"}/></p>
                                }
                        </button>
                    </div>
                </div>
                {
                    flag ? <div className="row-12 mb-4" id="myDIV">
                        <p className="top-info">Для вашего удобства мы добавили функцию «запрос менеджеру». Добавляйте услуги в «запрос» с помощью кнопки «корзина» и формируйте запрос для индивидуальной консультации по интересующим вас услугам. Данный запрос не обязывает вас к покупке.</p>
                    </div> : null
                }
                <div className="hiden-serv-one mt-4 serv-request serv-block mobil-img-serv">
                    <div className='bottom mobil-img-serv mr-5'>
                        <a href={'/basket'} className="hiden-serv-one-but ml-4 mr-0 btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                            офомить запрос
                            <div className="vector_more"></div>
                        </a>
                    </div>
                </div>
                <div className=" mt-5 d-flex flex-column flex-lg-row crumbs">
                    <div className="servis">Выбрать услуги для:</div>
                    <ul className="d-flex flex-wrap pagination-top">
                        <li className="page-items" onClick={()=>filter('for_all')}><a id='for_all' className={filterFlag==='for_all'? "page-link active_before" : "page-link"}><span>всех</span></a></li>
                        <li className="page-items" onClick={()=>filter('for_leader')}><a id='for_leader' className={filterFlag==='for_leader'? "page-link active_before" : "page-link"}><span>руководителя</span></a></li>
                        <li className="page-items" onClick={()=>filter('for_agro')}><a id='for_agro' className={filterFlag==='for_agro'? "page-link active_before" : "page-link"}><span>агронома</span></a></li>
                    </ul>
                </div>
                {filterFlag === 'for_all' ?
                    <div>
                        {page === 1 ?
                            <div className="serv-block">
                                <div className="row">
                                    <div id="TestsDiv" className="element btn-pageMenu el-1 col-sm">
                                        <div className="img r2 align-self-center">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[0].mainimg}/>
                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[0].name}
                                        </p>
                                        <div className='bottom'>
                                            <a href={el[0].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className='row request' onClick={() => add(el[0])}>
                                            <button className={checkIn(el[0])}></button>
                                            <p>от 350 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="center element col-sm">
                                        <div className="img r2 align-self">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[1].mainimg}/>
                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[1].name}
                                        </p>
                                        <div className='bottom'>
                                            <a href={el[1].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className='row request' onClick={() => add(el[1])}>
                                            <button className={checkIn(el[1])}>
                                            </button>
                                            <p>от 400 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="element btn-pageMenu el-1 col-sm">
                                        <div className="img r2 align-self-center">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[2].mainimg}/>
                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[2].name}
                                        </p>
                                        <div className='bottom'>
                                            <a href={el[2].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className='row request' onClick={() => add(el[2])}>
                                            <button className={checkIn(el[2])}></button>
                                            <p>от 250 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="element col-sm">
                                        <div className="img r2 align-self">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[3].mainimg}/>
                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[3].name}
                                        </p>
                                        <div className='bottom'>
                                            <a href={el[3].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className='row request' onClick={() => add(el[3])}>
                                            <button className={checkIn(el[3])}>
                                            </button>
                                            <p>от 250 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="center element col-sm">
                                        <div className="img r2 align-self">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[4].mainimg}/>
                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[4].name}
                                        </p>
                                        <div className='bottom'>
                                            <a href={el[4].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className='row request' onClick={() => add(el[4])}>
                                            <button className={checkIn(el[4])}>
                                            </button>
                                            <p>от 250 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="element col-sm">
                                        <div className="img r2 align-self-left">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[5].mainimg}/>
                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[5].name}
                                        </p>
                                        <div className='bottom'>
                                            <a href={el[5].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className='row request' onClick={() => add(el[5])}>
                                            <button className={checkIn(el[5])}>
                                            </button>
                                            <p>от 350 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="el-1 col-sm br-bt ">
                                        <div className="img r2 align-self-center">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[6].mainimg}/>

                                            </div>
                                        </div>
                                        <p className="text">
                                            {el[6].name}
                                        </p>
                                        <div className="bottom">
                                            <a href={el[6].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className="row request" onClick={() => add(el[6])}>
                                            <button className={checkIn(el[6])}>
                                            </button>
                                            <p>от 250 руб/га</p>
                                        </div>
                                    </div>
                                    <div className="center br-bt col-sm">
                                        <div className="img r2 align-self">
                                            <div className='min-img-block'>
                                                <img className="min-img" src={el[7].mainimg}/>

                                            </div>
                                        </div>
                                        <p className="text">{el[7].name}</p>
                                        <div className="bottom">
                                            <a href={el[7].link}
                                               className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                узнать больше
                                                <div className="vector_more"></div>
                                            </a>
                                        </div>
                                        <div className="row request" onClick={() => add(el[7])}>
                                            <button className={checkIn(el[7])}>
                                            </button>
                                            <p>от 150 руб/га</p>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-column align-items-center  el-1 br-bt col-12 col-lg">
                                        <h4 onClick={() => {window.scroll(0,0);
                                            changeActive(2)
                                        }} className="text-next"><b>Перейти<br/>на следующую<br/>страницу
                                            услуг</b><br/>
                                            <img src={"/images/servicesPage/VectorBig.svg"}/>
                                        </h4>
                                        <div className="mobil-img-serv br-bt col-12">
                                            <button type="button" onClick={() => {window.scroll(0,0);
                                                changeActive(2)
                                            }}
                                                    className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                <p className='mb-0'>следующая страница</p>
                                            </button>
                                        </div>
                                        <div className="pagination-bott mt-lg-1 mb-lg-5">
                                            <button id='btn-pagination_serv-1' onClick={() => {window.scroll(0,0);
                                                changeActive(1)
                                            }} className='btn btn-pagination_serv active'>01
                                            </button>
                                            <button id='btn-pagination_serv-2' onClick={() => {window.scroll(0,0);
                                                changeActive(2)
                                            }} className='btn btn-pagination_serv'>02
                                            </button>
                                            <button id='btn-pagination_serv-3' onClick={() => {window.scroll(0,0);
                                                changeActive(3)
                                            }} className='btn btn-pagination_serv'>03
                                            </button>
                                            <button id='btn-pagination_serv-4' onClick={() => {window.scroll(0,0);
                                                changeActive(4)
                                            }} className='btn btn-pagination_serv'>04
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : page === 2 ?
                                <div className="serv-block">
                                    <div className="row">
                                        <div className="element el-1 col-sm">
                                            <div className="img align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[8].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[8].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[8].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[8])}>
                                                <button className={checkIn(el[8])}>
                                                </button>
                                                <p>от 350 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="center element col-sm">
                                            <div className="img align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[9].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[9].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[9].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[9])}>
                                                <button className={checkIn(el[9])}>
                                                </button>
                                                <p>от 400 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img r2 align-self-left">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[10].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[10].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[10].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[10])}>
                                                <button className={checkIn(el[10])}>
                                                </button>
                                                <p>от 350 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[11].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[11].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[11].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[11])}>
                                                <button className={checkIn(el[11])}>
                                                </button>
                                                <p>от 400 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="center element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[12].mainimg}/>

                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[12].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[12].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[12])}>
                                                <button className={checkIn(el[12])}>
                                                </button>
                                                <p>от 450 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img r2 align-self-left">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[13].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[13].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[13].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[13])}>
                                                <button className={checkIn(el[13])}>
                                                </button>
                                                <p>от 300 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="el-1 col-sm br-bt ">
                                            <div className="img align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[14].mainimg}/>

                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[14].name}
                                            </p>
                                            <div className="bottom">
                                                <a href={el[14].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className="row request" onClick={() => add(el[14])}>
                                                <button className={checkIn(el[14])}>
                                                </button>
                                                <p>от 350 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="center br-bt col-sm">
                                            <div className="img align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[15].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[15].name}
                                            </p>
                                            <div className="bottom">
                                                <a href={el[15].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className="row request" onClick={() => add(el[15])}>
                                                <button className={checkIn(el[15])}>
                                                </button>
                                                <p>от 300 руб/га</p>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column align-items-center el-1 br-bt col-12 col-lg">
                                            <h4 onClick={() => {window.scroll(0,0);
                                                changeActive(3)
                                            }} className="text-next">
                                                <b>Перейти<br/>на следующую<br/>страницу услуг</b>
                                                <br/>
                                                <img src={"/images/servicesPage/VectorBig.svg"}/>
                                            </h4>
                                            <div className="mobil-img-serv br-bt col-12">
                                                <button type="button" onClick={() => {window.scroll(0,0);
                                                    changeActive(3)
                                                }}
                                                        className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    <p className='mb-0'>следующая страница</p>

                                                </button>
                                            </div>
                                            <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                <button id='btn-pagination_serv-1' onClick={() => {window.scroll(0,0);
                                                    changeActive(1)
                                                }} className='btn btn-pagination_serv'>01
                                                </button>
                                                <button id='btn-pagination_serv-2' onClick={() => {window.scroll(0,0);
                                                    changeActive(2)
                                                }} className='btn btn-pagination_serv active'>02
                                                </button>
                                                <button id='btn-pagination_serv-3' onClick={() => {window.scroll(0,0);
                                                    changeActive(3)
                                                }} className='btn btn-pagination_serv'>03
                                                </button>
                                                <button id='btn-pagination_serv-4' onClick={() => {window.scroll(0,0);
                                                    changeActive(4)
                                                }} className='btn btn-pagination_serv'>04
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : page === 3 ?
                                    <div className="serv-block">
                                        <div className="row">
                                            <div className="element el-1 col-sm">
                                                <div className="img align-self-center">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[16].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[16].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[16].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[16])}>
                                                    <button className={checkIn(el[16])}>
                                                    </button>
                                                    <p>от 350 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="center element col-sm">
                                                <div className="img align-self">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[17].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[17].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[17].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[17])}>
                                                    <button className={checkIn(el[17])}>
                                                    </button>
                                                    <p>от 400 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="element col-sm">
                                                <div className="img align-self-left">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[18].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[18].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[18].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[18])}>
                                                    <button className={checkIn(el[18])}>
                                                    </button>
                                                    <p>от 400 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="element col-sm">
                                                <div className="img r2 align-self-center">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[19].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[19].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[19].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[19])}>
                                                    <button className={checkIn(el[19])}>
                                                    </button>
                                                    <p>от 250 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="center element col-sm">
                                                <div className="img r2 align-self">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[20].mainimg}/>

                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[20].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[20].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[20])}>
                                                    <button className={checkIn(el[20])}>
                                                    </button>
                                                    <p>от 750 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="element col-sm">
                                                <div className="img r2 align-self-left">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[21].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[21].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[21].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[21])}>
                                                    <button className={checkIn(el[21])}>
                                                    </button>
                                                    <p>от 500 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="el-1 col-sm br-bt">
                                                <div className="img align-self-center">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img" src={el[22].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[22].name}
                                                </p>
                                                <div className="bottom">
                                                    <a href={el[22].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className="row request" onClick={() => add(el[22])}>
                                                    <button className={checkIn(el[22])}>
                                                    </button>
                                                    <p>от 350 руб/га</p></div>
                                            </div>
                                            <div className="center br-bt col-sm">
                                                <div className="img align-self">
                                                    <div className='min-img-block r2'>
                                                        <img className="min-img " src={el[23].mainimg}/>

                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[23].name}
                                                </p>
                                                <div className="bottom">
                                                    <a href={el[23].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className="row request" onClick={() => add(el[23])}>
                                                    <button className={checkIn(el[23])}>
                                                    </button>
                                                    <p>от 300 руб/га</p>
                                                </div>
                                            </div>

                                            <div
                                                className="d-flex el-1 flex-column align-items-center br-bt col-12 col-lg ">
                                                <h4 onClick={() => {window.scroll(0,0);
                                                    changeActive(4)
                                                }} className="text-next"><b>Перейти<br/>на следующую<br/>страницу
                                                    услуг</b><br/>
                                                    <img src={"/images/servicesPage/VectorBig.svg"}/>
                                                </h4>
                                                <div className="mobil-img-serv br-bt col-12">
                                                    <button type="button" onClick={() => {window.scroll(0,0);
                                                        changeActive(4)
                                                    }}
                                                            className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        <p className='mb-0'>следующая страница</p>

                                                    </button>
                                                </div>
                                                <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                    <button id='btn-pagination_serv-1' onClick={() => {window.scroll(0,0);
                                                        changeActive(1)
                                                    }} className='btn btn-pagination_serv'>01
                                                    </button>
                                                    <button id='btn-pagination_serv-2' onClick={() => {window.scroll(0,0);
                                                        changeActive(2)
                                                    }} className='btn btn-pagination_serv'>02
                                                    </button>
                                                    <button id='btn-pagination_serv-3' onClick={() => {window.scroll(0,0);
                                                        changeActive(3)
                                                    }} className='btn btn-pagination_serv active'>03
                                                    </button>
                                                    <button id='btn-pagination_serv-4' onClick={() => {window.scroll(0,0);
                                                        changeActive(4)
                                                    }} className='btn btn-pagination_serv'>04
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="serv-block">
                                        <div className="container">
                                            <div className="row">
                                                <div className="el-1 col-sm">
                                                    <div className="img align-self-center">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[24].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[24].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[24].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[24])}>
                                                        <button className={checkIn(el[24])}>
                                                        </button>
                                                        <p>от 450 руб/га</p>
                                                    </div>
                                                </div>
                                                <div className="center col-sm">
                                                    <div className="img align-self">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[25].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[25].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[25].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[25])}>
                                                        <button className={checkIn(el[25])}>
                                                        </button>
                                                        <p>от 200 руб/га</p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex flex-column el-1 align-items-center el-1 br-bt col-12 col-lg ">
                                                    <h4 onClick={() => {window.scroll(0,0);
                                                        changeActive(1)
                                                    }} className="text-next"><b>Вернуться <br/>на первую<br/> страницу
                                                        услуг</b><br/>
                                                        <img src={"/images/servicesPage/VectorBigReverse.svg"}/>
                                                    </h4>
                                                    <div className="mobil-img-serv br-bt col-12">
                                                        <button type="button" onClick={() => {window.scroll(0,0);
                                                            changeActive(1)
                                                        }}
                                                                className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            <p className='mb-0'>следующая страница</p>
                                                        </button>
                                                    </div>
                                                    <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                        <button id='btn-pagination_serv-1' onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(1)
                                                        }} className='btn btn-pagination_serv'>01
                                                        </button>
                                                        <button id='btn-pagination_serv-2' onClick={() => {window.scroll(0,0);
                                                            changeActive(2)
                                                        }} className='btn btn-pagination_serv'>02
                                                        </button>
                                                        <button id='btn-pagination_serv-3' onClick={() => {window.scroll(0,0);
                                                            changeActive(3)
                                                        }} className='btn btn-pagination_serv'>03
                                                        </button>
                                                        <button id='btn-pagination_serv-4' onClick={() => {window.scroll(0,0);
                                                            changeActive(4)
                                                        }} className='btn btn-pagination_serv active'>04
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
                    :filterFlag === 'for_leader'?
                        <div>
                            {page === 1 ?
                                <div className="serv-block">
                                    <div className="row">
                                        <div id="TestsDiv" className="element btn-pageMenu el-1 col-sm">
                                            <div className="img r2 align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[0].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[0].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[0].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[0])}>
                                                <button className={checkIn(el[0])}></button>
                                                <p>от 350 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="center element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[4].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[4].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[4].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[4])}>
                                                <button className={checkIn(el[4])}>
                                                </button>
                                                <p>от 250 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element btn-pageMenu el-1 col-sm">
                                            <div className="img r2 align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[16].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[16].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[16].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[16])}>
                                                <button className={checkIn(el[16])}></button>
                                                <p>от 350 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[18].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[18].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[18].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[18])}>
                                                <button className={checkIn(el[18])}>
                                                </button>
                                                <p>от 400 руб/гау</p>
                                            </div>
                                        </div>
                                        <div className="center element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[19].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[19].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[19].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[19])}>
                                                <button className={checkIn(el[19])}>
                                                </button>
                                                <p>от 250 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img r2 align-self-left">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[20].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[20].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[20].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[20])}>
                                                <button className={checkIn(el[20])}>
                                                </button>
                                                <p>от 750 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="el-1 col-sm br-bt ">
                                            <div className="img r2 align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[21].mainimg}/>

                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[21].name}
                                            </p>
                                            <div className="bottom">
                                                <a href={el[21].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className="row request" onClick={() => add(el[21])}>
                                                <button className={checkIn(el[21])}>
                                                </button>
                                                <p>от 500 руб/га</p></div>
                                        </div>
                                        <div className="center br-bt col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[22].mainimg}/>

                                                </div>
                                            </div>
                                            <p className="text">{el[22].name}</p>
                                            <div className="bottom">
                                                <a href={el[22].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className="row request" onClick={() => add(el[22])}>
                                                <button className={checkIn(el[22])}>
                                                </button>
                                                <p>от 350 руб/га</p></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-center el-1 r2 br-bt col-12 col-lg">
                                            <h4 onClick={() => {window.scroll(0,0);
                                                changeActive(2)
                                            }} className="text-next"><b>Перейти<br/>на следующую<br/>страницу
                                                услуг</b><br/>
                                                <img src={"/images/servicesPage/VectorBig.svg"}/>
                                            </h4>
                                            <div className="mobil-img-serv br-bt col-12">
                                                <button type="button" onClick={() => {window.scroll(0,0);
                                                    changeActive(2)
                                                }}
                                                        className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    <p className='mb-0'>следующая страница</p>
                                                </button>
                                            </div>
                                            <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                <button id='btn-pagination_serv-1' onClick={() => {
                                                    changeActive(1)
                                                }} className='btn btn-pagination_serv active'>01
                                                </button>
                                                <button id='btn-pagination_serv-2' onClick={() => {
                                                    changeActive(2)
                                                }} className='btn btn-pagination_serv'>02
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="serv-block row">
                                        <div className="container">
                                            <div className="row">
                                                <div className="el-1 col-sm">
                                                    <div className="img align-self-center">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[24].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[24].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[24].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[24])}>
                                                        <button className={checkIn(el[24])}>
                                                        </button>
                                                        <p>от 450 руб/га</p>
                                                    </div>
                                                </div>
                                                <div className="center col-sm">
                                                    <div className="img align-self">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[25].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[25].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[25].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[25])}>
                                                        <button className={checkIn(el[25])}>
                                                        </button>
                                                        <p>от 200 руб/га</p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex flex-column align-items-center  el-1 br-bt col-12 col-lg ">
                                                    <h4 onClick={() => {
                                                        window.scroll(0,0);
                                                        changeActive(1)
                                                    }} className="text-next"><b>Вернуться <br/>на первую<br/> страницу
                                                        услуг</b><br/>
                                                        <img src={"/images/servicesPage/VectorBigReverse.svg"}/>
                                                    </h4>
                                                    <div className="mobil-img-serv br-bt col-12">
                                                        <button type="button" onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(1)
                                                        }}
                                                                className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            <p className='mb-0'>следующая страница</p>
                                                        </button>
                                                    </div>
                                                    <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                        <button id='btn-pagination_serv-1' onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(1)
                                                        }} className='btn btn-pagination_serv'>01
                                                        </button>
                                                        <button id='btn-pagination_serv-2'
                                                                onClick={() => {
                                                                    window.scroll(0,0);
                                                                    changeActive(2)}
                                                                }
                                                                className='btn btn-pagination_serv active'>02
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            {page === 1 ?
                                <div className="serv-block">
                                    <div className="row">
                                        <div id="TestsDiv" className="element btn-pageMenu el-1 col-sm">
                                            <div className="img r2 align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[1].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[1].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[1].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[1])}>
                                                <button className={checkIn(el[1])}></button>
                                                <p>от 400 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="center element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[2].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[2].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[2].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[2])}>
                                                <button className={checkIn(el[2])}>
                                                </button>
                                                <p>от 250 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element btn-pageMenu el-1 col-sm">
                                            <div className="img r3 align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[3].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[3].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[3].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[3])}>
                                                <button className={checkIn(el[3])}></button>
                                                <p>от 250 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[4].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[4].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[4].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[4])}>
                                                <button className={checkIn(el[4])}>
                                                </button>
                                                <p>от 250 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="center element col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[5].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[5].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[5].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[5])}>
                                                <button className={checkIn(el[5])}>
                                                </button>
                                                <p>от 350 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="element col-sm">
                                            <div className="img r2 align-self-left">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[6].mainimg}/>
                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[6].name}
                                            </p>
                                            <div className='bottom'>
                                                <a href={el[6].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className='row request' onClick={() => add(el[6])}>
                                                <button className={checkIn(el[6])}>
                                                </button>
                                                <p>от 250 руб/га</p>
                                            </div>
                                        </div>
                                        <div className="el-1 col-sm br-bt ">
                                            <div className="img r2 align-self-center">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[7].mainimg}/>

                                                </div>
                                            </div>
                                            <p className="text">
                                                {el[7].name}
                                            </p>
                                            <div className="bottom">
                                                <a href={el[7].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className="row request" onClick={() => add(el[7])}>
                                                <button className={checkIn(el[7])}></button>
                                                <p>от 150 руб/га</p> </div>
                                        </div>
                                        <div className="center br-bt col-sm">
                                            <div className="img r2 align-self">
                                                <div className='min-img-block'>
                                                    <img className="min-img" src={el[8].mainimg}/>

                                                </div>
                                            </div>
                                            <p className="text">{el[8].name}</p>
                                            <div className="bottom">
                                                <a href={el[8].link}
                                                   className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    узнать больше
                                                    <div className="vector_more"></div>
                                                </a>
                                            </div>
                                            <div className="row request" onClick={() => add(el[8])}>
                                                <button className={checkIn(el[8])}>
                                                </button>
                                                <p>от 350 руб/га</p></div>
                                        </div>
                                        <div className="d-flex flex-column align-items-center el-1 r2 br-bt col-12 col-lg">
                                            <h4 onClick={() => {
                                                window.scroll(0,0);
                                                changeActive(2)
                                            }} className="text-next"><b>Перейти<br/>на следующую<br/>страницу
                                                услуг</b><br/>
                                                <img src={"/images/servicesPage/VectorBig.svg"}/>
                                            </h4>
                                            <div className="mobil-img-serv br-bt col-12">
                                                <button type="button" onClick={() => {
                                                    window.scroll(0,0);
                                                    changeActive(2);
                                                }}
                                                        className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                    <p className='mb-0'>следующая страница</p>
                                                </button>
                                            </div>
                                            <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                <button id='btn-pagination_serv-1' onClick={() => {
                                                    window.scroll(0,0);
                                                    changeActive(1)
                                                }} className='btn btn-pagination_serv active'>01
                                                </button>
                                                <button id='btn-pagination_serv-2' onClick={() => {
                                                    window.scroll(0,0);
                                                    changeActive(2)
                                                }} className='btn btn-pagination_serv'>02
                                                </button>
                                                <button id='btn-pagination_serv-3' onClick={() => {
                                                    window.scroll(0,0);
                                                    changeActive(3)
                                                }} className='btn btn-pagination_serv'>03
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                page === 2 ?
                                    <div className="serv-block">
                                        <div className="row">
                                            <div className="element el-1 col-sm">
                                                <div className="img align-self-center">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[9].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[9].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[9].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[9])}>
                                                    <button className={checkIn(el[9])}>
                                                    </button>
                                                    <p>от 400 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="center element col-sm">
                                                <div className="img align-self">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[10].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[10].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[10].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[10])}>
                                                    <button className={checkIn(el[10])}>
                                                    </button>
                                                    <p>от 350 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="element col-sm">
                                                <div className="img r2 align-self-left">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[11].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[11].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[11].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[11])}>
                                                    <button className={checkIn(el[11])}>
                                                    </button>
                                                    <p>от 400 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="element col-sm">
                                                <div className="img align-self-center">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[12].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[12].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[12].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[12])}>
                                                    <button className={checkIn(el[12])}>
                                                    </button>
                                                    <p>от 450 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="center element col-sm">
                                                <div className="img r2 align-self">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[13].mainimg}/>

                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[13].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[13].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[13])}>
                                                    <button className={checkIn(el[13])}>
                                                    </button>
                                                    <p>от 300 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="element col-sm">
                                                <div className="img r2 align-self-left">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[14].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[14].name}
                                                </p>
                                                <div className='bottom'>
                                                    <a href={el[14].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className='row request' onClick={() => add(el[14])}>
                                                    <button className={checkIn(el[14])}>
                                                    </button>
                                                    <p>от 350 руб/га</p>
                                                </div>
                                            </div>
                                            <div className="el-1 col-sm br-bt ">
                                                <div className="img align-self-center">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[15].mainimg}/>

                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[15].name}
                                                </p>
                                                <div className="bottom">
                                                    <a href={el[15].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className="row request" onClick={() => add(el[15])}>
                                                    <button className={checkIn(el[15])}>
                                                    </button>
                                                    <p>от 300 руб/га</p></div>
                                            </div>
                                            <div className="center br-bt col-sm">
                                                <div className="img align-self">
                                                    <div className='min-img-block'>
                                                        <img className="min-img" src={el[21].mainimg}/>
                                                    </div>
                                                </div>
                                                <p className="text">
                                                    {el[21].name}
                                                </p>
                                                <div className="bottom">
                                                    <a href={el[21].link}
                                                       className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        узнать больше
                                                        <div className="vector_more"></div>
                                                    </a>
                                                </div>
                                                <div className="row request" onClick={() => add(el[21])}>
                                                    <button className={checkIn(el[21])}>
                                                    </button>
                                                    <p>от 500 руб/га</p></div>
                                            </div>
                                            <div
                                                className="d-flex flex-column align-items-center el-1 br-bt col-12 col-lg">
                                                <h4 onClick={() => {
                                                    window.scroll(0,0);
                                                    changeActive(3)
                                                }} className="text-next">
                                                    <b>Перейти<br/>на следующую<br/>страницу услуг</b>
                                                    <br/>
                                                    <img src={"/images/servicesPage/VectorBig.svg"}/>
                                                </h4>
                                                <div className="mobil-img-serv br-bt col-12">
                                                    <button type="button" onClick={() => {
                                                        window.scroll(0,0);
                                                        changeActive(3);
                                                    }}
                                                            className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                        <p className='mb-0'>следующая страница</p>

                                                    </button>
                                                </div>
                                                <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                    <button id='btn-pagination_serv-1' onClick={() => {
                                                        window.scroll(0,0);
                                                        changeActive(1);
                                                    }} className='btn btn-pagination_serv'>01
                                                    </button>
                                                    <button id='btn-pagination_serv-2' onClick={() => {
                                                        window.scroll(0,0);
                                                        changeActive(2);
                                                    }} className='btn btn-pagination_serv active'>02
                                                    </button>
                                                    <button id='btn-pagination_serv-3' onClick={() => {
                                                        window.scroll(0,0);
                                                        changeActive(3);
                                                    }} className='btn btn-pagination_serv'>03
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="serv-block">
                                        <div className="container">
                                            <div className="row">
                                                <div className="el-1 col-sm">
                                                    <div className="img align-self-center">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[23].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[23].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[23].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[23])}>
                                                        <button className={checkIn(el[23])}>
                                                        </button>
                                                        <p>от 300 руб/га</p>
                                                    </div>
                                                </div>
                                                <div className="center col-sm">
                                                    <div className="img align-self">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[24].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[24].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[24].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[24])}>
                                                        <button className={checkIn(el[24])}>
                                                        </button>
                                                        <p>от 450 руб/га</p>
                                                    </div>
                                                </div>
                                                <div className="el-1 btn-pageMenu el-1 col-sm">
                                                    <div className="img r2 align-self-center">
                                                        <div className='min-img-block'>
                                                            <img className="min-img" src={el[25].mainimg}/>
                                                        </div>
                                                    </div>
                                                    <p className="text">
                                                        {el[25].name}
                                                    </p>
                                                    <div className='bottom'>
                                                        <a href={el[25].link}
                                                           className="btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            узнать больше
                                                            <div className="vector_more"></div>
                                                        </a>
                                                    </div>
                                                    <div className='row request' onClick={() => add(el[25])}>
                                                        <button className={checkIn(el[25])}></button>
                                                        <p>от 200 руб/га</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row agronomist_block">
                                                <div className="d-flex el-1 flex-column align-items-center br-bt col-4  agro col-lg">
                                                    <span>
                                                    <h4 onClick={() => {
                                                        window.scroll(0,0);
                                                        changeActive(1);
                                                    }} className="text-next"><b>Вернуться <br/>на первую<br/> страницу
                                                        услуг</b><br/>
                                                        <img src={"/images/servicesPage/VectorBigReverse.svg"}/>
                                                    </h4>
                                                    <div className="mobil-img-serv br-bt col-12">
                                                        <button type="button" onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(1);
                                                        }}
                                                                className="new-list  btn green-button btn-serv_more d-flex align-items-center justify-content-center">
                                                            <p className='mb-0'>следующая страница</p>
                                                        </button>
                                                    </div>
                                                    <div className="pagination-bott mt-lg-1 mb-lg-5">
                                                        <button id='btn-pagination_serv-1' onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(1);
                                                        }} className='btn btn-pagination_serv'>01
                                                        </button>
                                                        <button id='btn-pagination_serv-2' onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(2);
                                                        }} className='btn btn-pagination_serv'>02
                                                        </button>
                                                        <button id='btn-pagination_serv-3' onClick={() => {
                                                            window.scroll(0,0);
                                                            changeActive(3);
                                                        }} className='btn btn-pagination_serv active'>03
                                                        </button>
                                                    </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>

                }
            </div>
        </div>
    );
});
export default AerospaceServices;

